@charset "UTF-8";
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #2c8ef8;
  --indigo: #727cf5;
  --purple: #6b5eae;
  --pink: #ff679b;
  --red: #fa5c7c;
  --orange: #fd7e14;
  --yellow: #ffbc00;
  --green: #0acf97;
  --teal: #02a8b5;
  --cyan: #39afd1;
  --white: #fff;
  --gray: #98a6ad;
  --gray-dark: #343a40;
  --primary: #727cf5;
  --secondary: #6c757d;
  --success: #0acf97;
  --info: #39afd1;
  --warning: #ffbc00;
  --danger: #fa5c7c;
  --light: #e3eaef;
  --dark: #313a46;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Nunito", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  text-align: left;
  background-color: #fafbfe;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #727cf5;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #2b3af0;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.95rem;
  padding-bottom: 0.95rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1.5rem;
  font-weight: 400;
  line-height: 1.1;
}

h1, .h1 {
  font-size: 2.25rem;
}

h2, .h2 {
  font-size: 1.875rem;
}

h3, .h3 {
  font-size: 1.5rem;
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 0.9375rem;
}

h6, .h6 {
  font-size: 0.75rem;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #e3eaef;
}

small,
.small {
  font-size: 0.75rem;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 6px;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.09375rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.75rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #39afd1;
  word-break: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1.5rem;
  color: #6c757d;
}
.table th,
.table td {
  padding: 0.95rem;
  vertical-align: top;
  border-top: 1px solid #e3eaef;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #e3eaef;
}
.table tbody + tbody {
  border-top: 2px solid #e3eaef;
}

.table-sm th,
.table-sm td {
  padding: 0.5rem;
}

.table-bordered {
  border: 1px solid #e3eaef;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #e3eaef;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f1f3fa;
}

.table-hover tbody tr:hover {
  color: #6c757d;
  background-color: #f1f3fa;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d8dafc;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #b6bbfa;
}

.table-hover .table-primary:hover {
  background-color: #c0c4fa;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #c0c4fa;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #baf2e2;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #80e6c9;
}

.table-hover .table-success:hover {
  background-color: #a5eed9;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #a5eed9;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c8e9f2;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #98d5e7;
}

.table-hover .table-info:hover {
  background-color: #b3e1ed;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #b3e1ed;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffecb8;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdc7a;
}

.table-hover .table-warning:hover {
  background-color: #ffe59f;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe59f;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fed1da;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #fcaabb;
}

.table-hover .table-danger:hover {
  background-color: #fdb8c6;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #fdb8c6;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f7f9fb;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f0f4f7;
}

.table-hover .table-light:hover {
  background-color: #e6ecf3;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #e6ecf3;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c5c8cb;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #94999f;
}

.table-hover .table-dark:hover {
  background-color: #b8bbbf;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b8bbbf;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #98a6ad;
  background-color: #313a46;
  border-color: #414d5d;
}
.table .thead-light th {
  color: #6c757d;
  background-color: #f1f3fa;
  border-color: #e3eaef;
}

.table-dark {
  color: #98a6ad;
  background-color: #313a46;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #414d5d;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.2125rem + 2px);
  padding: 0.45rem 0.9rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #c1c9d0;
  outline: 0;
  box-shadow: none;
}
.form-control::placeholder {
  color: #adb5bd;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #6c757d;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.45rem + 1px);
  padding-bottom: calc(0.45rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.28rem + 1px);
  padding-bottom: calc(0.28rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.8725rem + 2px);
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #98a6ad;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #0acf97;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(10, 207, 151, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #0acf97;
  padding-right: 2.2125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #0acf97;
  box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 2.2125rem;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #0acf97;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #0acf97;
  box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #0acf97;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #0acf97;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #0acf97;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #18f4b5;
  background-color: #18f4b5;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #0acf97;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #0acf97;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #0acf97;
  box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #fa5c7c;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(250, 92, 124, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #fa5c7c;
  padding-right: 2.2125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fa5c7c' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fa5c7c' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #fa5c7c;
  box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 2.2125rem;
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #fa5c7c;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fa5c7c' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fa5c7c' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #fa5c7c;
  box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #fa5c7c;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #fa5c7c;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #fa5c7c;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #fc8da4;
  background-color: #fc8da4;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #fa5c7c;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #fa5c7c;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #fa5c7c;
  box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #6c757d;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.45rem 0.9rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.15rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #6c757d;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(114, 124, 245, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #727cf5;
  border-color: #727cf5;
}
.btn-primary:hover {
  color: #fff;
  background-color: #4e5bf2;
  border-color: #4250f2;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(135, 144, 247, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #727cf5;
  border-color: #727cf5;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #4250f2;
  border-color: #3645f1;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(135, 144, 247, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #0acf97;
  border-color: #0acf97;
}
.btn-success:hover {
  color: #fff;
  background-color: #08ab7c;
  border-color: #089e74;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 214, 167, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #0acf97;
  border-color: #0acf97;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #089e74;
  border-color: #07926b;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 214, 167, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1;
}
.btn-info:hover {
  color: #fff;
  background-color: #2b99b9;
  border-color: #2991ae;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(87, 187, 216, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #2991ae;
  border-color: #2688a4;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(87, 187, 216, 0.5);
}

.btn-warning {
  color: #313a46;
  background-color: #ffbc00;
  border-color: #ffbc00;
}
.btn-warning:hover {
  color: #313a46;
  background-color: #d9a000;
  border-color: #cc9600;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 169, 11, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #313a46;
  background-color: #ffbc00;
  border-color: #ffbc00;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #cc9600;
  border-color: #bf8d00;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 169, 11, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #fa5c7c;
  border-color: #fa5c7c;
}
.btn-danger:hover {
  color: #fff;
  background-color: #f9375e;
  border-color: #f82b54;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 116, 144, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #fa5c7c;
  border-color: #fa5c7c;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #f82b54;
  border-color: #f81e4a;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(251, 116, 144, 0.5);
}

.btn-light {
  color: #313a46;
  background-color: #e3eaef;
  border-color: #e3eaef;
}
.btn-light:hover {
  color: #313a46;
  background-color: #cbd8e1;
  border-color: #c3d2dc;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(200, 208, 214, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #313a46;
  background-color: #e3eaef;
  border-color: #e3eaef;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #313a46;
  background-color: #c3d2dc;
  border-color: #baccd8;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(200, 208, 214, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #313a46;
  border-color: #313a46;
}
.btn-dark:hover {
  color: #fff;
  background-color: #212730;
  border-color: #1c2128;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 88, 98, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #313a46;
  border-color: #313a46;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1c2128;
  border-color: #171b21;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 88, 98, 0.5);
}

.btn-outline-primary {
  color: #727cf5;
  border-color: #727cf5;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #727cf5;
  border-color: #727cf5;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(114, 124, 245, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #727cf5;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #727cf5;
  border-color: #727cf5;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(114, 124, 245, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #0acf97;
  border-color: #0acf97;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #0acf97;
  border-color: #0acf97;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #0acf97;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #0acf97;
  border-color: #0acf97;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.5);
}

.btn-outline-info {
  color: #39afd1;
  border-color: #39afd1;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(57, 175, 209, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #39afd1;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(57, 175, 209, 0.5);
}

.btn-outline-warning {
  color: #ffbc00;
  border-color: #ffbc00;
}
.btn-outline-warning:hover {
  color: #313a46;
  background-color: #ffbc00;
  border-color: #ffbc00;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 188, 0, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffbc00;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #313a46;
  background-color: #ffbc00;
  border-color: #ffbc00;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 188, 0, 0.5);
}

.btn-outline-danger {
  color: #fa5c7c;
  border-color: #fa5c7c;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #fa5c7c;
  border-color: #fa5c7c;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #fa5c7c;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #fa5c7c;
  border-color: #fa5c7c;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.5);
}

.btn-outline-light {
  color: #e3eaef;
  border-color: #e3eaef;
}
.btn-outline-light:hover {
  color: #313a46;
  background-color: #e3eaef;
  border-color: #e3eaef;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 234, 239, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #e3eaef;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #313a46;
  background-color: #e3eaef;
  border-color: #e3eaef;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(227, 234, 239, 0.5);
}

.btn-outline-dark {
  color: #313a46;
  border-color: #313a46;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #313a46;
  border-color: #313a46;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 58, 70, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #313a46;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #313a46;
  border-color: #313a46;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 58, 70, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #727cf5;
  text-decoration: none;
}
.btn-link:hover {
  color: #2b3af0;
  text-decoration: none;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: none;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #adb5bd;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.15rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.15rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-bottom: 0;
  border-left: 0.25em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.25rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #6c757d;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d9e3e9;
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.25em solid transparent;
  border-bottom: 0.25em solid;
  border-left: 0.25em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.25em solid transparent;
  border-right: 0;
  border-bottom: 0.25em solid transparent;
  border-left: 0.25em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.25em solid transparent;
  border-right: 0.25em solid;
  border-bottom: 0.25em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.375rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #6c757d;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #272e37;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #313a46;
  text-decoration: none;
  background-color: #edf1f5;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.25rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: inherit;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.375rem 1.5rem;
  color: #6c757d;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.675rem;
  padding-left: 0.675rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.6rem;
  padding-left: 0.6rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.45rem 0.9rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(2.875rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.8725rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.9rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #727cf5;
  border-color: #727cf5;
  background-color: #727cf5;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fafbfe, 0 0 0 0.15rem rgba(114, 124, 245, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #dee2e6 solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #727cf5;
  background-color: #727cf5;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(114, 124, 245, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(114, 124, 245, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(114, 124, 245, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.15625rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #dee2e6;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(114, 124, 245, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.2125rem + 2px);
  padding: 0.45rem 1.9rem 0.45rem 0.9rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #c1c9d0;
  outline: 0;
  box-shadow: none;
}
.custom-select:focus::-ms-value {
  color: #6c757d;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.9rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.2125rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.2125rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #c1c9d0;
  box-shadow: none;
}
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.2125rem + 2px);
  padding: 0.45rem 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.2125rem;
  padding: 0.45rem 0.9rem;
  line-height: 1.5;
  color: #6c757d;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.3rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fafbfe, 0 0 0 0.15rem rgba(114, 124, 245, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fafbfe, 0 0 0 0.15rem rgba(114, 124, 245, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fafbfe, 0 0 0 0.15rem rgba(114, 124, 245, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #727cf5;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: white;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #727cf5;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: white;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.15rem;
  margin-left: 0.15rem;
  background-color: #727cf5;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: white;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #727cf5;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3eaef;
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.5rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.5rem;
}

.card-header {
  padding: 0.75rem 1.5rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid #e3eaef;
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.5rem;
  background-color: #fff;
  border-top: 1px solid #e3eaef;
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -0.75rem;
  margin-left: -0.75rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #ced4da;
  content: "\f142";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #adb5bd;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #313a46;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #313a46;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(114, 124, 245, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #727cf5;
  border-color: #727cf5;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.6em 0.5em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #727cf5;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #4250f2;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(114, 124, 245, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #0acf97;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #089e74;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(10, 207, 151, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #39afd1;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #2991ae;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(57, 175, 209, 0.5);
}

.badge-warning {
  color: #313a46;
  background-color: #ffbc00;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #313a46;
  background-color: #cc9600;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 188, 0, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #fa5c7c;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #f82b54;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(250, 92, 124, 0.5);
}

.badge-light {
  color: #313a46;
  background-color: #e3eaef;
}
a.badge-light:hover, a.badge-light:focus {
  color: #313a46;
  background-color: #c3d2dc;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(227, 234, 239, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #313a46;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1c2128;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(49, 58, 70, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.8125rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #3b407f;
  background-color: #e3e5fd;
  border-color: #d8dafc;
}
.alert-primary hr {
  border-top-color: #c0c4fa;
}
.alert-primary .alert-link {
  color: #2b2e5c;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #056c4f;
  background-color: #cef5ea;
  border-color: #baf2e2;
}
.alert-success hr {
  border-top-color: #a5eed9;
}
.alert-success .alert-link {
  color: #033b2b;
}

.alert-info {
  color: #1e5b6d;
  background-color: #d7eff6;
  border-color: #c8e9f2;
}
.alert-info hr {
  border-top-color: #b3e1ed;
}
.alert-info .alert-link {
  color: #133a45;
}

.alert-warning {
  color: #856200;
  background-color: #fff2cc;
  border-color: #ffecb8;
}
.alert-warning hr {
  border-top-color: #ffe59f;
}
.alert-warning .alert-link {
  color: #523c00;
}

.alert-danger {
  color: #823040;
  background-color: #fedee5;
  border-color: #fed1da;
}
.alert-danger hr {
  border-top-color: #fdb8c6;
}
.alert-danger .alert-link {
  color: #5d222e;
}

.alert-light {
  color: #767a7c;
  background-color: #f9fbfc;
  border-color: #f7f9fb;
}
.alert-light hr {
  border-top-color: #e6ecf3;
}
.alert-light .alert-link {
  color: #5d6062;
}

.alert-dark {
  color: #191e24;
  background-color: #d6d8da;
  border-color: #c5c8cb;
}
.alert-dark hr {
  border-top-color: #b8bbbf;
}
.alert-dark .alert-link {
  color: #040506;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #727cf5;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #e3eaef;
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #727cf5;
  border-color: #007bff;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}
.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #3b407f;
  background-color: #d8dafc;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #3b407f;
  background-color: #c0c4fa;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #3b407f;
  border-color: #3b407f;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #056c4f;
  background-color: #baf2e2;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #056c4f;
  background-color: #a5eed9;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #056c4f;
  border-color: #056c4f;
}

.list-group-item-info {
  color: #1e5b6d;
  background-color: #c8e9f2;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #1e5b6d;
  background-color: #b3e1ed;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #1e5b6d;
  border-color: #1e5b6d;
}

.list-group-item-warning {
  color: #856200;
  background-color: #ffecb8;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856200;
  background-color: #ffe59f;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856200;
  border-color: #856200;
}

.list-group-item-danger {
  color: #823040;
  background-color: #fed1da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #823040;
  background-color: #fdb8c6;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #823040;
  border-color: #823040;
}

.list-group-item-light {
  color: #767a7c;
  background-color: #f7f9fb;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #767a7c;
  background-color: #e6ecf3;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #767a7c;
  border-color: #767a7c;
}

.list-group-item-dark {
  color: #191e24;
  background-color: #c5c8cb;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #191e24;
  background-color: #b8bbbf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #191e24;
  border-color: #191e24;
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: none;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid transparent;
  border-radius: 0.2rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #313a46;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.7;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #343a40;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #343a40;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #343a40;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #343a40;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.4rem 0.8rem;
  color: #f1f3fa;
  text-align: center;
  background-color: #343a40;
  border-radius: 0.2rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e3eaef;
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f1f3fa;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.7rem 0.8rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f1f3fa;
  border-bottom: 1px solid #dee3f3;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #727cf5 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #4250f2 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #0acf97 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #089e74 !important;
}

.bg-info {
  background-color: #39afd1 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #2991ae !important;
}

.bg-warning {
  background-color: #ffbc00 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cc9600 !important;
}

.bg-danger {
  background-color: #fa5c7c !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #f82b54 !important;
}

.bg-light {
  background-color: #e3eaef !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #c3d2dc !important;
}

.bg-dark {
  background-color: #313a46 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1c2128 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #727cf5 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #0acf97 !important;
}

.border-info {
  border-color: #39afd1 !important;
}

.border-warning {
  border-color: #ffbc00 !important;
}

.border-danger {
  border-color: #fa5c7c !important;
}

.border-light {
  border-color: #e3eaef !important;
}

.border-dark {
  border-color: #313a46 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-15 {
  width: 15% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-15 {
  height: 15% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.375rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.375rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.375rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.375rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.375rem !important;
}

.m-2 {
  margin: 0.75rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.75rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.75rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.75rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.75rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1.5rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1.5rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1.5rem !important;
}

.m-4 {
  margin: 2.25rem !important;
}

.mt-4,
.my-4 {
  margin-top: 2.25rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 2.25rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 2.25rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 2.25rem !important;
}

.m-5 {
  margin: 4.5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 4.5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 4.5rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 4.5rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 4.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.375rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.375rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.375rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.375rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.375rem !important;
}

.p-2 {
  padding: 0.75rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.75rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.75rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.75rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.75rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1.5rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1.5rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1.5rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1.5rem !important;
}

.p-4 {
  padding: 2.25rem !important;
}

.pt-4,
.py-4 {
  padding-top: 2.25rem !important;
}

.pr-4,
.px-4 {
  padding-right: 2.25rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 2.25rem !important;
}

.pl-4,
.px-4 {
  padding-left: 2.25rem !important;
}

.p-5 {
  padding: 4.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 4.5rem !important;
}

.pr-5,
.px-5 {
  padding-right: 4.5rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 4.5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 4.5rem !important;
}

.m-n1 {
  margin: -0.375rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.375rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.375rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.375rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.375rem !important;
}

.m-n2 {
  margin: -0.75rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.75rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.75rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.75rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.75rem !important;
}

.m-n3 {
  margin: -1.5rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1.5rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1.5rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1.5rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1.5rem !important;
}

.m-n4 {
  margin: -2.25rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -2.25rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -2.25rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -2.25rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -2.25rem !important;
}

.m-n5 {
  margin: -4.5rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -4.5rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -4.5rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -4.5rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.375rem !important;
  }
  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.375rem !important;
  }
  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.375rem !important;
  }
  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.375rem !important;
  }
  .m-sm-2 {
    margin: 0.75rem !important;
  }
  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.75rem !important;
  }
  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.75rem !important;
  }
  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.75rem !important;
  }
  .m-sm-3 {
    margin: 1.5rem !important;
  }
  .mt-sm-3,
.my-sm-3 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1.5rem !important;
  }
  .m-sm-4 {
    margin: 2.25rem !important;
  }
  .mt-sm-4,
.my-sm-4 {
    margin-top: 2.25rem !important;
  }
  .mr-sm-4,
.mx-sm-4 {
    margin-right: 2.25rem !important;
  }
  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-sm-4,
.mx-sm-4 {
    margin-left: 2.25rem !important;
  }
  .m-sm-5 {
    margin: 4.5rem !important;
  }
  .mt-sm-5,
.my-sm-5 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-5,
.mx-sm-5 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-5,
.mx-sm-5 {
    margin-left: 4.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.375rem !important;
  }
  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.375rem !important;
  }
  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.375rem !important;
  }
  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.375rem !important;
  }
  .p-sm-2 {
    padding: 0.75rem !important;
  }
  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.75rem !important;
  }
  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.75rem !important;
  }
  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.75rem !important;
  }
  .p-sm-3 {
    padding: 1.5rem !important;
  }
  .pt-sm-3,
.py-sm-3 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-3,
.px-sm-3 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-3,
.px-sm-3 {
    padding-left: 1.5rem !important;
  }
  .p-sm-4 {
    padding: 2.25rem !important;
  }
  .pt-sm-4,
.py-sm-4 {
    padding-top: 2.25rem !important;
  }
  .pr-sm-4,
.px-sm-4 {
    padding-right: 2.25rem !important;
  }
  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-sm-4,
.px-sm-4 {
    padding-left: 2.25rem !important;
  }
  .p-sm-5 {
    padding: 4.5rem !important;
  }
  .pt-sm-5,
.py-sm-5 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-5,
.px-sm-5 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-5,
.px-sm-5 {
    padding-left: 4.5rem !important;
  }
  .m-sm-n1 {
    margin: -0.375rem !important;
  }
  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.375rem !important;
  }
  .m-sm-n2 {
    margin: -0.75rem !important;
  }
  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.75rem !important;
  }
  .m-sm-n3 {
    margin: -1.5rem !important;
  }
  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n4 {
    margin: -2.25rem !important;
  }
  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -2.25rem !important;
  }
  .m-sm-n5 {
    margin: -4.5rem !important;
  }
  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -4.5rem !important;
  }
  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -4.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.375rem !important;
  }
  .mt-md-1,
.my-md-1 {
    margin-top: 0.375rem !important;
  }
  .mr-md-1,
.mx-md-1 {
    margin-right: 0.375rem !important;
  }
  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-md-1,
.mx-md-1 {
    margin-left: 0.375rem !important;
  }
  .m-md-2 {
    margin: 0.75rem !important;
  }
  .mt-md-2,
.my-md-2 {
    margin-top: 0.75rem !important;
  }
  .mr-md-2,
.mx-md-2 {
    margin-right: 0.75rem !important;
  }
  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-md-2,
.mx-md-2 {
    margin-left: 0.75rem !important;
  }
  .m-md-3 {
    margin: 1.5rem !important;
  }
  .mt-md-3,
.my-md-3 {
    margin-top: 1.5rem !important;
  }
  .mr-md-3,
.mx-md-3 {
    margin-right: 1.5rem !important;
  }
  .mb-md-3,
.my-md-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-3,
.mx-md-3 {
    margin-left: 1.5rem !important;
  }
  .m-md-4 {
    margin: 2.25rem !important;
  }
  .mt-md-4,
.my-md-4 {
    margin-top: 2.25rem !important;
  }
  .mr-md-4,
.mx-md-4 {
    margin-right: 2.25rem !important;
  }
  .mb-md-4,
.my-md-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-md-4,
.mx-md-4 {
    margin-left: 2.25rem !important;
  }
  .m-md-5 {
    margin: 4.5rem !important;
  }
  .mt-md-5,
.my-md-5 {
    margin-top: 4.5rem !important;
  }
  .mr-md-5,
.mx-md-5 {
    margin-right: 4.5rem !important;
  }
  .mb-md-5,
.my-md-5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-5,
.mx-md-5 {
    margin-left: 4.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.375rem !important;
  }
  .pt-md-1,
.py-md-1 {
    padding-top: 0.375rem !important;
  }
  .pr-md-1,
.px-md-1 {
    padding-right: 0.375rem !important;
  }
  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-md-1,
.px-md-1 {
    padding-left: 0.375rem !important;
  }
  .p-md-2 {
    padding: 0.75rem !important;
  }
  .pt-md-2,
.py-md-2 {
    padding-top: 0.75rem !important;
  }
  .pr-md-2,
.px-md-2 {
    padding-right: 0.75rem !important;
  }
  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-md-2,
.px-md-2 {
    padding-left: 0.75rem !important;
  }
  .p-md-3 {
    padding: 1.5rem !important;
  }
  .pt-md-3,
.py-md-3 {
    padding-top: 1.5rem !important;
  }
  .pr-md-3,
.px-md-3 {
    padding-right: 1.5rem !important;
  }
  .pb-md-3,
.py-md-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-3,
.px-md-3 {
    padding-left: 1.5rem !important;
  }
  .p-md-4 {
    padding: 2.25rem !important;
  }
  .pt-md-4,
.py-md-4 {
    padding-top: 2.25rem !important;
  }
  .pr-md-4,
.px-md-4 {
    padding-right: 2.25rem !important;
  }
  .pb-md-4,
.py-md-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-md-4,
.px-md-4 {
    padding-left: 2.25rem !important;
  }
  .p-md-5 {
    padding: 4.5rem !important;
  }
  .pt-md-5,
.py-md-5 {
    padding-top: 4.5rem !important;
  }
  .pr-md-5,
.px-md-5 {
    padding-right: 4.5rem !important;
  }
  .pb-md-5,
.py-md-5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-5,
.px-md-5 {
    padding-left: 4.5rem !important;
  }
  .m-md-n1 {
    margin: -0.375rem !important;
  }
  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.375rem !important;
  }
  .m-md-n2 {
    margin: -0.75rem !important;
  }
  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.75rem !important;
  }
  .m-md-n3 {
    margin: -1.5rem !important;
  }
  .mt-md-n3,
.my-md-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1.5rem !important;
  }
  .m-md-n4 {
    margin: -2.25rem !important;
  }
  .mt-md-n4,
.my-md-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-md-n4,
.mx-md-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-md-n4,
.mx-md-n4 {
    margin-left: -2.25rem !important;
  }
  .m-md-n5 {
    margin: -4.5rem !important;
  }
  .mt-md-n5,
.my-md-n5 {
    margin-top: -4.5rem !important;
  }
  .mr-md-n5,
.mx-md-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md-n5,
.mx-md-n5 {
    margin-left: -4.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.375rem !important;
  }
  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.375rem !important;
  }
  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.375rem !important;
  }
  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.375rem !important;
  }
  .m-lg-2 {
    margin: 0.75rem !important;
  }
  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.75rem !important;
  }
  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.75rem !important;
  }
  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.75rem !important;
  }
  .m-lg-3 {
    margin: 1.5rem !important;
  }
  .mt-lg-3,
.my-lg-3 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1.5rem !important;
  }
  .m-lg-4 {
    margin: 2.25rem !important;
  }
  .mt-lg-4,
.my-lg-4 {
    margin-top: 2.25rem !important;
  }
  .mr-lg-4,
.mx-lg-4 {
    margin-right: 2.25rem !important;
  }
  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-lg-4,
.mx-lg-4 {
    margin-left: 2.25rem !important;
  }
  .m-lg-5 {
    margin: 4.5rem !important;
  }
  .mt-lg-5,
.my-lg-5 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-5,
.mx-lg-5 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-5,
.mx-lg-5 {
    margin-left: 4.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.375rem !important;
  }
  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.375rem !important;
  }
  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.375rem !important;
  }
  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.375rem !important;
  }
  .p-lg-2 {
    padding: 0.75rem !important;
  }
  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.75rem !important;
  }
  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.75rem !important;
  }
  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.75rem !important;
  }
  .p-lg-3 {
    padding: 1.5rem !important;
  }
  .pt-lg-3,
.py-lg-3 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-3,
.px-lg-3 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-3,
.px-lg-3 {
    padding-left: 1.5rem !important;
  }
  .p-lg-4 {
    padding: 2.25rem !important;
  }
  .pt-lg-4,
.py-lg-4 {
    padding-top: 2.25rem !important;
  }
  .pr-lg-4,
.px-lg-4 {
    padding-right: 2.25rem !important;
  }
  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-lg-4,
.px-lg-4 {
    padding-left: 2.25rem !important;
  }
  .p-lg-5 {
    padding: 4.5rem !important;
  }
  .pt-lg-5,
.py-lg-5 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-5,
.px-lg-5 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-5,
.px-lg-5 {
    padding-left: 4.5rem !important;
  }
  .m-lg-n1 {
    margin: -0.375rem !important;
  }
  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.375rem !important;
  }
  .m-lg-n2 {
    margin: -0.75rem !important;
  }
  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.75rem !important;
  }
  .m-lg-n3 {
    margin: -1.5rem !important;
  }
  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n4 {
    margin: -2.25rem !important;
  }
  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -2.25rem !important;
  }
  .m-lg-n5 {
    margin: -4.5rem !important;
  }
  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -4.5rem !important;
  }
  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -4.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.375rem !important;
  }
  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.375rem !important;
  }
  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.375rem !important;
  }
  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.375rem !important;
  }
  .m-xl-2 {
    margin: 0.75rem !important;
  }
  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.75rem !important;
  }
  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.75rem !important;
  }
  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.75rem !important;
  }
  .m-xl-3 {
    margin: 1.5rem !important;
  }
  .mt-xl-3,
.my-xl-3 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1.5rem !important;
  }
  .m-xl-4 {
    margin: 2.25rem !important;
  }
  .mt-xl-4,
.my-xl-4 {
    margin-top: 2.25rem !important;
  }
  .mr-xl-4,
.mx-xl-4 {
    margin-right: 2.25rem !important;
  }
  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 2.25rem !important;
  }
  .ml-xl-4,
.mx-xl-4 {
    margin-left: 2.25rem !important;
  }
  .m-xl-5 {
    margin: 4.5rem !important;
  }
  .mt-xl-5,
.my-xl-5 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-5,
.mx-xl-5 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-5,
.mx-xl-5 {
    margin-left: 4.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.375rem !important;
  }
  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.375rem !important;
  }
  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.375rem !important;
  }
  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.375rem !important;
  }
  .p-xl-2 {
    padding: 0.75rem !important;
  }
  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.75rem !important;
  }
  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.75rem !important;
  }
  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.75rem !important;
  }
  .p-xl-3 {
    padding: 1.5rem !important;
  }
  .pt-xl-3,
.py-xl-3 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-3,
.px-xl-3 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-3,
.px-xl-3 {
    padding-left: 1.5rem !important;
  }
  .p-xl-4 {
    padding: 2.25rem !important;
  }
  .pt-xl-4,
.py-xl-4 {
    padding-top: 2.25rem !important;
  }
  .pr-xl-4,
.px-xl-4 {
    padding-right: 2.25rem !important;
  }
  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 2.25rem !important;
  }
  .pl-xl-4,
.px-xl-4 {
    padding-left: 2.25rem !important;
  }
  .p-xl-5 {
    padding: 4.5rem !important;
  }
  .pt-xl-5,
.py-xl-5 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-5,
.px-xl-5 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-5,
.px-xl-5 {
    padding-left: 4.5rem !important;
  }
  .m-xl-n1 {
    margin: -0.375rem !important;
  }
  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.375rem !important;
  }
  .m-xl-n2 {
    margin: -0.75rem !important;
  }
  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.75rem !important;
  }
  .m-xl-n3 {
    margin: -1.5rem !important;
  }
  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n4 {
    margin: -2.25rem !important;
  }
  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -2.25rem !important;
  }
  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -2.25rem !important;
  }
  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -2.25rem !important;
  }
  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -2.25rem !important;
  }
  .m-xl-n5 {
    margin: -4.5rem !important;
  }
  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -4.5rem !important;
  }
  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -4.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #727cf5 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #2b3af0 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #0acf97 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #068662 !important;
}

.text-info {
  color: #39afd1 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #247f9a !important;
}

.text-warning {
  color: #ffbc00 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #b38400 !important;
}

.text-danger {
  color: #fa5c7c !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #f81240 !important;
}

.text-light {
  color: #e3eaef !important;
}

a.text-light:hover, a.text-light:focus {
  color: #b2c5d3 !important;
}

.text-dark {
  color: #313a46 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121519 !important;
}

.text-body {
  color: #6c757d !important;
}

.text-muted {
  color: #98a6ad !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
img {
    page-break-inside: avoid;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }
  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #e3eaef;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #e3eaef;
  }
}
html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}
.logo {
  display: block;
  line-height: 80px;
}
.logo span.logo-lg {
  display: block;
}
.logo span.logo-sm {
  display: none;
}

.wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.content-page {
  margin-left: 250px;
  overflow: hidden;
  padding: 5px 20px 65px;
  min-height: 80vh;
}

.left-side-menu {
  width: 250px;
  z-index: 10;
  background: linear-gradient(135deg, #8f75da 0%, #727cf5 60%);
  bottom: 0;
  position: fixed;
  transition: all 0.2s ease-out;
  top: 0;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
}

.side-nav .side-nav-link {
  color: #cedce4;
  display: block;
  padding: 15px 30px;
  font-size: 0.9375rem;
  position: relative;
  transition: all 0.4s;
}
.side-nav .side-nav-link:hover, .side-nav .side-nav-link:focus, .side-nav .side-nav-link:active {
  color: #ffffff;
  text-decoration: none;
}
.side-nav .side-nav-link span {
  vertical-align: middle;
}
.side-nav .side-nav-link i {
  display: inline-block;
  line-height: 1.0625rem;
  margin: 0 10px 0 3px;
  text-align: center;
  vertical-align: middle;
  width: 20px;
}
.side-nav .menu-arrow {
  transition: transform 0.15s;
  position: absolute;
  right: 30px;
  display: inline-block;
  font-family: "Material Design Icons";
  text-rendering: auto;
  line-height: 1.5rem;
  font-size: 1.1rem;
  transform: translate(0, 0);
}
.side-nav .menu-arrow:before {
  content: "\f142";
}
.side-nav .badge {
  margin-top: 3px;
}
.side-nav .side-nav-item.mm-active > a .menu-arrow {
  transform: rotate(90deg);
}
.side-nav .side-nav-title {
  padding: 12px 30px;
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: 0.6875rem;
  text-transform: uppercase;
  color: #cedce4;
  font-weight: 700;
}
.side-nav .side-nav-link.active {
  color: #ffffff;
}
.side-nav .mm-active > a {
  color: #ffffff !important;
}

@media (max-width: 992px) and (min-width: 576px) {
  .wrapper .left-side-menu {
    position: absolute;
    padding-top: 0;
    width: 70px;
    z-index: 5;
  }
  .wrapper .left-side-menu .slimScrollDiv,
.wrapper .left-side-menu .slimscroll-menu {
    overflow: inherit !important;
    height: auto !important;
  }
  .wrapper .left-side-menu .slimScrollBar {
    visibility: hidden;
  }
  .wrapper .help-box {
    display: none;
  }
  .wrapper .content-page {
    margin-left: 70px;
  }
  .wrapper .footer {
    left: 70px;
  }
  .side-nav .side-nav-title,
.side-nav .menu-arrow,
.side-nav .badge,
.side-nav .collapse.in,
.side-nav .mm-collapse {
    display: none !important;
  }
  .side-nav .nav.collapse {
    height: inherit !important;
  }
  .side-nav .side-nav-item {
    position: relative;
    white-space: nowrap;
  }
  .side-nav .side-nav-item .side-nav-link {
    padding: 15px 20px;
    min-height: 56px;
  }
  .side-nav .side-nav-item .side-nav-link:hover, .side-nav .side-nav-item .side-nav-link:active, .side-nav .side-nav-item .side-nav-link:focus {
    color: #ffffff;
  }
  .side-nav .side-nav-item .side-nav-link i {
    font-size: 1.125rem;
    margin-right: 20px;
  }
  .side-nav .side-nav-item .side-nav-link span {
    display: none;
    padding-left: 10px;
  }
  .side-nav .side-nav-item:not(:hover) .collapse {
    display: none !important;
  }
  .side-nav .side-nav-item:hover .side-nav-link {
    position: relative;
    width: 260px;
    color: #fff;
    background: linear-gradient(135deg, #8f75da 0%, #727cf5 60%);
  }
  .side-nav .side-nav-item:hover .side-nav-link span {
    display: inline;
  }
  .side-nav .side-nav-item:hover > ul,
.side-nav .side-nav-item:hover > div > ul,
.side-nav .side-nav-item:hover > div.collapse > div.accordion > ul {
    display: block !important;
    left: 70px;
    position: absolute;
    width: 190px;
    height: auto !important;
    padding: 5px 0;
    z-index: 9999;
    background: linear-gradient(135deg, #8f75da 0%, #727cf5 60%);
  }
  .side-nav .side-nav-item:hover > ul a,
.side-nav .side-nav-item:hover > div > ul a,
.side-nav .side-nav-item:hover > div.collapse > div.accordion > ul a {
    padding: 8px 20px;
    position: relative;
    width: 190px;
  }
  .side-nav .side-nav-item:hover > ul a:hover,
.side-nav .side-nav-item:hover > div > ul a:hover,
.side-nav .side-nav-item:hover > div.collapse > div.accordion > ul a:hover {
    color: #ffffff;
  }
  .side-nav .side-nav-item:hover > ul ul,
.side-nav .side-nav-item:hover > div > ul ul,
.side-nav .side-nav-item:hover > div.collapse > div.accordion > ul ul {
    left: 190px;
    top: 0;
  }
  .logo span.logo-lg {
    display: none;
  }
  .logo span.logo-sm {
    display: block;
    line-height: 70px;
    color: #727cf5;
  }
}
@media (max-width: 767.98px) {
  body {
    overflow-x: hidden;
  }
  .left-side-menu {
    box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
    display: none;
    z-index: 10 !important;
  }
  .sidebar-enable .left-side-menu {
    display: block;
  }
  .navbar-nav.navbar-right {
    float: right;
  }
  .content-page {
    margin-left: 0 !important;
    padding: 5px 10px 100px;
  }
  body[data-leftbar-compact-mode=condensed] .left-side-menu {
    margin-left: -70px;
  }
  .logo span.logo-lg {
    display: block;
  }
  .logo span.logo-sm {
    display: none;
  }
}
.help-box {
  border-radius: 5px;
  padding: 20px;
  margin: 65px 25px 25px;
  position: relative;
  background-color: #7e87f6;
}
.help-box .close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}

.help-box-light {
  background-color: #f1f3fa;
}

body[data-layout=topnav] .content-page {
  margin-left: 0 !important;
  padding: 0 0 60px 0;
}

body[data-layout-mode=boxed] {
  background-color: #ffffff;
}
body[data-layout-mode=boxed] .wrapper {
  max-width: 1300px;
  margin: 0 auto;
  background-color: #fafbfe;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
}
body[data-layout-mode=boxed] body[data-leftbar-compact-mode=condensed] .footer {
  max-width: calc(1300px - 70px);
}

@media (min-width: 1200px) {
  body[data-leftbar-compact-mode=scrollable] {
    padding-bottom: 0;
  }
  body[data-leftbar-compact-mode=scrollable] .wrapper {
    display: flex;
  }
  body[data-leftbar-compact-mode=scrollable] .left-side-menu {
    position: relative;
    min-width: 250px;
    max-width: 250px;
    height: 100%;
  }
  body[data-leftbar-compact-mode=scrollable] .content-page {
    margin-left: 0;
    width: 100%;
    padding-bottom: 60px;
  }
}
body[data-layout=detached] {
  padding-bottom: 0;
}
@media (min-width: 992px) {
  body[data-layout=detached] .container-fluid {
    max-width: 95%;
  }
}
body[data-layout=detached] .wrapper {
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  padding: 0 15px;
  overflow: inherit;
}
body[data-layout=detached] .wrapper .container-fluid {
  max-width: 100%;
  padding: 0;
}
body[data-layout=detached] .content-page {
  margin-left: 0;
  overflow: hidden;
  padding: 0 15px 5px 30px;
  position: relative;
  margin-right: -15px;
  width: 100%;
  padding-bottom: 60px;
}
body[data-layout=detached] .left-side-menu {
  position: relative;
  background: #ffffff;
  min-width: 250px;
  max-width: 250px;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  margin-top: 30px;
  height: calc(100% - 30px);
}
body[data-layout=detached] .left-side-menu .side-nav .side-nav-link {
  color: #6c757d;
}
body[data-layout=detached] .left-side-menu .side-nav .side-nav-link:hover, body[data-layout=detached] .left-side-menu .side-nav .side-nav-link:focus, body[data-layout=detached] .left-side-menu .side-nav .side-nav-link:active {
  color: #727cf5;
}
body[data-layout=detached] .left-side-menu .side-nav .side-nav-link.active {
  color: #727cf5;
}
body[data-layout=detached] .left-side-menu .side-nav .mm-active > a {
  color: #727cf5 !important;
}
body[data-layout=detached] .left-side-menu .side-nav .side-nav-title {
  color: #6c757d;
}
body[data-layout=detached] .leftbar-user {
  background: url("../assets/images/waves.png") no-repeat;
  padding: 30px 20px;
  text-align: center;
}
body[data-layout=detached] .leftbar-user .leftbar-user-name {
  font-weight: 700;
  color: #313a46;
  margin-left: 12px;
  margin-top: 8px;
  display: block;
}
@media (max-width: 767.98px) {
  body[data-layout=detached].sidebar-enable .left-side-menu {
    position: fixed;
    left: 0;
    overflow-y: auto;
    margin-top: 0;
  }
  body[data-layout=detached] .wrapper {
    max-width: 100%;
  }
  body[data-layout=detached] .content-page {
    margin-left: 0 !important;
    padding: 0 10px 60px 10px;
  }
  body[data-layout=detached] .lang-switch {
    display: none;
  }
}
body[data-layout=detached][data-leftbar-compact-mode=condensed] .wrapper .left-side-menu {
  max-width: 70px;
  min-width: 70px;
  position: relative;
}
body[data-layout=detached][data-leftbar-compact-mode=condensed] .wrapper .leftbar-user {
  display: none;
}
body[data-layout=detached][data-leftbar-compact-mode=condensed] .wrapper .content-page {
  margin-left: 0;
}
body[data-layout=detached][data-leftbar-compact-mode=condensed] .wrapper .footer {
  left: 0;
}
body[data-layout=detached][data-leftbar-compact-mode=condensed] .side-nav .side-nav-item .side-nav-link:hover, body[data-layout=detached][data-leftbar-compact-mode=condensed] .side-nav .side-nav-item .side-nav-link:active, body[data-layout=detached][data-leftbar-compact-mode=condensed] .side-nav .side-nav-item .side-nav-link:focus {
  color: #ffffff;
}
body[data-layout=detached][data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover .side-nav-link {
  background: #727cf5;
  color: #fff !important;
  transition: none;
}
body[data-layout=detached][data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover > ul {
  background: #ffffff;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
}
body[data-layout=detached][data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover > ul a:hover {
  color: #727cf5;
}

.button-menu-mobile .lines {
  width: 18px;
  display: block;
  position: relative;
  height: 16px;
  transition: all 0.5s ease;
  margin-top: 26px;
  margin-left: 10px;
}
.button-menu-mobile span {
  height: 2px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: block;
  margin-bottom: 5px;
  transition: transform 0.5s ease;
}
.button-menu-mobile span:nth-of-type(2) {
  width: 24px;
}
.button-menu-mobile.disable-btn {
  display: none;
}

body[data-leftbar-theme=light] .left-side-menu {
  background: #ffffff;
}
body[data-leftbar-theme=light] .side-nav .side-nav-link {
  color: #6c757d;
}
body[data-leftbar-theme=light] .side-nav .side-nav-link:hover, body[data-leftbar-theme=light] .side-nav .side-nav-link:focus, body[data-leftbar-theme=light] .side-nav .side-nav-link:active {
  color: #727cf5;
}
body[data-leftbar-theme=light] .side-nav .side-nav-link.active {
  color: #727cf5;
}
body[data-leftbar-theme=light] .side-nav .mm-active > a {
  color: #727cf5 !important;
}
body[data-leftbar-theme=light] .side-nav .side-nav-title {
  color: #6c757d;
}
body[data-leftbar-theme=light][data-leftbar-compact-mode=condensed] .side-nav .side-nav-item .side-nav-link:hover, body[data-leftbar-theme=light][data-leftbar-compact-mode=condensed] .side-nav .side-nav-item .side-nav-link:active, body[data-leftbar-theme=light][data-leftbar-compact-mode=condensed] .side-nav .side-nav-item .side-nav-link:focus {
  color: #ffffff;
}
body[data-leftbar-theme=light][data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover .side-nav-link {
  background: #727cf5;
  color: #fff !important;
  transition: none;
}
body[data-leftbar-theme=light][data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover > ul {
  background: #ffffff;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
}
body[data-leftbar-theme=light][data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover > ul a:hover {
  color: #727cf5;
}

body[data-leftbar-theme=dark] .left-side-menu {
  background: #313a46;
}
body[data-leftbar-theme=dark] .side-nav .side-nav-link {
  color: #8391a2;
}
body[data-leftbar-theme=dark] .side-nav .side-nav-link:hover, body[data-leftbar-theme=dark] .side-nav .side-nav-link:focus, body[data-leftbar-theme=dark] .side-nav .side-nav-link:active {
  color: #bccee4;
}
body[data-leftbar-theme=dark] .side-nav .side-nav-link.active {
  color: #ffffff;
}
body[data-leftbar-theme=dark] .side-nav .mm-active > a {
  color: #ffffff !important;
}
body[data-leftbar-theme=dark] .side-nav .side-nav-title {
  color: #8391a2;
}
body[data-leftbar-theme=dark][data-leftbar-compact-mode=condensed] .side-nav .side-nav-item .side-nav-link:hover, body[data-leftbar-theme=dark][data-leftbar-compact-mode=condensed] .side-nav .side-nav-item .side-nav-link:active, body[data-leftbar-theme=dark][data-leftbar-compact-mode=condensed] .side-nav .side-nav-item .side-nav-link:focus {
  color: #bccee4;
}
body[data-leftbar-theme=dark][data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover .side-nav-link {
  background: #727cf5;
  color: #fff !important;
  transition: none;
}
body[data-leftbar-theme=dark][data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover > ul {
  background: #313a46;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
}
body[data-leftbar-theme=dark][data-leftbar-compact-mode=condensed] .side-nav .side-nav-item:hover > ul a:hover {
  color: #bccee4;
}

.navbar-custom {
  padding: 0 30px;
  background-color: #ffffff;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  margin: -5px -20px 0;
  min-height: 70px;
}
.navbar-custom .topbar-left {
  background-color: #ffffff;
  height: 70px;
  position: fixed;
  z-index: 1;
  width: 250px;
  text-align: center;
  top: 0;
  left: 0;
}
.navbar-custom .topbar-left .logo {
  line-height: 70px;
}
.navbar-custom .topbar-left .logo i {
  display: none;
}
.navbar-custom .topbar-right-menu li {
  float: left;
  max-height: 70px;
}
.navbar-custom .topbar-right-menu li.show .nav-link {
  color: #6c757d;
}
.navbar-custom .topbar-right-menu .nav-link {
  padding: 0;
  color: #98a6ad;
  min-width: 32px;
  display: block;
  text-align: center;
  margin: 0 10px;
}

.topbar-dropdown .nav-link {
  line-height: 70px;
}

/* Search */
.app-search {
  padding: calc(32px / 2) 0;
  overflow: hidden;
}
.app-search form {
  max-width: 320px;
}
.app-search .form-control {
  border: none;
  height: 38px;
  padding-left: 40px;
  padding-right: 20px;
  background-color: #f1f3fa;
  box-shadow: none;
}
.app-search span {
  position: absolute;
  z-index: 10;
  font-size: 20px;
  line-height: 38px;
  left: 10px;
}
.app-search .input-group-append {
  margin-left: 0;
  z-index: 4;
}

/* Notification */
.notification-list {
  margin-left: 0;
}
.notification-list .dropdown-menu.dropdown-menu-right {
  transform: none !important;
  top: 100% !important;
  right: 0 !important;
  left: auto !important;
}
.notification-list .noti-title {
  background-color: transparent;
  padding: 15px 20px;
}
.notification-list .noti-icon {
  font-size: 22px;
  vertical-align: middle;
  line-height: 70px;
}
.notification-list .noti-icon-badge {
  display: inline-block;
  position: absolute;
  top: 22px;
  right: 18px;
  border-radius: 50%;
  height: 7px;
  width: 7px;
  background-color: #fa5c7c;
}
.notification-list .notify-item {
  padding: 10px 20px;
}
.notification-list .notify-item .notify-icon {
  float: left;
  height: 36px;
  width: 36px;
  line-height: 36px;
  text-align: center;
  margin-right: 10px;
  border-radius: 50%;
  color: #fff;
}
.notification-list .notify-item .notify-details {
  margin-bottom: 0;
  overflow: hidden;
  margin-left: 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.notification-list .notify-item .notify-details b {
  font-weight: 500;
}
.notification-list .notify-item .notify-details small {
  display: block;
}
.notification-list .notify-item .notify-details span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}
.notification-list .notify-item .user-msg {
  margin-left: 45px;
  white-space: normal;
  line-height: 16px;
}
.notification-list .topbar-dropdown-menu .notify-item {
  padding: 7px 20px;
}

.profile-dropdown {
  width: 170px;
}
.profile-dropdown i, .profile-dropdown span {
  vertical-align: middle;
}

.nav-user {
  padding: calc(32px / 2) 20px calc(32px / 2) 57px !important;
  text-align: left !important;
  position: relative;
  background-color: #fafbfd;
  border: 1px solid #f1f3fa;
  border-width: 0 1px;
  min-height: 70px;
}
.nav-user .account-user-avatar {
  position: absolute;
  top: calc(38px / 2);
  left: 15px;
}
.nav-user .account-user-avatar img {
  height: 32px;
  width: 32px;
}
.nav-user .account-position {
  display: block;
  font-size: 12px;
  margin-top: -3px;
}
.nav-user .account-user-name {
  display: block;
  font-weight: 600;
}

.button-menu-mobile {
  border: none;
  color: #313a46;
  height: 70px;
  line-height: 70px;
  width: 60px;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  float: left;
}
.button-menu-mobile.disable-btn {
  display: none;
}

[data-keep-enlarged=true] .navbar-custom {
  padding-left: 0;
}
[data-keep-enlarged=true] .button-menu-mobile.disable-btn {
  display: inline-block;
}

@media (max-width: 1024px) {
  .navbar-custom {
    left: 70px;
    right: 0;
  }
  .app-search {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-custom {
    left: 0;
    padding: 0 10px;
    margin: -5px -10px 0;
  }
  .button-menu-mobile.disable-btn {
    display: inline-block;
  }
  .nav-user {
    padding: 17px 5px 17px 57px !important;
  }
  .nav-user .account-position,
.nav-user .account-user-name {
    display: none;
  }
}
.topnav-navbar-dark {
  background-color: #313a46;
}
.topnav-navbar-dark .nav-user {
  background-color: #3c4655;
  border: 1px solid #414d5d;
}
.topnav-navbar-dark .topbar-right-menu li.show .nav-link {
  color: #fff;
}
.topnav-navbar-dark .app-search .form-control {
  background-color: #3c4655;
  color: #fff;
}
.topnav-navbar-dark .app-search span {
  color: #98a6ad;
}

.right-bar {
  background-color: #ffffff;
  box-shadow: 0 0 24px 0 rgba(49, 58, 70, 0.1), 0 1px 0 0 rgba(49, 58, 70, 0.08);
  display: block;
  position: fixed;
  transition: all 200ms ease-out;
  width: 260px;
  z-index: 9999;
  float: right !important;
  right: -270px;
  top: 0;
  bottom: 0;
}
.right-bar .rightbar-content {
  padding-bottom: 70px;
}
.right-bar .rightbar-title {
  background-color: #313a46;
  padding: 27px 25px;
  color: #ffffff;
}
.right-bar .right-bar-toggle {
  background-color: #444e5a;
  height: 24px;
  width: 24px;
  line-height: 22px;
  color: #ffffff;
  text-align: center;
  border-radius: 50%;
  margin-top: -4px;
}

.rightbar-overlay {
  background-color: #37404a;
  opacity: 0.1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 9998;
  transition: all 0.2s ease-out;
}

.right-bar-enabled .right-bar {
  right: 0;
}
.right-bar-enabled .rightbar-overlay {
  display: block;
}

@media (max-width: 767.98px) {
  .right-bar {
    overflow: auto;
  }
  .right-bar .slimscroll-menu {
    height: auto !important;
  }
}
.page-title-box .page-title {
  font-size: 18px;
  margin: 0;
  line-height: 85px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: inherit;
}
.page-title-box .page-title-right {
  float: right;
  margin-top: 25px;
}
.page-title-box .breadcrumb {
  padding-top: 8px;
}

.page-title-box-sm .page-title {
  line-height: 1 !important;
  margin-bottom: 25px;
}
.page-title-box-sm .page-title-right {
  float: right;
  margin-top: 0;
}
.page-title-box-sm .breadcrumb {
  padding-top: 0;
  margin-top: -3px !important;
}

.text-title {
  color: #6c757d;
}
.text-title:hover {
  color: #6c757d;
}

@media (max-width: 767.98px) {
  .page-title-box .page-title {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 70px;
  }
  .page-title-box .breadcrumb {
    display: none;
  }
  .page-title-box .page-title-right {
    display: none;
  }
}
@media (max-width: 419px) {
  .page-title-box .breadcrumb {
    display: none;
  }
}
.footer {
  border-top: 1px solid rgba(152, 166, 173, 0.2);
  bottom: 0;
  padding: 19px 30px 20px;
  position: absolute;
  right: 0;
  color: #98a6ad;
  left: 250px;
}
.footer .footer-links a {
  color: #98a6ad;
  margin-left: 1.5rem;
  transition: all 0.4s;
}
.footer .footer-links a:hover {
  color: #313a46;
}
.footer .footer-links a:first-of-type {
  margin-left: 0;
}

@media (max-width: 767.98px) {
  .footer {
    left: 0 !important;
    text-align: center;
  }
}
.footer-alt {
  left: 0;
  border: none;
  text-align: center;
}

body[data-layout=topnav] .footer {
  left: 0 !important;
  padding: 19px 0 20px;
}
body[data-layout=topnav][data-layout-mode=boxed] .footer {
  max-width: 1300px;
}

body[data-layout-mode=boxed] .footer {
  border: none;
  margin: 0 auto;
  background-color: #fafbfe;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  max-width: calc(1300px - 250px);
}

body[data-layout=detached] .footer {
  left: 0;
}
body[data-layout=detached] .footer .container-fluid {
  max-width: 100%;
  padding: 0;
}

.topnav-navbar {
  padding: 0;
  margin: 0;
  min-height: 70px;
}
.topnav-navbar .topnav-logo {
  line-height: 70px;
  float: left;
  padding-right: 20px;
  min-width: 160px;
}
.topnav-navbar .topnav-logo .topnav-logo-sm {
  display: none;
}
.topnav-navbar .navbar-toggle {
  position: relative;
  cursor: pointer;
  float: left;
  margin: 27px 20px;
  padding: 0;
  background-color: transparent;
  border: none;
}
.topnav-navbar .navbar-toggle .lines {
  width: 25px;
  display: block;
  position: relative;
  height: 16px;
  transition: all 0.5s ease;
}
.topnav-navbar .navbar-toggle span {
  height: 2px;
  width: 100%;
  background-color: rgba(49, 58, 70, 0.8);
  display: block;
  margin-bottom: 5px;
  transition: transform 0.5s ease;
}
.topnav-navbar .navbar-toggle.open span {
  position: absolute;
}
.topnav-navbar .navbar-toggle.open span:first-child {
  top: 7px;
  transform: rotate(45deg);
}
.topnav-navbar .navbar-toggle.open span:nth-child(2) {
  visibility: hidden;
}
.topnav-navbar .navbar-toggle.open span:last-child {
  width: 100%;
  top: 7px;
  transform: rotate(-45deg);
}

.topnav {
  background: linear-gradient(to bottom, #8f75da, #727cf5);
}
.topnav .topnav-menu {
  margin: 0;
  padding: 0;
}
.topnav .navbar-nav .nav-link {
  font-size: 0.9375rem;
  position: relative;
  padding: 1rem 1.3rem;
}

.arrow-down {
  display: inline-block;
}
.arrow-down:after {
  border-color: initial;
  border-style: solid;
  border-width: 0 0 1px 1px;
  content: "";
  height: 0.4em;
  display: inline-block;
  right: 5px;
  top: 50%;
  margin-left: 10px;
  transform: rotate(-45deg) translateY(-50%);
  transform-origin: top;
  transition: all 0.3s ease-out;
  width: 0.4em;
}

@media (min-width: 992px) {
  body[data-layout=topnav] .container-fluid {
    max-width: 85%;
  }
  body[data-layout=topnav][data-layout-mode=boxed] .container-fluid {
    max-width: 97%;
  }
  .topnav .navbar-nav .nav-item:first-of-type .nav-link {
    padding-left: 0;
  }
  .topnav .dropdown .dropdown-menu {
    margin-top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
  }
  .topnav .dropdown .dropdown-menu .arrow-down::after {
    right: 15px;
    transform: rotate(-135deg) translateY(-50%);
    position: absolute;
  }
  .topnav .dropdown .dropdown-menu .dropdown .dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
    display: none;
  }
  .topnav .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .topnav .dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-menu {
    display: block;
  }
  .navbar-toggle {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .topnav-navbar .topnav-logo-lg {
    display: none;
  }
  .topnav-navbar .topnav-logo {
    min-width: 50px;
  }
  .topnav-navbar .topnav-logo-sm {
    display: block !important;
  }
  .topnav .navbar-nav .nav-link {
    padding: 0.75rem 1.3rem;
  }
  .topnav .dropdown .dropdown-menu {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding-left: 15px;
  }
  .topnav .dropdown .dropdown-item {
    position: relative;
    color: rgba(255, 255, 255, 0.5);
    background-color: transparent;
  }
  .topnav .dropdown .dropdown-item.active, .topnav .dropdown .dropdown-item:active {
    color: #fff;
  }
  .topnav .arrow-down::after {
    right: 15px;
    position: absolute;
  }
}
@font-face {
  font-family: "Nunito";
  src: url("../assets/fonts/Nunito-Regular.eot");
  src: local("Nunito Regular"), local("Nunito-Regular"), url("../assets/fonts/Nunito-Regular.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/Nunito-Regular.woff") format("woff"), url("../assets/fonts/Nunito-Regular.ttf") format("truetype"), url("../assets/fonts/Nunito-Regular.svg#Roboto") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("../assets/fonts/Nunito-SemiBold.eot");
  src: local("Nunito SemiBold"), local("Nunito-SemiBold"), url("../assets/fonts/Nunito-SemiBold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/Nunito-SemiBold.woff") format("woff"), url("../assets/fonts/Nunito-SemiBold.ttf") format("truetype"), url("../assets/fonts/Nunito-SemiBold.svg#Roboto") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("../assets/fonts/Nunito-Bold.eot");
  src: local("Nunito Bold"), local("Nunito-Bold"), url("../assets/fonts/Nunito-Bold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/Nunito-Bold.woff") format("woff"), url("../assets/fonts/Nunito-Bold.ttf") format("truetype"), url("../assets/fonts/Nunito-Bold.svg#Roboto") format("svg");
  font-weight: 700;
  font-style: normal;
}
.custom-accordion .card + .card {
  margin-top: 0.5rem;
}
.custom-accordion .accordion-arrow {
  font-size: 1.2rem;
}
.custom-accordion a.collapsed i.accordion-arrow:before {
  content: "\f142";
}

.custom-accordion-title {
  color: #313a46;
}
.custom-accordion-title:hover {
  color: #414d5d;
}

.avatar-xs {
  height: 1.5rem;
  width: 1.5rem;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-title {
  align-items: center;
  background-color: #727cf5;
  color: #fff;
  display: flex;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.breadcrumb-item + .breadcrumb-item::before {
  font-family: "Material Design Icons";
}

.btn .mdi:before {
  line-height: initial;
}

.btn-rounded {
  border-radius: 2em;
}

.btn-primary {
  box-shadow: 0px 2px 6px 0px rgba(114, 124, 245, 0.5);
}

.btn-secondary {
  box-shadow: 0px 2px 6px 0px rgba(108, 117, 125, 0.5);
}

.btn-success {
  box-shadow: 0px 2px 6px 0px rgba(10, 207, 151, 0.5);
}

.btn-info {
  box-shadow: 0px 2px 6px 0px rgba(57, 175, 209, 0.5);
}

.btn-warning {
  box-shadow: 0px 2px 6px 0px rgba(255, 188, 0, 0.5);
}

.btn-danger {
  box-shadow: 0px 2px 6px 0px rgba(250, 92, 124, 0.5);
}

.btn-light {
  box-shadow: 0px 2px 6px 0px rgba(227, 234, 239, 0.5);
}

.btn-dark {
  box-shadow: 0px 2px 6px 0px rgba(49, 58, 70, 0.5);
}

.badge {
  padding-bottom: 0.45em;
}

.badge-primary-lighten {
  color: #727cf5;
  background-color: rgba(114, 124, 245, 0.18);
}
.badge-primary-lighten[href]:hover, .badge-primary-lighten[href]:focus {
  color: #727cf5;
  text-decoration: none;
  background-color: rgba(114, 124, 245, 0.4);
}

.badge-secondary-lighten {
  color: #6c757d;
  background-color: rgba(108, 117, 125, 0.18);
}
.badge-secondary-lighten[href]:hover, .badge-secondary-lighten[href]:focus {
  color: #6c757d;
  text-decoration: none;
  background-color: rgba(108, 117, 125, 0.4);
}

.badge-success-lighten {
  color: #0acf97;
  background-color: rgba(10, 207, 151, 0.18);
}
.badge-success-lighten[href]:hover, .badge-success-lighten[href]:focus {
  color: #0acf97;
  text-decoration: none;
  background-color: rgba(10, 207, 151, 0.4);
}

.badge-info-lighten {
  color: #39afd1;
  background-color: rgba(57, 175, 209, 0.18);
}
.badge-info-lighten[href]:hover, .badge-info-lighten[href]:focus {
  color: #39afd1;
  text-decoration: none;
  background-color: rgba(57, 175, 209, 0.4);
}

.badge-warning-lighten {
  color: #ffbc00;
  background-color: rgba(255, 188, 0, 0.18);
}
.badge-warning-lighten[href]:hover, .badge-warning-lighten[href]:focus {
  color: #ffbc00;
  text-decoration: none;
  background-color: rgba(255, 188, 0, 0.4);
}

.badge-danger-lighten {
  color: #fa5c7c;
  background-color: rgba(250, 92, 124, 0.18);
}
.badge-danger-lighten[href]:hover, .badge-danger-lighten[href]:focus {
  color: #fa5c7c;
  text-decoration: none;
  background-color: rgba(250, 92, 124, 0.4);
}

.badge-light-lighten {
  color: #e3eaef;
  background-color: rgba(227, 234, 239, 0.18);
}
.badge-light-lighten[href]:hover, .badge-light-lighten[href]:focus {
  color: #e3eaef;
  text-decoration: none;
  background-color: rgba(227, 234, 239, 0.4);
}

.badge-dark-lighten {
  color: #313a46;
  background-color: rgba(49, 58, 70, 0.18);
}
.badge-dark-lighten[href]:hover, .badge-dark-lighten[href]:focus {
  color: #313a46;
  text-decoration: none;
  background-color: rgba(49, 58, 70, 0.4);
}

.card {
  border: none;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  margin-bottom: 30px;
}
.card .header-title {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 0.875rem;
  margin-top: 0;
}
.card .card-drop {
  font-size: 20px;
  line-height: 0;
  color: inherit;
}
.card .card-widgets {
  float: right;
  height: 16px;
}
.card .card-widgets > a {
  color: inherit;
  font-size: 18px;
  display: inline-block;
  line-height: 1;
}
.card .card-widgets > a.collapsed i:before {
  content: "\f415";
}

.card-title,
.card-header {
  margin-top: 0;
}

.card-disabled {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0.25rem;
  background: rgba(255, 255, 255, 0.8);
  cursor: progress;
}
.card-disabled .card-portlets-loader {
  background-color: #313a46;
  animation: rotatebox 1.2s infinite ease-in-out;
  height: 30px;
  width: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -15px;
  margin-top: -15px;
}

@keyframes rotatebox {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
.card-pricing {
  position: relative;
}
.card-pricing .card-pricing-plan-name {
  padding-bottom: 20px;
}
.card-pricing .card-pricing-icon {
  font-size: 22px;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  height: 60px;
  display: inline-block;
  width: 60px;
  line-height: 56px;
  border-radius: 50%;
}
.card-pricing .card-pricing-price {
  padding: 30px 0 0;
}
.card-pricing .card-pricing-price span {
  font-size: 40%;
  color: #98a6ad;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.card-pricing .card-pricing-features {
  color: #98a6ad;
  list-style: none;
  margin: 0;
  padding: 20px 0 0 0;
}
.card-pricing .card-pricing-features li {
  padding: 15px;
}

@media (min-width: 768px) {
  .card-pricing-recommended {
    margin-top: -1.9375rem;
  }
}
.card-pricing-recommended .card-pricing-plan-tag {
  background-color: rgba(250, 92, 124, 0.2);
  color: #fa5c7c;
  padding: 5px 0;
  font-weight: 700;
  border-radius: 0.25rem 0.25rem 0 0;
  margin: -1.5rem -1.5rem 1.5rem -1.5rem;
}

.dropdown-menu {
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
}

.dropdown-menu-animated {
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: all 300ms ease;
  margin-top: 20px !important;
}

.show > .dropdown-menu {
  visibility: visible;
  opacity: 1;
  margin-top: 0 !important;
}

.dropdown-lg {
  width: 320px;
}

@media (max-width: 767.98px) {
  .dropdown-lg {
    width: 200px !important;
  }
}
.arrow-none:after {
  display: none;
}

.form-control-light {
  background-color: #f1f3fa !important;
  border-color: #f1f3fa !important;
}

input.form-control[type=color],
input.form-control[type=range] {
  min-height: 39px;
}

.custom-select.is-invalid:focus, .form-control.is-invalid:focus, .custom-select:invalid:focus, .form-control:invalid:focus,
.custom-select.is-valid:focus, .form-control.is-valid:focus, .custom-select:valid:focus, .form-control:valid:focus {
  box-shadow: none !important;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.2125rem + 2px);
}

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8725rem + 2px);
}

.modal-title {
  margin-top: 0;
}

.modal-full-width {
  width: 95%;
  max-width: none;
}

.modal-top {
  margin: 0 auto;
}

.modal-right {
  position: absolute;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;
  margin: 0;
  background-color: #fff;
  align-content: center;
  transform: translate(25%, 0) !important;
}
.modal-right button.close {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.modal.show .modal-right {
  transform: translate(0, 0) !important;
}

.modal-bottom {
  display: flex;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
  margin: 0 auto;
  align-content: center;
}

.modal-colored-header {
  color: #fff;
  border-radius: 0;
}
.modal-colored-header .close {
  color: #fff !important;
}

.modal-filled {
  color: #fff;
}
.modal-filled .modal-header {
  background-color: rgba(255, 255, 255, 0.07);
}
.modal-filled .modal-header,
.modal-filled .modal-footer {
  border: none;
}
.modal-filled .close {
  color: #fff !important;
}

.modal.zoom .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: scale(0);
}

.modal.show .modal-dialog {
  transform: none;
}

.nav-tabs > li > a, .nav-pills > li > a {
  color: #6c757d;
  font-weight: 600;
}

.nav-pills > a {
  color: #6c757d;
  font-weight: 600;
}

.bg-nav-pills {
  background-color: #e3eaef;
}

.nav-tabs.nav-bordered {
  border-bottom: 2px solid rgba(152, 166, 173, 0.2);
}
.nav-tabs.nav-bordered .nav-item {
  margin-bottom: -2px;
}
.nav-tabs.nav-bordered li a {
  border: 0;
  padding: 0.625rem 1.25rem;
}
.nav-tabs.nav-bordered li a.active {
  border-bottom: 2px solid #727cf5;
}

.pagination-rounded .page-link {
  border-radius: 30px !important;
  margin: 0 3px;
  border: none;
}

.popover-header {
  margin-top: 0;
}

@media print {
  .left-side-menu,
.right-bar,
.page-title-box,
.navbar-custom,
.footer {
    display: none;
  }
  .card-body,
.content-page,
.right-bar,
.content,
body {
    padding: 0;
    margin: 0;
  }
}
.progress-sm {
  height: 5px;
}

.progress-md {
  height: 8px;
}

.progress-lg {
  height: 12px;
}

.progress-xl {
  height: 15px;
}

button, a {
  outline: none !important;
}

label {
  font-weight: 600;
}

address.address-lg {
  line-height: 24px;
}

b, strong {
  font-weight: 700;
}

input[data-switch] {
  display: none;
}
input[data-switch] + label {
  width: 56px;
  height: 24px;
  background-color: #f1f3fa;
  background-image: none;
  border-radius: 2rem;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  position: relative;
  transition: all 0.1s ease-in-out;
}
input[data-switch] + label:before {
  color: #313a46;
  content: attr(data-off-label);
  display: block;
  font-family: inherit;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 24px;
  position: absolute;
  right: 3px;
  margin: 0 0.21667rem;
  top: 0;
  text-align: center;
  min-width: 1.66667rem;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
}
input[data-switch] + label:after {
  content: "";
  position: absolute;
  left: 4px;
  background-color: #adb5bd;
  box-shadow: none;
  border-radius: 2rem;
  height: 18px;
  width: 18px;
  top: 3px;
  transition: all 0.1s ease-in-out;
}
input[data-switch]:checked + label {
  background-color: #727cf5;
}
input[data-switch]:checked + label:before {
  color: #fff;
  content: attr(data-on-label);
  right: auto;
  left: 4px;
}
input[data-switch]:checked + label:after {
  left: 34px;
  background-color: #f1f3fa;
}

input[data-switch=bool] + label {
  background-color: #fa5c7c;
}

input[data-switch=bool] + label:before,
input[data-switch=bool]:checked + label:before {
  color: #fff !important;
}

input[data-switch=bool] + label:after {
  background-color: #f1f3fa;
}

input[data-switch=primary]:checked + label {
  background-color: #727cf5;
}

input[data-switch=secondary]:checked + label {
  background-color: #6c757d;
}

input[data-switch=success]:checked + label {
  background-color: #0acf97;
}

input[data-switch=info]:checked + label {
  background-color: #39afd1;
}

input[data-switch=warning]:checked + label {
  background-color: #ffbc00;
}

input[data-switch=danger]:checked + label {
  background-color: #fa5c7c;
}

input[data-switch=light]:checked + label {
  background-color: #e3eaef;
}

input[data-switch=dark]:checked + label {
  background-color: #313a46;
}

.table-centered th, .table-centered td {
  vertical-align: middle !important;
}

.table .table-user img {
  height: 30px;
  width: 30px;
}
.table .action-icon {
  color: #98a6ad;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0 3px;
}
.table .action-icon:hover {
  color: #6c757d;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin: 10px 0;
  font-weight: 700;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.bg-primary-lighten {
  background-color: rgba(114, 124, 245, 0.25) !important;
}

.bg-secondary-lighten {
  background-color: rgba(108, 117, 125, 0.25) !important;
}

.bg-success-lighten {
  background-color: rgba(10, 207, 151, 0.25) !important;
}

.bg-info-lighten {
  background-color: rgba(57, 175, 209, 0.25) !important;
}

.bg-warning-lighten {
  background-color: rgba(255, 188, 0, 0.25) !important;
}

.bg-danger-lighten {
  background-color: rgba(250, 92, 124, 0.25) !important;
}

.bg-light-lighten {
  background-color: rgba(227, 234, 239, 0.25) !important;
}

.bg-dark-lighten {
  background-color: rgba(49, 58, 70, 0.25) !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.flex-basis-0 {
  flex-basis: 0% !important;
}

@media (min-width: 576px) {
  .flex-sm-basis-0 {
    flex-basis: 0% !important;
  }
}
@media (min-width: 768px) {
  .flex-md-basis-0 {
    flex-basis: 0% !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-basis-0 {
    flex-basis: 0% !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-basis-0 {
    flex-basis: 0% !important;
  }
}
.progress-w-percent {
  min-height: 20px;
  margin-bottom: 20px;
}
.progress-w-percent .progress {
  width: calc(100% - 50px);
  float: left;
  margin-top: 8px;
}
.progress-w-percent .progress-value {
  width: 40px;
  float: right;
  text-align: right;
  line-height: 20px;
}

.widget-flat {
  position: relative;
  overflow: hidden;
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .widget-flat i.widget-icon {
    display: none;
  }
}

.widget-icon {
  color: #727cf5;
  font-size: 20px;
  background-color: rgba(114, 124, 245, 0.25);
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 3px;
  display: inline-block;
}

.dash-item-overlay {
  position: absolute;
  left: 8%;
  max-width: 350px;
  padding: 20px;
  z-index: 1;
}

.chart-content-bg {
  background-color: #f9f9fd;
}

.chart-widget-list p {
  border-bottom: 1px solid #f1f3fa;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

.timeline-alt {
  padding: 20px 0;
  position: relative;
}
.timeline-alt .timeline-item {
  position: relative;
}
.timeline-alt .timeline-item:before {
  background-color: #f1f3fa;
  bottom: 0;
  content: "";
  left: 9px;
  position: absolute;
  top: 20px;
  width: 2px;
  z-index: 0;
}
.timeline-alt .timeline-item .timeline-icon {
  float: left;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid transparent;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  background-color: #fff;
}
.timeline-alt .timeline-item .timeline-item-info {
  margin-left: 30px;
}

.inbox-widget .inbox-item {
  border-bottom: 1px solid white;
  overflow: hidden;
  padding: 0.625rem 0;
  position: relative;
}
.inbox-widget .inbox-item:last-of-type {
  border-bottom: none;
}
.inbox-widget .inbox-item .inbox-item-img {
  display: block;
  float: left;
  margin-right: 15px;
  width: 40px;
}
.inbox-widget .inbox-item .inbox-item-img img {
  width: 40px;
}
.inbox-widget .inbox-item .inbox-item-author {
  color: #343a40;
  display: block;
  margin-bottom: 3px;
}
.inbox-widget .inbox-item .inbox-item-text {
  color: #adb5bd;
  display: block;
  font-size: 0.8125rem;
  margin: 0;
}
.inbox-widget .inbox-item .inbox-item-date {
  color: #98a6ad;
  font-size: 0.6875rem;
  position: absolute;
  right: 5px;
  top: 10px;
}

.tilebox-one i {
  position: absolute;
  right: 1.5rem;
  font-size: 2rem;
  opacity: 0.3;
}

.toll-free-box i {
  position: absolute;
  left: 0;
  bottom: -15px;
  font-size: 4rem;
  opacity: 0.4;
  transform: rotate(30deg);
}

.cta-box {
  background-image: url(../assets/images/bg-pattern.png);
  background-size: cover;
}
.cta-box .cta-box-title {
  font-size: 20px;
  line-height: 30px;
}

.conversation-list {
  list-style: none;
  padding: 0 15px;
}
.conversation-list li {
  margin-bottom: 20px;
}
.conversation-list .chat-avatar {
  float: left;
  text-align: center;
  width: 42px;
}
.conversation-list .chat-avatar img {
  border-radius: 100%;
  width: 100%;
}
.conversation-list .chat-avatar i {
  font-size: 12px;
  font-style: normal;
}
.conversation-list .ctext-wrap {
  background: #f1f3fa;
  border-radius: 3px;
  display: inline-block;
  padding: 12px;
  position: relative;
}
.conversation-list .ctext-wrap i {
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  position: relative;
}
.conversation-list .ctext-wrap p {
  margin: 0;
  padding-top: 3px;
}
.conversation-list .ctext-wrap:after {
  right: 99%;
  top: 0;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: #f1f3fa;
  border-width: 6px;
  margin-right: -1px;
  border-right-color: #f1f3fa;
}
.conversation-list .conversation-text {
  float: left;
  font-size: 12px;
  margin-left: 12px;
  width: 70%;
}
.conversation-list .odd .chat-avatar {
  float: right !important;
}
.conversation-list .odd .conversation-text {
  float: right !important;
  margin-right: 12px;
  text-align: right;
  width: 70% !important;
}
.conversation-list .odd .ctext-wrap {
  background-color: #fef5e4;
}
.conversation-list .odd .ctext-wrap:after {
  border-color: transparent;
  border-left-color: #fef5e4;
  border-top-color: #fef5e4;
  left: 99% !important;
}

.calendar-widget .datepicker-inline,
.calendar-widget table {
  width: 100%;
}
.calendar-widget .datepicker-inline tr td, .calendar-widget .datepicker-inline tr th, .calendar-widget .datepicker-inline tr td.active.day, .calendar-widget .datepicker-inline tr td.today.day,
.calendar-widget table tr td,
.calendar-widget table tr th,
.calendar-widget table tr td.active.day,
.calendar-widget table tr td.today.day {
  background-color: transparent !important;
}
.calendar-widget .datepicker-inline tr td.active.day,
.calendar-widget .datepicker-inline tr td.today.day,
.calendar-widget table tr td.active.day,
.calendar-widget table tr td.today.day {
  color: #fa5c7c !important;
  text-shadow: none;
  font-weight: 700;
}
.calendar-widget .datepicker-inline tr td.active.day:hover,
.calendar-widget .datepicker-inline tr td.today.day:hover,
.calendar-widget table tr td.active.day:hover,
.calendar-widget table tr td.today.day:hover {
  background-color: transparent !important;
}
.calendar-widget .datepicker-inline td, .calendar-widget .datepicker-inline th,
.calendar-widget table td,
.calendar-widget table th {
  height: 43px;
}
.calendar-widget .datepicker-inline .datepicker-switch,
.calendar-widget .datepicker-inline .prev,
.calendar-widget .datepicker-inline .next,
.calendar-widget table .datepicker-switch,
.calendar-widget table .prev,
.calendar-widget table .next {
  font-size: 1.1rem;
  background-color: rgba(114, 124, 245, 0.1) !important;
  border-radius: 0;
  color: #727cf5;
}

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 2px);
  display: block;
  border: 2px solid #adb5bd;
  border-radius: 50%;
  color: #adb5bd;
}
.social-list-item:hover {
  color: #98a6ad;
  border-color: #98a6ad;
}

.horizontal-steps {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.horizontal-steps:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 0.2em;
  background-color: #e3eaef;
}
.horizontal-steps .process-line {
  display: block;
  position: absolute;
  width: 50%;
  height: 0.2em;
  background-color: #727cf5;
}
.horizontal-steps .horizontal-steps-content {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.horizontal-steps .horizontal-steps-content .step-item {
  display: block;
  position: relative;
  bottom: calc(100% + 1em);
  height: 8px;
  width: 8px;
  margin: 0 2em;
  box-sizing: content-box;
  color: #727cf5;
  background-color: currentColor;
  border: 0.25em solid #fafbfe;
  border-radius: 50%;
  z-index: 5;
}
.horizontal-steps .horizontal-steps-content .step-item:first-child {
  margin-left: 0;
}
.horizontal-steps .horizontal-steps-content .step-item:last-child {
  margin-right: 0;
  color: #0acf97;
}
.horizontal-steps .horizontal-steps-content .step-item span {
  position: absolute;
  top: calc(100% + 1em);
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color: #adb5bd;
}
.horizontal-steps .horizontal-steps-content .step-item.current:before {
  content: "";
  display: block;
  position: absolute;
  top: 47.5%;
  left: 51%;
  padding: 1em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  z-index: -1;
  animation-name: animation-steps-current;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}
.horizontal-steps .horizontal-steps-content .step-item.current span {
  color: #727cf5;
}

@keyframes animation-steps-current {
  from {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}
@media (max-width: 767.98px) {
  .horizontal-steps .horizontal-steps-content .step-item span {
    white-space: inherit;
  }
}
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f1f3fa;
  z-index: 9999;
}

#status {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -40px 0 0 -40px;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -16px, 0);
  }
}
.bouncing-loader {
  display: flex;
  justify-content: center;
}
.bouncing-loader > div {
  width: 13px;
  height: 13px;
  margin: 32px 3px;
  background: #727cf5;
  border-radius: 50%;
  animation: bouncing-loader 0.6s infinite alternate;
}
.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
  background: #fa5c7c;
}
.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
  background: #0acf97;
}

.authentication-bg {
  background-image: url("../assets/images/bg-pattern-light.svg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.authentication-bg.enlarged, .auth-fluid-pages.enlarged {
  min-height: 100px;
}

.logout-icon {
  width: 140px;
}

.auth-fluid {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
  flex-direction: row;
  align-items: stretch;
  background: url("../assets/images/bg-auth.jpg") center;
  background-size: cover;
}
.auth-fluid .auth-fluid-form-box {
  max-width: 480px;
  border-radius: 0;
  z-index: 2;
  padding: 3rem 2rem;
  background-color: #ffffff;
  position: relative;
  width: 100%;
}
.auth-fluid .auth-fluid-right {
  padding: 6rem 3rem;
  flex: 1;
  position: relative;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
}

.auth-brand {
  margin-bottom: 2rem;
}

.auth-user-testimonial {
  position: absolute;
  margin: 0 auto;
  padding: 0 1.75rem;
  bottom: 3rem;
  left: 0;
  right: 0;
}
.auth-user-testimonial p.lead {
  font-size: 1.125rem;
  margin: 0 auto 20px auto;
  max-width: 700px;
}

@media (min-width: 992px) {
  .auth-brand {
    position: absolute;
    top: 3rem;
  }
}
@media (max-width: 991.98px) {
  .auth-fluid {
    display: block;
  }
  .auth-fluid .auth-fluid-form-box {
    max-width: 100%;
    min-height: 100vh;
  }
  .auth-fluid .auth-fluid-right {
    display: none;
  }
}
.button-list {
  margin-left: -8px;
  margin-bottom: -12px;
}
.button-list .btn {
  margin-bottom: 12px;
  margin-left: 8px;
}

.text-error {
  color: #727cf5;
  text-shadow: rgba(114, 124, 245, 0.3) 5px 1px, rgba(114, 124, 245, 0.2) 10px 3px;
  font-size: 5.25rem;
  line-height: 5.625rem;
}

.faq-question-q-box {
  height: 30px;
  width: 30px;
  color: #fff;
  background-color: #727cf5;
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  text-align: center;
  border-radius: 50%;
  float: left;
  font-weight: 700;
  line-height: 30px;
}

.faq-question {
  margin-top: 0;
  margin-left: 50px;
  font-weight: 400;
  font-size: 16px;
  color: #313a46;
}

.faq-answer {
  margin-left: 50px;
}

.grid-structure .grid-container {
  background-color: #f1f3fa;
  margin-bottom: 10px;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 10px 20px;
}

.icons-list-demo div {
  cursor: pointer;
  line-height: 45px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}
.icons-list-demo div p {
  margin-bottom: 0;
  line-height: inherit;
}
.icons-list-demo i {
  text-align: center;
  vertical-align: middle;
  font-size: 22px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-right: 12px;
  border-radius: 3px;
  display: inline-block;
  transition: all 0.2s;
}
.icons-list-demo .col-md-4 {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box;
  margin-bottom: 10px;
}
.icons-list-demo .col-md-4:hover, .icons-list-demo .col-md-4:hover i {
  color: #727cf5;
}

.maintenance-icon {
  font-size: 22px;
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  height: 60px;
  display: inline-block;
  width: 60px;
  line-height: 58px;
  border-radius: 50%;
}

.board {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
}

.tasks {
  display: inline-block;
  width: 22rem;
  padding: 0 1rem 1rem 1rem;
  border: 1px solid #e3eaef;
  vertical-align: top;
  margin-bottom: 30px;
  border-radius: 0.25rem;
}
.tasks.tasks:not(:last-child) {
  margin-right: 1.25rem;
}
.tasks .card {
  white-space: normal;
  margin-top: 1rem;
}
.tasks .task-header {
  background-color: #f1f3fa;
  padding: 1rem;
  margin: 0 -1rem;
}

.task-list-items {
  min-height: 100px;
  position: relative;
}
.task-list-items:before {
  content: "No Tasks";
  position: absolute;
  line-height: 110px;
  width: 100%;
  text-align: center;
  font-weight: 600;
}

.task-modal-content .form-control-light {
  background-color: #ebf0f4 !important;
  border-color: #ebf0f4 !important;
}

.page-aside-left {
  width: 240px;
  float: left;
  padding: 0 20px 20px 10px;
  position: relative;
}
.page-aside-left:before {
  content: "";
  background-color: #fafbfe;
  width: 5px;
  position: absolute;
  right: -15px;
  height: 100%;
  bottom: -1.5rem;
}

.page-aside-right {
  margin: -1.5rem 0 -1.5rem 250px;
  border-left: 5px solid #fafbfe;
  padding: 1.5rem 0 1.5rem 25px;
}

.email-list {
  display: block;
  padding-left: 0;
  overflow: hidden;
}
.email-list > li {
  position: relative;
  display: block;
  height: 51px;
  line-height: 50px;
  cursor: default;
  transition-duration: 0.3s;
}
.email-list > li a {
  color: #6c757d;
}
.email-list > li a:hover {
  color: #343a40;
}
.email-list > li .col-mail {
  float: left;
  position: relative;
}
.email-list > li .email-sender-info {
  width: 320px;
}
.email-list > li .email-sender-info .star-toggle,
.email-list > li .email-sender-info .checkbox-wrapper-mail {
  display: block;
  float: left;
}
.email-list > li .email-sender-info .checkbox-wrapper-mail {
  margin: 15px 10px 0 20px;
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.email-list > li .email-sender-info .star-toggle {
  color: #adb5bd;
  margin-left: 10px;
  font-size: 18px;
}
.email-list > li .email-sender-info .email-title {
  position: absolute;
  top: 0;
  left: 100px;
  right: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0;
  line-height: 50px;
}
.email-list > li .email-content {
  position: absolute;
  top: 0;
  left: 320px;
  right: 0;
  bottom: 0;
}
.email-list > li .email-content .email-subject,
.email-list > li .email-content .email-date {
  position: absolute;
  top: 0;
}
.email-list > li .email-content .email-subject {
  left: 0;
  right: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.email-list > li .email-content .email-date {
  right: 0;
  width: 100px;
  text-align: right;
  padding-left: 10px;
}
.email-list > li.active, .email-list > li.mail-selected {
  background: #f1f3fa;
  transition-duration: 0.05s;
}
.email-list > li.unread a {
  font-weight: 600;
  color: #272e37;
}
.email-list > li .email-action-icons {
  opacity: 0;
}
.email-list > li .email-action-icons ul {
  display: flex;
  position: absolute;
  transition: all 0.5s;
  right: -180px;
}
.email-list > li .email-action-icons ul li {
  margin: 0 10px;
}
.email-list > li .email-action-icons ul .email-action-icons-item {
  font-size: 20px;
  transition: all 0.5s;
}
.email-list > li .email-action-icons ul .email-action-icons-item:hover {
  color: #fa5c7c;
}
.email-list > li:hover {
  background: #f1f3fa;
  transition-duration: 0.05s;
}
.email-list > li:hover .email-action-icons {
  opacity: 1;
}
.email-list > li:hover .email-action-icons ul {
  transition: all 0.5s;
  right: 10px;
}
.email-list > li:hover .email-content .email-date {
  opacity: 0;
}
.email-list > li:hover .email-content .email-subject {
  right: 180px;
  transition: all 0.5s;
}

.email-menu-list a {
  color: #6c757d;
  padding: 12px 5px;
  display: block;
  font-size: 15px;
}
.email-menu-list a:hover {
  color: #343a40;
}
.email-menu-list a .badge {
  margin-top: 3px;
}

.labels-list a {
  padding: 7px 5px;
}

.write-mdg-box .CodeMirror {
  height: 150px;
}

@media (max-width: 648px) {
  .page-aside-left {
    width: 100%;
    float: none;
  }
  .page-aside-right {
    margin-left: 0;
    border: 0;
    padding-left: 0;
  }
  .email-list li .email-sender-info .checkbox-wrapper-mail {
    margin-left: 0;
  }
}
@media (max-width: 520px) {
  .page-aside-right > .btn-group {
    margin-bottom: 10px;
  }
  .email-list li .email-sender-info {
    width: 150px;
  }
  .email-list li .email-sender-info .email-title {
    left: 80px;
  }
  .email-list li .email-content {
    left: 160px;
  }
  .email-list li .email-content .email-date {
    padding-right: 10px;
    padding-left: 20px;
  }
}
.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
}

.daterangepicker:before, .daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }
  .daterangepicker .ranges ul {
    width: 140px;
  }
  .daterangepicker.single .ranges ul {
    width: 100%;
  }
  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }
  .daterangepicker.single .ranges, .daterangepicker.single .drp-calendar {
    float: left;
  }
  .daterangepicker {
    direction: ltr;
    text-align: left;
  }
  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }
  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }
  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }
  .daterangepicker .ranges, .daterangepicker .drp-calendar {
    float: left;
  }
}
@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }
  .daterangepicker .ranges {
    float: left;
  }
  .daterangepicker.rtl .ranges {
    float: right;
  }
  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}
/*!
 * Datepicker for Bootstrap v1.8.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */
.datepicker {
  padding: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  direction: ltr;
}

.datepicker-inline {
  width: 220px;
}

.datepicker-rtl {
  direction: rtl;
}

.datepicker-rtl.dropdown-menu {
  left: auto;
}

.datepicker-rtl table tr td span {
  float: right;
}

.datepicker-dropdown {
  top: 0;
  left: 0;
}

.datepicker-dropdown:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #999;
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}

.datepicker-dropdown:after {
  content: "";
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-top: 0;
  position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999;
}

.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}

.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datepicker td, .datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none;
}

.table-striped .datepicker table tr td, .table-striped .datepicker table tr th {
  background-color: transparent;
}

.datepicker table tr td.day.focused, .datepicker table tr td.day:hover {
  background: #eee;
  cursor: pointer;
}

.datepicker table tr td.new, .datepicker table tr td.old {
  color: #999;
}

.datepicker table tr td.disabled, .datepicker table tr td.disabled:hover {
  background: 0 0;
  color: #999;
  cursor: default;
}

.datepicker table tr td.highlighted {
  background: #d9edf7;
  border-radius: 0;
}

.datepicker table tr td.today, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today:hover {
  background-color: #fde19a;
  background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
  background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fdd49a", endColorstr="#fdf59a", GradientType=0);
  border-color: #fdf59a #fdf59a #fbed50;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #000;
}

.datepicker table tr td.today.active, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled.disabled, .datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today.disabled:hover.active, .datepicker table tr td.today.disabled:hover.disabled, .datepicker table tr td.today.disabled:hover:active, .datepicker table tr td.today.disabled:hover:hover, .datepicker table tr td.today.disabled:hover[disabled], .datepicker table tr td.today.disabled[disabled], .datepicker table tr td.today:active, .datepicker table tr td.today:hover, .datepicker table tr td.today:hover.active, .datepicker table tr td.today:hover.disabled, .datepicker table tr td.today:hover:active, .datepicker table tr td.today:hover:hover, .datepicker table tr td.today:hover[disabled], .datepicker table tr td.today[disabled] {
  background-color: #fdf59a;
}

.datepicker table tr td.today.active, .datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled:hover.active, .datepicker table tr td.today.disabled:hover:active, .datepicker table tr td.today:active, .datepicker table tr td.today:hover.active, .datepicker table tr td.today:hover:active {
  background-color: #fbf069\9 ;
}

.datepicker table tr td.today:hover:hover {
  color: #000;
}

.datepicker table tr td.today.active:hover {
  color: #fff;
}

.datepicker table tr td.range, .datepicker table tr td.range.disabled, .datepicker table tr td.range.disabled:hover, .datepicker table tr td.range:hover {
  background: #eee;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.datepicker table tr td.range.today, .datepicker table tr td.range.today.disabled, .datepicker table tr td.range.today.disabled:hover, .datepicker table tr td.range.today:hover {
  background-color: #f3d17a;
  background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
  background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f3c17a", endColorstr="#f3e97a", GradientType=0);
  border-color: #f3e97a #f3e97a #edde34;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.datepicker table tr td.range.today.active, .datepicker table tr td.range.today.disabled, .datepicker table tr td.range.today.disabled.active, .datepicker table tr td.range.today.disabled.disabled, .datepicker table tr td.range.today.disabled:active, .datepicker table tr td.range.today.disabled:hover, .datepicker table tr td.range.today.disabled:hover.active, .datepicker table tr td.range.today.disabled:hover.disabled, .datepicker table tr td.range.today.disabled:hover:active, .datepicker table tr td.range.today.disabled:hover:hover, .datepicker table tr td.range.today.disabled:hover[disabled], .datepicker table tr td.range.today.disabled[disabled], .datepicker table tr td.range.today:active, .datepicker table tr td.range.today:hover, .datepicker table tr td.range.today:hover.active, .datepicker table tr td.range.today:hover.disabled, .datepicker table tr td.range.today:hover:active, .datepicker table tr td.range.today:hover:hover, .datepicker table tr td.range.today:hover[disabled], .datepicker table tr td.range.today[disabled] {
  background-color: #f3e97a;
}

.datepicker table tr td.range.today.active, .datepicker table tr td.range.today.disabled.active, .datepicker table tr td.range.today.disabled:active, .datepicker table tr td.range.today.disabled:hover.active, .datepicker table tr td.range.today.disabled:hover:active, .datepicker table tr td.range.today:active, .datepicker table tr td.range.today:hover.active, .datepicker table tr td.range.today:hover:active {
  background-color: #efe24b\9 ;
}

.datepicker table tr td.selected, .datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled:hover, .datepicker table tr td.selected:hover {
  background-color: #9e9e9e;
  background-image: -moz-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: -ms-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(grey));
  background-image: -webkit-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: -o-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: linear-gradient(to bottom, #b3b3b3, grey);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b3b3b3", endColorstr="#808080", GradientType=0);
  border-color: grey grey #595959;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.selected.active, .datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled.active, .datepicker table tr td.selected.disabled.disabled, .datepicker table tr td.selected.disabled:active, .datepicker table tr td.selected.disabled:hover, .datepicker table tr td.selected.disabled:hover.active, .datepicker table tr td.selected.disabled:hover.disabled, .datepicker table tr td.selected.disabled:hover:active, .datepicker table tr td.selected.disabled:hover:hover, .datepicker table tr td.selected.disabled:hover[disabled], .datepicker table tr td.selected.disabled[disabled], .datepicker table tr td.selected:active, .datepicker table tr td.selected:hover, .datepicker table tr td.selected:hover.active, .datepicker table tr td.selected:hover.disabled, .datepicker table tr td.selected:hover:active, .datepicker table tr td.selected:hover:hover, .datepicker table tr td.selected:hover[disabled], .datepicker table tr td.selected[disabled] {
  background-color: grey;
}

.datepicker table tr td.selected.active, .datepicker table tr td.selected.disabled.active, .datepicker table tr td.selected.disabled:active, .datepicker table tr td.selected.disabled:hover.active, .datepicker table tr td.selected.disabled:hover:active, .datepicker table tr td.selected:active, .datepicker table tr td.selected:hover.active, .datepicker table tr td.selected:hover:active {
  background-color: #666 \9 ;
}

.datepicker table tr td.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active:hover {
  background-color: #006dcc;
  background-image: -moz-linear-gradient(to bottom, #08c, #04c);
  background-image: -ms-linear-gradient(to bottom, #08c, #04c);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
  background-image: -webkit-linear-gradient(to bottom, #08c, #04c);
  background-image: -o-linear-gradient(to bottom, #08c, #04c);
  background-image: linear-gradient(to bottom, #08c, #04c);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#08c", endColorstr="#0044cc", GradientType=0);
  border-color: #04c #04c #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.active.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled.disabled, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover.disabled, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.disabled:hover:hover, .datepicker table tr td.active.disabled:hover[disabled], .datepicker table tr td.active.disabled[disabled], .datepicker table tr td.active:active, .datepicker table tr td.active:hover, .datepicker table tr td.active:hover.active, .datepicker table tr td.active:hover.disabled, .datepicker table tr td.active:hover:active, .datepicker table tr td.active:hover:hover, .datepicker table tr td.active:hover[disabled], .datepicker table tr td.active[disabled] {
  background-color: #04c;
}

.datepicker table tr td.active.active, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active:active, .datepicker table tr td.active:hover.active, .datepicker table tr td.active:hover:active {
  background-color: #039 \9 ;
}

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.datepicker table tr td span.focused, .datepicker table tr td span:hover {
  background: #eee;
}

.datepicker table tr td span.disabled, .datepicker table tr td span.disabled:hover {
  background: 0 0;
  color: #999;
  cursor: default;
}

.datepicker table tr td span.active, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active:hover {
  background-color: #006dcc;
  background-image: -moz-linear-gradient(to bottom, #08c, #04c);
  background-image: -ms-linear-gradient(to bottom, #08c, #04c);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
  background-image: -webkit-linear-gradient(to bottom, #08c, #04c);
  background-image: -o-linear-gradient(to bottom, #08c, #04c);
  background-image: linear-gradient(to bottom, #08c, #04c);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#08c", endColorstr="#0044cc", GradientType=0);
  border-color: #04c #04c #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td span.active.active, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled.disabled, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active.disabled:hover.active, .datepicker table tr td span.active.disabled:hover.disabled, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active.disabled:hover:hover, .datepicker table tr td span.active.disabled:hover[disabled], .datepicker table tr td span.active.disabled[disabled], .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active:hover.disabled, .datepicker table tr td span.active:hover:active, .datepicker table tr td span.active:hover:hover, .datepicker table tr td span.active:hover[disabled], .datepicker table tr td span.active[disabled] {
  background-color: #04c;
}

.datepicker table tr td span.active.active, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover.active, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active:hover:active {
  background-color: #039 \9 ;
}

.datepicker table tr td span.new, .datepicker table tr td span.old {
  color: #999;
}

.datepicker .datepicker-switch {
  width: 145px;
}

.datepicker .datepicker-switch, .datepicker .next, .datepicker .prev, .datepicker tfoot tr th {
  cursor: pointer;
}

.datepicker .datepicker-switch:hover, .datepicker .next:hover, .datepicker .prev:hover, .datepicker tfoot tr th:hover {
  background: #eee;
}

.datepicker .next.disabled, .datepicker .prev.disabled {
  visibility: hidden;
}

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}

.input-append.date .add-on, .input-prepend.date .add-on {
  cursor: pointer;
}

.input-append.date .add-on i, .input-prepend.date .add-on i {
  margin-top: 3px;
}

.input-daterange input {
  text-align: center;
}

.input-daterange input:first-child {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.input-daterange input:last-child {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.input-daterange .add-on {
  display: inline-block;
  width: auto;
  min-width: 16px;
  height: 18px;
  padding: 4px 5px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  vertical-align: middle;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px;
}

.jq-toast-wrap, .jq-toast-wrap * {
  margin: 0;
  padding: 0;
}

.jq-toast-wrap {
  display: block;
  position: fixed;
  width: 250px;
  pointer-events: none !important;
  letter-spacing: normal;
  z-index: 9000 !important;
}

.jq-toast-wrap.bottom-left {
  bottom: 20px;
  left: 20px;
}

.jq-toast-wrap.bottom-right {
  bottom: 20px;
  right: 40px;
}

.jq-toast-wrap.top-left {
  top: 20px;
  left: 20px;
}

.jq-toast-wrap.top-right {
  top: 20px;
  right: 40px;
}

.jq-toast-single {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 0 0 5px;
  border-radius: 4px;
  font-size: 12px;
  font-family: arial, sans-serif;
  line-height: 17px;
  position: relative;
  pointer-events: all !important;
  background-color: #444;
  color: #fff;
}

.jq-toast-single h2 {
  font-family: arial, sans-serif;
  font-size: 14px;
  margin: 0 0 7px;
  background: 0 0;
  color: inherit;
  line-height: inherit;
  letter-spacing: normal;
}

.jq-toast-single a {
  color: #eee;
  text-decoration: none;
  font-weight: 700;
  border-bottom: 1px solid #fff;
  padding-bottom: 3px;
  font-size: 12px;
}

.jq-toast-single ul {
  margin: 0 0 0 15px;
  background: 0 0;
  padding: 0;
}

.jq-toast-single ul li {
  list-style-type: disc !important;
  line-height: 17px;
  background: 0 0;
  margin: 0;
  padding: 0;
  letter-spacing: normal;
}

.close-jq-toast-single {
  position: absolute;
  top: 3px;
  right: 7px;
  font-size: 14px;
  cursor: pointer;
}

.jq-toast-loader {
  display: block;
  position: absolute;
  top: -2px;
  height: 5px;
  width: 0;
  left: 0;
  border-radius: 5px;
  background: red;
}

.jq-toast-loaded {
  width: 100%;
}

.jq-has-icon {
  padding: 10px 10px 10px 50px;
  background-repeat: no-repeat;
  background-position: 10px;
}

.jq-icon-info {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=);
  background-color: #31708f;
  color: #d9edf7;
  border-color: #bce8f1;
}

.jq-icon-warning {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=);
  background-color: #8a6d3b;
  color: #fcf8e3;
  border-color: #faebcc;
}

.jq-icon-error {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=);
  background-color: #a94442;
  color: #f2dede;
  border-color: #ebccd1;
}

.jq-icon-success {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==);
  color: #dff0d8;
  background-color: #3c763d;
  border-color: #d6e9c6;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir=rtl] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: -o-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: -o-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: #fff;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.jq-toast-wrap, .jq-toast-wrap * {
  margin: 0;
  padding: 0;
}

.jq-toast-wrap {
  display: block;
  position: fixed;
  width: 250px;
  pointer-events: none !important;
  letter-spacing: normal;
  z-index: 9000 !important;
}

.jq-toast-wrap.bottom-left {
  bottom: 20px;
  left: 20px;
}

.jq-toast-wrap.bottom-right {
  bottom: 20px;
  right: 40px;
}

.jq-toast-wrap.top-left {
  top: 20px;
  left: 20px;
}

.jq-toast-wrap.top-right {
  top: 20px;
  right: 40px;
}

.jq-toast-single {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 0 0 5px;
  border-radius: 4px;
  font-size: 12px;
  font-family: arial, sans-serif;
  line-height: 17px;
  position: relative;
  pointer-events: all !important;
  background-color: #444;
  color: #fff;
}

.jq-toast-single h2 {
  font-family: arial, sans-serif;
  font-size: 14px;
  margin: 0 0 7px;
  background: 0 0;
  color: inherit;
  line-height: inherit;
  letter-spacing: normal;
}

.jq-toast-single a {
  color: #eee;
  text-decoration: none;
  font-weight: 700;
  border-bottom: 1px solid #fff;
  padding-bottom: 3px;
  font-size: 12px;
}

.jq-toast-single ul {
  margin: 0 0 0 15px;
  background: 0 0;
  padding: 0;
}

.jq-toast-single ul li {
  list-style-type: disc !important;
  line-height: 17px;
  background: 0 0;
  margin: 0;
  padding: 0;
  letter-spacing: normal;
}

.close-jq-toast-single {
  position: absolute;
  top: 3px;
  right: 7px;
  font-size: 14px;
  cursor: pointer;
}

.jq-toast-loader {
  display: block;
  position: absolute;
  top: -2px;
  height: 5px;
  width: 0;
  left: 0;
  border-radius: 5px;
  background: red;
}

.jq-toast-loaded {
  width: 100%;
}

.jq-has-icon {
  padding: 10px 10px 10px 50px;
  background-repeat: no-repeat;
  background-position: 10px;
}

.jq-icon-info {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=);
  background-color: #31708f;
  color: #d9edf7;
  border-color: #bce8f1;
}

.jq-icon-warning {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=);
  background-color: #8a6d3b;
  color: #fcf8e3;
  border-color: #faebcc;
}

.jq-icon-error {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=);
  background-color: #a94442;
  color: #f2dede;
  border-color: #ebccd1;
}

.jq-icon-success {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==);
  color: #dff0d8;
  background-color: #3c763d;
  border-color: #d6e9c6;
} /*!
 * Timepicker Component for Twitter Bootstrap
 *
 * Copyright 2013 Joris de Wit
 *
 * Contributors https://github.com/jdewit/bootstrap-timepicker/graphs/contributors
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
.bootstrap-timepicker {
  position: relative;
}

.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu {
  left: auto;
  right: 0;
}

.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:before {
  left: auto;
  right: 12px;
}

.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:after {
  left: auto;
  right: 13px;
}

.bootstrap-timepicker .input-group-addon {
  cursor: pointer;
}

.bootstrap-timepicker .input-group-addon i {
  display: inline-block;
  width: 16px;
  height: 16px;
}

.bootstrap-timepicker-widget.dropdown-menu {
  padding: 4px;
}

.bootstrap-timepicker-widget.dropdown-menu.open {
  display: inline-block;
}

.bootstrap-timepicker-widget.dropdown-menu:before {
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
}

.bootstrap-timepicker-widget.dropdown-menu:after {
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  display: inline-block;
  position: absolute;
}

.bootstrap-timepicker-widget.timepicker-orient-left:before {
  left: 6px;
}

.bootstrap-timepicker-widget.timepicker-orient-left:after {
  left: 7px;
}

.bootstrap-timepicker-widget.timepicker-orient-right:before {
  right: 6px;
}

.bootstrap-timepicker-widget.timepicker-orient-right:after {
  right: 7px;
}

.bootstrap-timepicker-widget.timepicker-orient-top:before {
  top: -7px;
}

.bootstrap-timepicker-widget.timepicker-orient-top:after {
  top: -6px;
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999;
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}

.bootstrap-timepicker-widget a.btn, .bootstrap-timepicker-widget input {
  border-radius: 4px;
}

.bootstrap-timepicker-widget table {
  width: 100%;
  margin: 0;
}

.bootstrap-timepicker-widget table td {
  text-align: center;
  height: 30px;
  margin: 0;
  padding: 2px;
}

.bootstrap-timepicker-widget table td:not(.separator) {
  min-width: 30px;
}

.bootstrap-timepicker-widget table td span {
  width: 100%;
}

.bootstrap-timepicker-widget table td a {
  border: 1px transparent solid;
  width: 100%;
  display: inline-block;
  margin: 0;
  padding: 8px 0;
  outline: 0;
  color: #333;
}

.bootstrap-timepicker-widget table td a:hover {
  text-decoration: none;
  background-color: #eee;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border-color: #ddd;
}

.bootstrap-timepicker-widget table td a i {
  margin-top: 2px;
  font-size: 18px;
}

.bootstrap-timepicker-widget table td input {
  width: 25px;
  margin: 0;
  text-align: center;
}

.bootstrap-timepicker-widget .modal-content {
  padding: 4px;
}

@media (min-width: 767px) {
  .bootstrap-timepicker-widget.modal {
    width: 200px;
    margin-left: -100px;
  }
}
@media (max-width: 767px) {
  .bootstrap-timepicker {
    width: 100%;
  }
  .bootstrap-timepicker .dropdown-menu {
    width: 100%;
  }
}
.bootstrap-touchspin .input-group-btn-vertical {
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 11;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  position: absolute;
  right: 0;
  height: 50%;
  padding: 0;
  width: 2em;
  text-align: center;
  line-height: 1;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0 4px 0 0;
  top: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border-radius: 0 0 4px 0;
  bottom: 0;
}

.apex-charts {
  min-height: 10px !important;
}
.apex-charts text {
  font-family: "Nunito", sans-serif !important;
  fill: #adb5bd;
}
.apex-charts .apexcharts-canvas {
  margin: 0 auto;
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
  font-family: "Nunito", sans-serif !important;
}

.apexcharts-legend-series {
  font-weight: 600;
}

.apexcharts-gridline {
  pointer-events: none;
  stroke: #f1f3fa;
}

.apexcharts-legend-text {
  color: #98a6ad !important;
  font-family: "Nunito", sans-serif !important;
}

.apexcharts-pie-label {
  fill: #fff !important;
}

.apexcharts-yaxis text,
.apexcharts-xaxis text {
  font-family: "Nunito", sans-serif !important;
  fill: #adb5bd;
}

.apexcharts-point-annotations text,
.apexcharts-xaxis-annotations text,
.apexcharts-yaxis-annotations text {
  fill: #fff;
}

.apexcharts-radar-series polygon {
  fill: transparent;
  stroke: #dee2e6;
}
.apexcharts-radar-series line {
  stroke: #dee2e6;
}

.apexcharts-pie-label,
.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value {
  fill: #fff !important;
}

.britechart, .tick text {
  font-family: "Nunito", sans-serif;
  font-size: 0.75rem;
}

.horizontal-grid-line,
.vertical-grid-line,
.extended-x-line,
.extended-y-line {
  stroke: #dee2e6;
}

.tick text,
.bar-chart .percentage-label,
.donut-text,
.legend-entry-name,
.legend-entry-value {
  fill: #98a6ad;
}

.fc-view {
  margin-top: 30px;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar {
  margin: 15px 0 5px 0;
}
.fc-toolbar h2 {
  font-size: 1.25rem;
  line-height: 1.875rem;
  text-transform: uppercase;
}

.fc-day-grid-event .fc-time {
  font-weight: 700;
}

.fc-day {
  background: transparent;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc th.fc-widget-header {
  background: #edf1f5;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #f1f3fa;
}

.fc-button {
  background: #edf1f5;
  border: none;
  color: #adb5bd;
  text-shadow: none;
  text-transform: capitalize;
  box-shadow: none;
  border-radius: 3px;
  margin: 0 3px;
  padding: 6px 12px;
  height: auto;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem;
}

.fc-state-hover {
  background: #e3eaef;
}

.fc-state-highlight {
  background: #dee2e6;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #727cf5;
  color: #fff;
  text-shadow: none;
}

.fc-cell-overlay {
  background: #dee2e6;
}

.fc-unthemed td.fc-today {
  background: #f3f6f8;
}
.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-popover .fc-header {
  background: #f1f3fa;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
}

.external-event {
  cursor: move;
  margin: 10px 0;
  padding: 8px 10px;
  color: #fff;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
}
.fc-basic-view td.fc-day-number {
  padding-right: 8px;
}
.fc-basic-view .fc-content {
  color: #fff;
}

.fc-time-grid-event .fc-content {
  color: #fff;
}

.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  float: right;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
  background-color: #f1f3fa;
  border-radius: 50%;
  margin: 5px;
  font-size: 11px;
}

@media (max-width: 767.98px) {
  .fc-toolbar .fc-left, .fc-toolbar .fc-right, .fc-toolbar .fc-center {
    float: none;
    display: block;
    clear: both;
    margin: 10px 0;
  }
  .fc .fc-toolbar > * > * {
    float: none;
  }
  .fc-today-button {
    display: none;
  }
}
.chartjs-chart {
  margin: auto;
  position: relative;
  width: 100%;
}

.dataTables_wrapper.container-fluid {
  padding: 0;
}

table.dataTable {
  border-collapse: collapse !important;
  margin-bottom: 15px !important;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 0.5rem;
  content: "\f35d";
  font-family: "Material Design Icons";
  font-size: 1rem;
  top: 18px;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\f360";
  font-family: "Material Design Icons";
  top: 12px;
  font-size: 1rem;
}
table.dataTable tbody > tr.selected, table.dataTable tbody > tr > .selected {
  background-color: #727cf5;
}
table.dataTable tbody > tr.selected td, table.dataTable tbody > tr > .selected td {
  border-color: #727cf5;
}
table.dataTable tbody td:focus {
  outline: none !important;
}
table.dataTable tbody th.focus, table.dataTable tbody td.focus {
  outline: 2px solid #727cf5 !important;
  outline-offset: -1px;
  background-color: rgba(114, 124, 245, 0.15);
}

.dataTables_info {
  font-weight: 600;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > th:first-child:before {
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  background-color: #0acf97;
  top: auto;
  bottom: auto;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  background-color: #fa5c7c;
}

div.dt-button-info {
  background-color: #727cf5;
  border: none;
  color: #fff;
  box-shadow: none;
  border-radius: 3px;
  text-align: center;
  z-index: 21;
}
div.dt-button-info h2 {
  border-bottom: none;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

@media (max-width: 767.98px) {
  li.paginate_button.previous, li.paginate_button.next {
    display: inline-block;
    font-size: 1.5rem;
  }
  li.paginate_button {
    display: none;
  }
  .dataTables_paginate ul {
    text-align: center;
    display: block;
    margin: 1.5rem 0 0 !important;
  }
  div.dt-buttons {
    display: inline-table;
    margin-bottom: 1.5rem;
  }
}
.activate-select .sorting_1 {
  background-color: #f1f3fa;
}

.daterangepicker {
  font-family: "Nunito", sans-serif;
  border: 1px solid #d9e3e9;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  background-color: #fff;
}
.daterangepicker .calendar-table {
  border: 1px solid #fff;
  background-color: #fff;
}
.daterangepicker .calendar-table td, .daterangepicker .calendar-table th {
  color: #98a6ad;
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  border-color: #adb5bd;
}
.daterangepicker td.in-range {
  background-color: #edeff1;
  color: #6c757d;
}
.daterangepicker td.off, .daterangepicker td.off.end-date, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date {
  background-color: #fff;
  color: #6c757d;
  opacity: 0.5;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #727cf5;
  color: #fff;
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #727cf5;
  color: #fff;
}
.daterangepicker:after {
  border-bottom: 6px solid #fff;
}
.daterangepicker:before {
  border-bottom: 7px solid #d9e3e9;
}
.daterangepicker .ranges li.active {
  background-color: #727cf5;
}
.daterangepicker .ranges li:hover {
  background-color: #f8f9fa;
}
.daterangepicker .drp-buttons {
  border-top: 1px solid #d9e3e9;
}
.daterangepicker select.ampmselect, .daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect {
  background: white;
  border: 1px solid white;
  color: #fff;
}
.daterangepicker.show-ranges .drp-calendar.left {
  border-left: 1px solid #d9e3e9;
}

.datepicker {
  padding: 10px !important;
}
.datepicker td,
.datepicker th {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.datepicker table tr td.active.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled.disabled, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover.disabled, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td .active.disabled:hover[disabled],
.datepicker table tr td .active.disabled[disabled],
.datepicker table tr td .active:active,
.datepicker table tr td .active:hover,
.datepicker table tr td .active:hover.active,
.datepicker table tr td .active:hover.disabled,
.datepicker table tr td .active:hover:active,
.datepicker table tr td .active:hover:hover,
.datepicker table tr td .active:hover[disabled],
.datepicker table tr td .active[disabled],
.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active.disabled:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active[disabled], .datepicker table tr td.today, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today:hover {
  background-color: #727cf5 !important;
  background-image: none !important;
  color: #fff;
}
.datepicker table tr td.day.focused, .datepicker table tr td.day:hover,
.datepicker table tr td span.focused,
.datepicker table tr td span:hover {
  background: #e3eaef;
}
.datepicker table tr td.new, .datepicker table tr td.old,
.datepicker table tr td span.new,
.datepicker table tr td span.old {
  color: #6c757d;
  opacity: 0.4;
}
.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover {
  background: #e3eaef;
}
.datepicker .datepicker-switch:hover {
  background: none;
}

.datepicker-dropdown:after {
  border-bottom: 6px solid #fff;
}
.datepicker-dropdown:before {
  border-bottom-color: #d9e3e9;
}
.datepicker-dropdown.datepicker-orient-top:before {
  border-top: 7px solid #d9e3e9;
}
.datepicker-dropdown.datepicker-orient-top:after {
  border-top: 6px solid #fff;
}

.bg-dragula {
  background-color: #e3eaef;
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.dragula-handle {
  position: relative;
  width: 36px;
  height: 36px;
  font-size: 24px;
  text-align: center;
  cursor: move;
}
.dragula-handle:before {
  content: "\f1db";
  font-family: "Material Design Icons";
  position: absolute;
}

.dropzone {
  border: 2px dashed #dee2e6;
  background: #fff;
  border-radius: 6px;
  cursor: pointer;
  min-height: 150px;
  padding: 20px;
}
.dropzone .dz-message {
  text-align: center;
  margin: 2rem 0;
}
.dropzone.dz-started .dz-message {
  display: none;
}

.form-wizard-header {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  background-color: #e3eaef;
}

.gmaps,
.gmaps-panaroma {
  height: 300px;
  background: #f1f3fa;
  border-radius: 3px;
}

.jvectormap-label {
  border: none;
  background: #343a40;
  color: #f1f3fa;
  font-family: "Nunito", sans-serif;
  font-size: 0.875rem;
  padding: 5px 8px;
}

.metismenu {
  padding: 0;
}
.metismenu li {
  list-style: none;
}
.metismenu ul {
  padding: 0;
}
.metismenu .mm-collapse:not(.mm-show) {
  display: none;
}
.metismenu .mm-collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease;
  transition-duration: 0.35s;
  transition-property: height, visibility;
}

.side-nav-second-level li a,
.side-nav-third-level li a,
.side-nav-forth-level li a {
  padding: 8px 20px 8px 68px;
  color: #cedce4;
  display: block;
  position: relative;
  transition: all 0.4s;
}
.side-nav-second-level li a:focus, .side-nav-second-level li a:hover,
.side-nav-third-level li a:focus,
.side-nav-third-level li a:hover,
.side-nav-forth-level li a:focus,
.side-nav-forth-level li a:hover {
  color: #ffffff;
}
.side-nav-second-level li a .menu-arrow,
.side-nav-third-level li a .menu-arrow,
.side-nav-forth-level li a .menu-arrow {
  line-height: 1.3rem;
}
.side-nav-second-level li.active > a,
.side-nav-third-level li.active > a,
.side-nav-forth-level li.active > a {
  color: #ffffff;
}

body[data-leftbar-theme=light] .side-nav .side-nav-second-level li a,
body[data-leftbar-theme=light] .side-nav .side-nav-third-level li a,
body[data-leftbar-theme=light] .side-nav .side-nav-forth-level li a {
  color: #6c757d;
}
body[data-leftbar-theme=light] .side-nav .side-nav-second-level li a:focus, body[data-leftbar-theme=light] .side-nav .side-nav-second-level li a:hover,
body[data-leftbar-theme=light] .side-nav .side-nav-third-level li a:focus,
body[data-leftbar-theme=light] .side-nav .side-nav-third-level li a:hover,
body[data-leftbar-theme=light] .side-nav .side-nav-forth-level li a:focus,
body[data-leftbar-theme=light] .side-nav .side-nav-forth-level li a:hover {
  color: #727cf5;
}
body[data-leftbar-theme=light] .side-nav .side-nav-second-level li.active > a,
body[data-leftbar-theme=light] .side-nav .side-nav-third-level li.active > a,
body[data-leftbar-theme=light] .side-nav .side-nav-forth-level li.active > a {
  color: #727cf5;
}

body[data-layout=detached] .side-nav .side-nav-second-level li a,
body[data-layout=detached] .side-nav .side-nav-third-level li a,
body[data-layout=detached] .side-nav .side-nav-forth-level li a {
  color: #6c757d;
}
body[data-layout=detached] .side-nav .side-nav-second-level li a:focus, body[data-layout=detached] .side-nav .side-nav-second-level li a:hover,
body[data-layout=detached] .side-nav .side-nav-third-level li a:focus,
body[data-layout=detached] .side-nav .side-nav-third-level li a:hover,
body[data-layout=detached] .side-nav .side-nav-forth-level li a:focus,
body[data-layout=detached] .side-nav .side-nav-forth-level li a:hover {
  color: #727cf5;
}
body[data-layout=detached] .side-nav .side-nav-second-level li.mm-active > a,
body[data-layout=detached] .side-nav .side-nav-third-level li.mm-active > a,
body[data-layout=detached] .side-nav .side-nav-forth-level li.mm-active > a {
  color: #727cf5 !important;
}

body[data-leftbar-theme=dark] .side-nav .side-nav-second-level li a,
body[data-leftbar-theme=dark] .side-nav .side-nav-third-level li a,
body[data-leftbar-theme=dark] .side-nav .side-nav-forth-level li a {
  color: #8391a2;
}
body[data-leftbar-theme=dark] .side-nav .side-nav-second-level li a:focus, body[data-leftbar-theme=dark] .side-nav .side-nav-second-level li a:hover,
body[data-leftbar-theme=dark] .side-nav .side-nav-third-level li a:focus,
body[data-leftbar-theme=dark] .side-nav .side-nav-third-level li a:hover,
body[data-leftbar-theme=dark] .side-nav .side-nav-forth-level li a:focus,
body[data-leftbar-theme=dark] .side-nav .side-nav-forth-level li a:hover {
  color: #bccee4;
}
body[data-leftbar-theme=dark] .side-nav .side-nav-second-level li.active > a,
body[data-leftbar-theme=dark] .side-nav .side-nav-third-level li.active > a,
body[data-leftbar-theme=dark] .side-nav .side-nav-forth-level li.active > a {
  color: #ffffff;
}

.side-nav-third-level li a {
  padding: 8px 20px 8px 84px;
}

.side-nav-forth-level li a {
  padding: 8px 20px 8px 100px;
}

.select2-container {
  width: 100% !important;
}
.select2-container .select2-selection--single {
  border: 1px solid #dee2e6;
  height: calc(2.2125rem + 2px);
  background-color: #fff;
  outline: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  line-height: 36px;
  padding-left: 12px;
  color: #6c757d;
}
.select2-container .select2-selection--single .select2-selection__arrow {
  height: 34px;
  width: 34px;
  right: 3px;
}
.select2-container .select2-selection--single .select2-selection__arrow b {
  border-color: #98a6ad transparent transparent transparent;
  border-width: 6px 6px 0 6px;
}

.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #98a6ad transparent !important;
  border-width: 0 6px 6px 6px !important;
}

.select2-results__option {
  padding: 6px 12px;
}

.select2-dropdown {
  border: 1px solid #d9e3e9;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  background-color: #fff;
}

.select2-container--default .select2-search--dropdown {
  padding: 10px;
  background-color: white;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  outline: none;
  border: 1px solid #dee2e6;
  background-color: #fff;
  color: #6c757d;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #727cf5;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: white;
  color: #313a46;
}
.select2-container--default .select2-results__option[aria-selected=true]:hover {
  background-color: #727cf5;
  color: #fff;
}

.select2-container .select2-selection--multiple {
  min-height: calc(2.2125rem + 2px);
  border: 1px solid #dee2e6 !important;
  background-color: #fff;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 1px 10px;
}
.select2-container .select2-selection--multiple .select2-search__field {
  border: 0;
  color: #6c757d;
}
.select2-container .select2-selection--multiple .select2-selection__choice {
  background-color: #727cf5;
  border: none;
  color: #fff;
  border-radius: 3px;
  padding: 0 7px;
  margin-top: 6px;
}
.select2-container .select2-selection--multiple .select2-selection__choice__remove {
  color: #fff;
  margin-right: 5px;
}
.select2-container .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #fff;
}

.slimScrollDiv {
  height: auto !important;
}

.jq-toast-single {
  padding: 15px;
  font-family: "Nunito", sans-serif;
  background-color: #727cf5;
  font-size: 13px;
  line-height: 22px;
}
.jq-toast-single h2 {
  font-family: "Nunito", sans-serif;
}
.jq-toast-single a {
  font-size: 0.875rem;
}
.jq-toast-single a:hover {
  color: #fff;
}

.jq-has-icon {
  padding: 10px 10px 10px 50px;
}

.close-jq-toast-single {
  position: absolute;
  top: -12px;
  right: -12px;
  font-size: 20px;
  cursor: pointer;
  height: 32px;
  width: 32px;
  background: #343a40;
  color: #f1f3fa;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
}

.jq-toast-loader {
  height: 3px;
  top: 0;
  border-radius: 0;
}

.jq-icon-primary {
  background-color: #727cf5;
  color: #fff;
  border-color: #727cf5;
}

.jq-icon-secondary {
  background-color: #6c757d;
  color: #fff;
  border-color: #6c757d;
}

.jq-icon-success {
  background-color: #0acf97;
  color: #fff;
  border-color: #0acf97;
}

.jq-icon-info {
  background-color: #39afd1;
  color: #fff;
  border-color: #39afd1;
}

.jq-icon-warning {
  background-color: #ffbc00;
  color: #fff;
  border-color: #ffbc00;
}

.jq-icon-danger {
  background-color: #fa5c7c;
  color: #fff;
  border-color: #fa5c7c;
}

.jq-icon-light {
  background-color: #e3eaef;
  color: #fff;
  border-color: #e3eaef;
}

.jq-icon-dark {
  background-color: #313a46;
  color: #fff;
  border-color: #313a46;
}

.jq-icon-error {
  background-color: #fa5c7c;
  color: #fff;
  border-color: #fa5c7c;
}

.bootstrap-touchspin .btn .input-group-text {
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
}

.bootstrap-timepicker-widget table td input {
  height: 32px;
  width: 32px;
  color: #fff;
  background-color: #727cf5;
  border-radius: 50%;
  border: 0;
  outline: none !important;
}
.bootstrap-timepicker-widget table td a {
  color: #6c757d;
}
.bootstrap-timepicker-widget table td a:hover {
  background-color: transparent;
  border: 1px solid transparent;
  color: #727cf5;
}
.bootstrap-timepicker-widget.dropdown-menu:before {
  border-bottom: 7px solid #d9e3e9;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
.bootstrap-timepicker-widget.dropdown-menu:after {
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #d9e3e9;
}

@font-face {
  font-family: "summernote";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/summernote.eot");
  src: url("../assets/fonts/summernote.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/summernote.woff?") format("woff"), url("../assets/fonts/summernote.ttf?") format("truetype");
}
.note-editor.note-frame {
  border: 1px solid #dee2e6;
  box-shadow: none;
  margin: 0;
}
.note-editor.note-frame .note-statusbar {
  background-color: #fff;
  border-top: 1px solid #dee2e6;
}
.note-editor.note-frame .note-editable {
  border: none;
  background-color: #fff !important;
  color: #6c757d !important;
}

.note-status-output {
  display: none;
}

.note-placeholder {
  color: #adb5bd;
}

.note-editable {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  padding: 0.45rem 0.9rem;
}
.note-editable p:last-of-type {
  margin-bottom: 0;
}

.note-btn-group .btn-light {
  background-color: #edeff1;
  box-shadow: none;
  border-color: #edeff1;
  color: #343a40;
}

.note-popover .popover-content, .card-header.note-toolbar {
  background: #fff;
}

.editor-toolbar.fullscreen, .CodeMirror-fullscreen {
  z-index: 1000;
}

.editor-preview, .editor-preview-side {
  background: #dee2e6;
}

.editor-preview-active {
  background: #e5e8eb;
}

.editor-toolbar {
  border: 1px solid #dee2e6;
  border-bottom: 0;
}
.editor-toolbar a {
  color: #6c757d !important;
}
.editor-toolbar a:hover, .editor-toolbar a.active {
  background-color: transparent;
  color: #727cf5 !important;
  border-color: transparent;
}
.editor-toolbar i.separator {
  display: none;
}
.editor-toolbar.disabled-for-preview a:not(.no-disable) {
  background: transparent;
}

.CodeMirror {
  border: 1px solid #dee2e6;
  background: #fff;
  color: #6c757d;
  min-height: 100px;
}

.CodeMirror-cursor {
  border-left: 1px solid #343a40;
}

.editor-statusbar {
  color: #343a40;
}

.twitter-typeahead {
  display: inherit !important;
}

.tt-query,
.tt-hint {
  line-height: 30px;
  outline: none;
}

.tt-query {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #98a6ad;
}

.tt-menu {
  width: 100%;
  padding: 8px 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
  border: 1px solid #d9e3e9;
}

.tt-suggestion {
  padding: 3px 20px;
  line-height: 24px;
}
.tt-suggestion:hover, .tt-suggestion.tt-cursor {
  cursor: pointer;
  color: #fff;
  background-color: #727cf5;
}
.tt-suggestion p {
  margin: 0;
}

.tt-highlight {
  font-family: "Nunito", sans-serif;
}

.typeahead-empty-message {
  padding: 5px 10px;
  color: #fa5c7c;
}

.league-name {
  padding: 3px 20px;
}

.jqstooltip {
  box-sizing: content-box;
  width: auto !important;
  height: auto !important;
  background-color: #fff !important;
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  padding: 5px 10px !important;
  border-radius: 3px;
  border-color: #fff !important;
}

.jqsfield {
  color: #000 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-family: "Nunito", sans-serif !important;
  font-weight: 700 !important;
}

@font-face {
  font-family: "dripicons-v2";
  src: url("../assets/fonts/dripicons-v2.eot");
  src: url("../assets/fonts/dripicons-v2.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/dripicons-v2.woff") format("woff"), url("../assets/fonts/dripicons-v2.ttf") format("truetype"), url("../assets/fonts/dripicons-v2.svg#dripicons-v2") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: "dripicons-v2" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^=dripicons-]:before,
[class*=" dripicons-"]:before {
  font-family: "dripicons-v2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;
}

.dripicons-alarm:before {
  content: "a";
}

.dripicons-align-center:before {
  content: "b";
}

.dripicons-align-justify:before {
  content: "c";
}

.dripicons-align-left:before {
  content: "d";
}

.dripicons-align-right:before {
  content: "e";
}

.dripicons-anchor:before {
  content: "f";
}

.dripicons-archive:before {
  content: "g";
}

.dripicons-arrow-down:before {
  content: "h";
}

.dripicons-arrow-left:before {
  content: "i";
}

.dripicons-arrow-right:before {
  content: "j";
}

.dripicons-arrow-thin-down:before {
  content: "k";
}

.dripicons-arrow-thin-left:before {
  content: "l";
}

.dripicons-arrow-thin-right:before {
  content: "m";
}

.dripicons-arrow-thin-up:before {
  content: "n";
}

.dripicons-arrow-up:before {
  content: "o";
}

.dripicons-article:before {
  content: "p";
}

.dripicons-backspace:before {
  content: "q";
}

.dripicons-basket:before {
  content: "r";
}

.dripicons-basketball:before {
  content: "s";
}

.dripicons-battery-empty:before {
  content: "t";
}

.dripicons-battery-full:before {
  content: "u";
}

.dripicons-battery-low:before {
  content: "v";
}

.dripicons-battery-medium:before {
  content: "w";
}

.dripicons-bell:before {
  content: "x";
}

.dripicons-blog:before {
  content: "y";
}

.dripicons-bluetooth:before {
  content: "z";
}

.dripicons-bold:before {
  content: "A";
}

.dripicons-bookmark:before {
  content: "B";
}

.dripicons-bookmarks:before {
  content: "C";
}

.dripicons-box:before {
  content: "D";
}

.dripicons-briefcase:before {
  content: "E";
}

.dripicons-brightness-low:before {
  content: "F";
}

.dripicons-brightness-max:before {
  content: "G";
}

.dripicons-brightness-medium:before {
  content: "H";
}

.dripicons-broadcast:before {
  content: "I";
}

.dripicons-browser:before {
  content: "J";
}

.dripicons-browser-upload:before {
  content: "K";
}

.dripicons-brush:before {
  content: "L";
}

.dripicons-calendar:before {
  content: "M";
}

.dripicons-camcorder:before {
  content: "N";
}

.dripicons-camera:before {
  content: "O";
}

.dripicons-card:before {
  content: "P";
}

.dripicons-cart:before {
  content: "Q";
}

.dripicons-checklist:before {
  content: "R";
}

.dripicons-checkmark:before {
  content: "S";
}

.dripicons-chevron-down:before {
  content: "T";
}

.dripicons-chevron-left:before {
  content: "U";
}

.dripicons-chevron-right:before {
  content: "V";
}

.dripicons-chevron-up:before {
  content: "W";
}

.dripicons-clipboard:before {
  content: "X";
}

.dripicons-clock:before {
  content: "Y";
}

.dripicons-clockwise:before {
  content: "Z";
}

.dripicons-cloud:before {
  content: "0";
}

.dripicons-cloud-download:before {
  content: "1";
}

.dripicons-cloud-upload:before {
  content: "2";
}

.dripicons-code:before {
  content: "3";
}

.dripicons-contract:before {
  content: "4";
}

.dripicons-contract-2:before {
  content: "5";
}

.dripicons-conversation:before {
  content: "6";
}

.dripicons-copy:before {
  content: "7";
}

.dripicons-crop:before {
  content: "8";
}

.dripicons-cross:before {
  content: "9";
}

.dripicons-crosshair:before {
  content: "!";
}

.dripicons-cutlery:before {
  content: '"';
}

.dripicons-device-desktop:before {
  content: "#";
}

.dripicons-device-mobile:before {
  content: "$";
}

.dripicons-device-tablet:before {
  content: "%";
}

.dripicons-direction:before {
  content: "&";
}

.dripicons-disc:before {
  content: "'";
}

.dripicons-document:before {
  content: "(";
}

.dripicons-document-delete:before {
  content: ")";
}

.dripicons-document-edit:before {
  content: "*";
}

.dripicons-document-new:before {
  content: "+";
}

.dripicons-document-remove:before {
  content: ",";
}

.dripicons-dot:before {
  content: "-";
}

.dripicons-dots-2:before {
  content: ".";
}

.dripicons-dots-3:before {
  content: "/";
}

.dripicons-download:before {
  content: ":";
}

.dripicons-duplicate:before {
  content: ";";
}

.dripicons-enter:before {
  content: "<";
}

.dripicons-exit:before {
  content: "=";
}

.dripicons-expand:before {
  content: ">";
}

.dripicons-expand-2:before {
  content: "?";
}

.dripicons-experiment:before {
  content: "@";
}

.dripicons-export:before {
  content: "[";
}

.dripicons-feed:before {
  content: "]";
}

.dripicons-flag:before {
  content: "^";
}

.dripicons-flashlight:before {
  content: "_";
}

.dripicons-folder:before {
  content: "`";
}

.dripicons-folder-open:before {
  content: "{";
}

.dripicons-forward:before {
  content: "|";
}

.dripicons-gaming:before {
  content: "}";
}

.dripicons-gear:before {
  content: "~";
}

.dripicons-graduation:before {
  content: "\\";
}

.dripicons-graph-bar:before {
  content: "\e000";
}

.dripicons-graph-line:before {
  content: "\e001";
}

.dripicons-graph-pie:before {
  content: "\e002";
}

.dripicons-headset:before {
  content: "\e003";
}

.dripicons-heart:before {
  content: "\e004";
}

.dripicons-help:before {
  content: "\e005";
}

.dripicons-home:before {
  content: "\e006";
}

.dripicons-hourglass:before {
  content: "\e007";
}

.dripicons-inbox:before {
  content: "\e008";
}

.dripicons-information:before {
  content: "\e009";
}

.dripicons-italic:before {
  content: "\e00a";
}

.dripicons-jewel:before {
  content: "\e00b";
}

.dripicons-lifting:before {
  content: "\e00c";
}

.dripicons-lightbulb:before {
  content: "\e00d";
}

.dripicons-link:before {
  content: "\e00e";
}

.dripicons-link-broken:before {
  content: "\e00f";
}

.dripicons-list:before {
  content: "\e010";
}

.dripicons-loading:before {
  content: "\e011";
}

.dripicons-location:before {
  content: "\e012";
}

.dripicons-lock:before {
  content: "\e013";
}

.dripicons-lock-open:before {
  content: "\e014";
}

.dripicons-mail:before {
  content: "\e015";
}

.dripicons-map:before {
  content: "\e016";
}

.dripicons-media-loop:before {
  content: "\e017";
}

.dripicons-media-next:before {
  content: "\e018";
}

.dripicons-media-pause:before {
  content: "\e019";
}

.dripicons-media-play:before {
  content: "\e01a";
}

.dripicons-media-previous:before {
  content: "\e01b";
}

.dripicons-media-record:before {
  content: "\e01c";
}

.dripicons-media-shuffle:before {
  content: "\e01d";
}

.dripicons-media-stop:before {
  content: "\e01e";
}

.dripicons-medical:before {
  content: "\e01f";
}

.dripicons-menu:before {
  content: "\e020";
}

.dripicons-message:before {
  content: "\e021";
}

.dripicons-meter:before {
  content: "\e022";
}

.dripicons-microphone:before {
  content: "\e023";
}

.dripicons-minus:before {
  content: "\e024";
}

.dripicons-monitor:before {
  content: "\e025";
}

.dripicons-move:before {
  content: "\e026";
}

.dripicons-music:before {
  content: "\e027";
}

.dripicons-network-1:before {
  content: "\e028";
}

.dripicons-network-2:before {
  content: "\e029";
}

.dripicons-network-3:before {
  content: "\e02a";
}

.dripicons-network-4:before {
  content: "\e02b";
}

.dripicons-network-5:before {
  content: "\e02c";
}

.dripicons-pamphlet:before {
  content: "\e02d";
}

.dripicons-paperclip:before {
  content: "\e02e";
}

.dripicons-pencil:before {
  content: "\e02f";
}

.dripicons-phone:before {
  content: "\e030";
}

.dripicons-photo:before {
  content: "\e031";
}

.dripicons-photo-group:before {
  content: "\e032";
}

.dripicons-pill:before {
  content: "\e033";
}

.dripicons-pin:before {
  content: "\e034";
}

.dripicons-plus:before {
  content: "\e035";
}

.dripicons-power:before {
  content: "\e036";
}

.dripicons-preview:before {
  content: "\e037";
}

.dripicons-print:before {
  content: "\e038";
}

.dripicons-pulse:before {
  content: "\e039";
}

.dripicons-question:before {
  content: "\e03a";
}

.dripicons-reply:before {
  content: "\e03b";
}

.dripicons-reply-all:before {
  content: "\e03c";
}

.dripicons-return:before {
  content: "\e03d";
}

.dripicons-retweet:before {
  content: "\e03e";
}

.dripicons-rocket:before {
  content: "\e03f";
}

.dripicons-scale:before {
  content: "\e040";
}

.dripicons-search:before {
  content: "\e041";
}

.dripicons-shopping-bag:before {
  content: "\e042";
}

.dripicons-skip:before {
  content: "\e043";
}

.dripicons-stack:before {
  content: "\e044";
}

.dripicons-star:before {
  content: "\e045";
}

.dripicons-stopwatch:before {
  content: "\e046";
}

.dripicons-store:before {
  content: "\e047";
}

.dripicons-suitcase:before {
  content: "\e048";
}

.dripicons-swap:before {
  content: "\e049";
}

.dripicons-tag:before {
  content: "\e04a";
}

.dripicons-tag-delete:before {
  content: "\e04b";
}

.dripicons-tags:before {
  content: "\e04c";
}

.dripicons-thumbs-down:before {
  content: "\e04d";
}

.dripicons-thumbs-up:before {
  content: "\e04e";
}

.dripicons-ticket:before {
  content: "\e04f";
}

.dripicons-time-reverse:before {
  content: "\e050";
}

.dripicons-to-do:before {
  content: "\e051";
}

.dripicons-toggles:before {
  content: "\e052";
}

.dripicons-trash:before {
  content: "\e053";
}

.dripicons-trophy:before {
  content: "\e054";
}

.dripicons-upload:before {
  content: "\e055";
}

.dripicons-user:before {
  content: "\e056";
}

.dripicons-user-group:before {
  content: "\e057";
}

.dripicons-user-id:before {
  content: "\e058";
}

.dripicons-vibrate:before {
  content: "\e059";
}

.dripicons-view-apps:before {
  content: "\e05a";
}

.dripicons-view-list:before {
  content: "\e05b";
}

.dripicons-view-list-large:before {
  content: "\e05c";
}

.dripicons-view-thumb:before {
  content: "\e05d";
}

.dripicons-volume-full:before {
  content: "\e05e";
}

.dripicons-volume-low:before {
  content: "\e05f";
}

.dripicons-volume-medium:before {
  content: "\e060";
}

.dripicons-volume-off:before {
  content: "\e061";
}

.dripicons-wallet:before {
  content: "\e062";
}

.dripicons-warning:before {
  content: "\e063";
}

.dripicons-web:before {
  content: "\e064";
}

.dripicons-weight:before {
  content: "\e065";
}

.dripicons-wifi:before {
  content: "\e066";
}

.dripicons-wrong:before {
  content: "\e067";
}

.dripicons-zoom-in:before {
  content: "\e068";
}

.dripicons-zoom-out:before {
  content: "\e069";
}

/* MaterialDesignIcons.com */
@font-face {
  font-family: "Material Design Icons";
  src: url("../assets/fonts/materialdesignicons-webfont.eot?v=3.5.95");
  src: url("../assets/fonts/materialdesignicons-webfont.eot?#iefix&v=3.5.95") format("embedded-opentype"), url("../assets/fonts/materialdesignicons-webfont.woff2?v=3.5.95") format("woff2"), url("../assets/fonts/materialdesignicons-webfont.woff?v=3.5.95") format("woff"), url("../assets/fonts/materialdesignicons-webfont.ttf?v=3.5.95") format("truetype"), url("../assets/fonts/materialdesignicons-webfont.svg?v=3.5.95#materialdesigniconsregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mdi-access-point:before {
  content: "\f002";
}

.mdi-access-point-network:before {
  content: "\f003";
}

.mdi-access-point-network-off:before {
  content: "﮽";
}

.mdi-account:before {
  content: "\f004";
}

.mdi-account-alert:before {
  content: "\f005";
}

.mdi-account-alert-outline:before {
  content: "שּׁ";
}

.mdi-account-arrow-left:before {
  content: "שּׂ";
}

.mdi-account-arrow-left-outline:before {
  content: "אַ";
}

.mdi-account-arrow-right:before {
  content: "אָ";
}

.mdi-account-arrow-right-outline:before {
  content: "אּ";
}

.mdi-account-badge:before {
  content: "ﶃ";
}

.mdi-account-badge-alert:before {
  content: "ﶄ";
}

.mdi-account-badge-alert-outline:before {
  content: "ﶅ";
}

.mdi-account-badge-outline:before {
  content: "ﶆ";
}

.mdi-account-box:before {
  content: "\f006";
}

.mdi-account-box-multiple:before {
  content: "盧";
}

.mdi-account-box-outline:before {
  content: "\f007";
}

.mdi-account-card-details:before {
  content: "\f5d2";
}

.mdi-account-card-details-outline:before {
  content: "ﶇ";
}

.mdi-account-check:before {
  content: "\f008";
}

.mdi-account-check-outline:before {
  content: "﮾";
}

.mdi-account-child:before {
  content: "愈";
}

.mdi-account-child-circle:before {
  content: "憎";
}

.mdi-account-circle:before {
  content: "\f009";
}

.mdi-account-circle-outline:before {
  content: "בּ";
}

.mdi-account-clock:before {
  content: "גּ";
}

.mdi-account-clock-outline:before {
  content: "דּ";
}

.mdi-account-convert:before {
  content: "\f00a";
}

.mdi-account-details:before {
  content: "\f631";
}

.mdi-account-edit:before {
  content: "\f6bb";
}

.mdi-account-group:before {
  content: "\f848";
}

.mdi-account-group-outline:before {
  content: "הּ";
}

.mdi-account-heart:before {
  content: "\f898";
}

.mdi-account-heart-outline:before {
  content: "﮿";
}

.mdi-account-key:before {
  content: "\f00b";
}

.mdi-account-key-outline:before {
  content: "﯀";
}

.mdi-account-minus:before {
  content: "\f00d";
}

.mdi-account-minus-outline:before {
  content: "﫫";
}

.mdi-account-multiple:before {
  content: "\f00e";
}

.mdi-account-multiple-check:before {
  content: "\f8c4";
}

.mdi-account-multiple-minus:before {
  content: "\f5d3";
}

.mdi-account-multiple-minus-outline:before {
  content: "﯁";
}

.mdi-account-multiple-outline:before {
  content: "\f00f";
}

.mdi-account-multiple-plus:before {
  content: "\f010";
}

.mdi-account-multiple-plus-outline:before {
  content: "\f7ff";
}

.mdi-account-network:before {
  content: "\f011";
}

.mdi-account-network-outline:before {
  content: "﯂";
}

.mdi-account-off:before {
  content: "\f012";
}

.mdi-account-off-outline:before {
  content: "﯃";
}

.mdi-account-outline:before {
  content: "\f013";
}

.mdi-account-plus:before {
  content: "\f014";
}

.mdi-account-plus-outline:before {
  content: "\f800";
}

.mdi-account-question:before {
  content: "וּ";
}

.mdi-account-question-outline:before {
  content: "זּ";
}

.mdi-account-remove:before {
  content: "\f015";
}

.mdi-account-remove-outline:before {
  content: "﫬";
}

.mdi-account-search:before {
  content: "\f016";
}

.mdi-account-search-outline:before {
  content: "老";
}

.mdi-account-settings:before {
  content: "\f630";
}

.mdi-account-star:before {
  content: "\f017";
}

.mdi-account-star-outline:before {
  content: "﯄";
}

.mdi-account-supervisor:before {
  content: "慠";
}

.mdi-account-supervisor-circle:before {
  content: "懲";
}

.mdi-account-switch:before {
  content: "\f019";
}

.mdi-account-tie:before {
  content: "ﲿ";
}

.mdi-accusoft:before {
  content: "\f849";
}

.mdi-adchoices:before {
  content: "ﴞ";
}

.mdi-adjust:before {
  content: "\f01a";
}

.mdi-adobe:before {
  content: "蘆";
}

.mdi-air-conditioner:before {
  content: "\f01b";
}

.mdi-air-filter:before {
  content: "ﴟ";
}

.mdi-air-horn:before {
  content: "ﶈ";
}

.mdi-air-purifier:before {
  content: "ﴠ";
}

.mdi-airbag:before {
  content: "﯅";
}

.mdi-airballoon:before {
  content: "\f01c";
}

.mdi-airplane:before {
  content: "\f01d";
}

.mdi-airplane-landing:before {
  content: "\f5d4";
}

.mdi-airplane-off:before {
  content: "\f01e";
}

.mdi-airplane-takeoff:before {
  content: "\f5d5";
}

.mdi-airplay:before {
  content: "\f01f";
}

.mdi-airport:before {
  content: "\f84a";
}

.mdi-alarm:before {
  content: "\f020";
}

.mdi-alarm-bell:before {
  content: "\f78d";
}

.mdi-alarm-check:before {
  content: "\f021";
}

.mdi-alarm-light:before {
  content: "\f78e";
}

.mdi-alarm-light-outline:before {
  content: "﯆";
}

.mdi-alarm-multiple:before {
  content: "\f022";
}

.mdi-alarm-off:before {
  content: "\f023";
}

.mdi-alarm-plus:before {
  content: "\f024";
}

.mdi-alarm-snooze:before {
  content: "\f68d";
}

.mdi-album:before {
  content: "\f025";
}

.mdi-alert:before {
  content: "\f026";
}

.mdi-alert-box:before {
  content: "\f027";
}

.mdi-alert-box-outline:before {
  content: "ﳀ";
}

.mdi-alert-circle:before {
  content: "\f028";
}

.mdi-alert-circle-outline:before {
  content: "\f5d6";
}

.mdi-alert-decagram:before {
  content: "\f6bc";
}

.mdi-alert-decagram-outline:before {
  content: "ﳁ";
}

.mdi-alert-octagon:before {
  content: "\f029";
}

.mdi-alert-octagon-outline:before {
  content: "ﳂ";
}

.mdi-alert-octagram:before {
  content: "\f766";
}

.mdi-alert-octagram-outline:before {
  content: "ﳃ";
}

.mdi-alert-outline:before {
  content: "\f02a";
}

.mdi-alien:before {
  content: "\f899";
}

.mdi-all-inclusive:before {
  content: "\f6bd";
}

.mdi-alpha:before {
  content: "\f02b";
}

.mdi-alpha-a:before {
  content: "A";
}

.mdi-alpha-a-box:before {
  content: "﫭";
}

.mdi-alpha-a-box-outline:before {
  content: "﯇";
}

.mdi-alpha-a-circle:before {
  content: "﯈";
}

.mdi-alpha-a-circle-outline:before {
  content: "﯉";
}

.mdi-alpha-b:before {
  content: "B";
}

.mdi-alpha-b-box:before {
  content: "﫮";
}

.mdi-alpha-b-box-outline:before {
  content: "﯊";
}

.mdi-alpha-b-circle:before {
  content: "﯋";
}

.mdi-alpha-b-circle-outline:before {
  content: "﯌";
}

.mdi-alpha-c:before {
  content: "C";
}

.mdi-alpha-c-box:before {
  content: "﫯";
}

.mdi-alpha-c-box-outline:before {
  content: "﯍";
}

.mdi-alpha-c-circle:before {
  content: "﯎";
}

.mdi-alpha-c-circle-outline:before {
  content: "﯏";
}

.mdi-alpha-d:before {
  content: "D";
}

.mdi-alpha-d-box:before {
  content: "﫰";
}

.mdi-alpha-d-box-outline:before {
  content: "﯐";
}

.mdi-alpha-d-circle:before {
  content: "﯑";
}

.mdi-alpha-d-circle-outline:before {
  content: "﯒";
}

.mdi-alpha-e:before {
  content: "E";
}

.mdi-alpha-e-box:before {
  content: "﫱";
}

.mdi-alpha-e-box-outline:before {
  content: "ﯓ";
}

.mdi-alpha-e-circle:before {
  content: "ﯔ";
}

.mdi-alpha-e-circle-outline:before {
  content: "ﯕ";
}

.mdi-alpha-f:before {
  content: "F";
}

.mdi-alpha-f-box:before {
  content: "﫲";
}

.mdi-alpha-f-box-outline:before {
  content: "ﯖ";
}

.mdi-alpha-f-circle:before {
  content: "ﯗ";
}

.mdi-alpha-f-circle-outline:before {
  content: "ﯘ";
}

.mdi-alpha-g:before {
  content: "G";
}

.mdi-alpha-g-box:before {
  content: "﫳";
}

.mdi-alpha-g-box-outline:before {
  content: "ﯙ";
}

.mdi-alpha-g-circle:before {
  content: "ﯚ";
}

.mdi-alpha-g-circle-outline:before {
  content: "ﯛ";
}

.mdi-alpha-h:before {
  content: "H";
}

.mdi-alpha-h-box:before {
  content: "﫴";
}

.mdi-alpha-h-box-outline:before {
  content: "ﯜ";
}

.mdi-alpha-h-circle:before {
  content: "ﯝ";
}

.mdi-alpha-h-circle-outline:before {
  content: "ﯞ";
}

.mdi-alpha-i:before {
  content: "I";
}

.mdi-alpha-i-box:before {
  content: "﫵";
}

.mdi-alpha-i-box-outline:before {
  content: "ﯟ";
}

.mdi-alpha-i-circle:before {
  content: "ﯠ";
}

.mdi-alpha-i-circle-outline:before {
  content: "ﯡ";
}

.mdi-alpha-j:before {
  content: "J";
}

.mdi-alpha-j-box:before {
  content: "﫶";
}

.mdi-alpha-j-box-outline:before {
  content: "ﯢ";
}

.mdi-alpha-j-circle:before {
  content: "ﯣ";
}

.mdi-alpha-j-circle-outline:before {
  content: "ﯤ";
}

.mdi-alpha-k:before {
  content: "K";
}

.mdi-alpha-k-box:before {
  content: "﫷";
}

.mdi-alpha-k-box-outline:before {
  content: "ﯥ";
}

.mdi-alpha-k-circle:before {
  content: "ﯦ";
}

.mdi-alpha-k-circle-outline:before {
  content: "ﯧ";
}

.mdi-alpha-l:before {
  content: "L";
}

.mdi-alpha-l-box:before {
  content: "﫸";
}

.mdi-alpha-l-box-outline:before {
  content: "ﯨ";
}

.mdi-alpha-l-circle:before {
  content: "ﯩ";
}

.mdi-alpha-l-circle-outline:before {
  content: "ﯪ";
}

.mdi-alpha-m:before {
  content: "M";
}

.mdi-alpha-m-box:before {
  content: "﫹";
}

.mdi-alpha-m-box-outline:before {
  content: "ﯫ";
}

.mdi-alpha-m-circle:before {
  content: "ﯬ";
}

.mdi-alpha-m-circle-outline:before {
  content: "ﯭ";
}

.mdi-alpha-n:before {
  content: "N";
}

.mdi-alpha-n-box:before {
  content: "﫺";
}

.mdi-alpha-n-box-outline:before {
  content: "ﯮ";
}

.mdi-alpha-n-circle:before {
  content: "ﯯ";
}

.mdi-alpha-n-circle-outline:before {
  content: "ﯰ";
}

.mdi-alpha-o:before {
  content: "O";
}

.mdi-alpha-o-box:before {
  content: "﫻";
}

.mdi-alpha-o-box-outline:before {
  content: "ﯱ";
}

.mdi-alpha-o-circle:before {
  content: "ﯲ";
}

.mdi-alpha-o-circle-outline:before {
  content: "ﯳ";
}

.mdi-alpha-p:before {
  content: "P";
}

.mdi-alpha-p-box:before {
  content: "﫼";
}

.mdi-alpha-p-box-outline:before {
  content: "ﯴ";
}

.mdi-alpha-p-circle:before {
  content: "ﯵ";
}

.mdi-alpha-p-circle-outline:before {
  content: "ﯶ";
}

.mdi-alpha-q:before {
  content: "Q";
}

.mdi-alpha-q-box:before {
  content: "﫽";
}

.mdi-alpha-q-box-outline:before {
  content: "ﯷ";
}

.mdi-alpha-q-circle:before {
  content: "ﯸ";
}

.mdi-alpha-q-circle-outline:before {
  content: "ﯹ";
}

.mdi-alpha-r:before {
  content: "R";
}

.mdi-alpha-r-box:before {
  content: "﫾";
}

.mdi-alpha-r-box-outline:before {
  content: "ﯺ";
}

.mdi-alpha-r-circle:before {
  content: "ﯻ";
}

.mdi-alpha-r-circle-outline:before {
  content: "ﯼ";
}

.mdi-alpha-s:before {
  content: "S";
}

.mdi-alpha-s-box:before {
  content: "﫿";
}

.mdi-alpha-s-box-outline:before {
  content: "ﯽ";
}

.mdi-alpha-s-circle:before {
  content: "ﯾ";
}

.mdi-alpha-s-circle-outline:before {
  content: "ﯿ";
}

.mdi-alpha-t:before {
  content: "T";
}

.mdi-alpha-t-box:before {
  content: "ﬀ";
}

.mdi-alpha-t-box-outline:before {
  content: "ﰀ";
}

.mdi-alpha-t-circle:before {
  content: "ﰁ";
}

.mdi-alpha-t-circle-outline:before {
  content: "ﰂ";
}

.mdi-alpha-u:before {
  content: "U";
}

.mdi-alpha-u-box:before {
  content: "ﬁ";
}

.mdi-alpha-u-box-outline:before {
  content: "ﰃ";
}

.mdi-alpha-u-circle:before {
  content: "ﰄ";
}

.mdi-alpha-u-circle-outline:before {
  content: "ﰅ";
}

.mdi-alpha-v:before {
  content: "V";
}

.mdi-alpha-v-box:before {
  content: "ﬂ";
}

.mdi-alpha-v-box-outline:before {
  content: "ﰆ";
}

.mdi-alpha-v-circle:before {
  content: "ﰇ";
}

.mdi-alpha-v-circle-outline:before {
  content: "ﰈ";
}

.mdi-alpha-w:before {
  content: "W";
}

.mdi-alpha-w-box:before {
  content: "ﬃ";
}

.mdi-alpha-w-box-outline:before {
  content: "ﰉ";
}

.mdi-alpha-w-circle:before {
  content: "ﰊ";
}

.mdi-alpha-w-circle-outline:before {
  content: "ﰋ";
}

.mdi-alpha-x:before {
  content: "X";
}

.mdi-alpha-x-box:before {
  content: "ﬄ";
}

.mdi-alpha-x-box-outline:before {
  content: "ﰌ";
}

.mdi-alpha-x-circle:before {
  content: "ﰍ";
}

.mdi-alpha-x-circle-outline:before {
  content: "ﰎ";
}

.mdi-alpha-y:before {
  content: "Y";
}

.mdi-alpha-y-box:before {
  content: "ﬅ";
}

.mdi-alpha-y-box-outline:before {
  content: "ﰏ";
}

.mdi-alpha-y-circle:before {
  content: "ﰐ";
}

.mdi-alpha-y-circle-outline:before {
  content: "ﰑ";
}

.mdi-alpha-z:before {
  content: "Z";
}

.mdi-alpha-z-box:before {
  content: "ﬆ";
}

.mdi-alpha-z-box-outline:before {
  content: "ﰒ";
}

.mdi-alpha-z-circle:before {
  content: "ﰓ";
}

.mdi-alpha-z-circle-outline:before {
  content: "ﰔ";
}

.mdi-alphabetical:before {
  content: "\f02c";
}

.mdi-altimeter:before {
  content: "\f5d7";
}

.mdi-amazon:before {
  content: "\f02d";
}

.mdi-amazon-alexa:before {
  content: "\f8c5";
}

.mdi-amazon-drive:before {
  content: "\f02e";
}

.mdi-ambulance:before {
  content: "\f02f";
}

.mdi-ammunition:before {
  content: "ﳄ";
}

.mdi-ampersand:before {
  content: "戴";
}

.mdi-amplifier:before {
  content: "\f030";
}

.mdi-anchor:before {
  content: "\f031";
}

.mdi-android:before {
  content: "\f032";
}

.mdi-android-auto:before {
  content: "揄";
}

.mdi-android-debug-bridge:before {
  content: "\f033";
}

.mdi-android-head:before {
  content: "\f78f";
}

.mdi-android-messages:before {
  content: "ﴡ";
}

.mdi-android-studio:before {
  content: "\f034";
}

.mdi-angle-acute:before {
  content: "虜";
}

.mdi-angle-obtuse:before {
  content: "路";
}

.mdi-angle-right:before {
  content: "露";
}

.mdi-angular:before {
  content: "\f6b1";
}

.mdi-angularjs:before {
  content: "\f6be";
}

.mdi-animation:before {
  content: "\f5d8";
}

.mdi-animation-outline:before {
  content: "搜";
}

.mdi-animation-play:before {
  content: "魯";
}

.mdi-animation-play-outline:before {
  content: "摒";
}

.mdi-anvil:before {
  content: "\f89a";
}

.mdi-apple:before {
  content: "\f035";
}

.mdi-apple-finder:before {
  content: "\f036";
}

.mdi-apple-icloud:before {
  content: "\f038";
}

.mdi-apple-ios:before {
  content: "\f037";
}

.mdi-apple-keyboard-caps:before {
  content: "\f632";
}

.mdi-apple-keyboard-command:before {
  content: "\f633";
}

.mdi-apple-keyboard-control:before {
  content: "\f634";
}

.mdi-apple-keyboard-option:before {
  content: "\f635";
}

.mdi-apple-keyboard-shift:before {
  content: "\f636";
}

.mdi-apple-safari:before {
  content: "\f039";
}

.mdi-application:before {
  content: "\f614";
}

.mdi-application-export:before {
  content: "ﶉ";
}

.mdi-application-import:before {
  content: "ﶊ";
}

.mdi-apps:before {
  content: "\f03b";
}

.mdi-apps-box:before {
  content: "ﴢ";
}

.mdi-arch:before {
  content: "\f8c6";
}

.mdi-archive:before {
  content: "\f03c";
}

.mdi-arrange-bring-forward:before {
  content: "\f03d";
}

.mdi-arrange-bring-to-front:before {
  content: "\f03e";
}

.mdi-arrange-send-backward:before {
  content: "\f03f";
}

.mdi-arrange-send-to-back:before {
  content: "\f040";
}

.mdi-arrow-all:before {
  content: "\f041";
}

.mdi-arrow-bottom-left:before {
  content: "\f042";
}

.mdi-arrow-bottom-left-bold-outline:before {
  content: "禮";
}

.mdi-arrow-bottom-left-thick:before {
  content: "醴";
}

.mdi-arrow-bottom-right:before {
  content: "\f043";
}

.mdi-arrow-bottom-right-bold-outline:before {
  content: "隸";
}

.mdi-arrow-bottom-right-thick:before {
  content: "惡";
}

.mdi-arrow-collapse:before {
  content: "\f615";
}

.mdi-arrow-collapse-all:before {
  content: "\f044";
}

.mdi-arrow-collapse-down:before {
  content: "\f791";
}

.mdi-arrow-collapse-horizontal:before {
  content: "\f84b";
}

.mdi-arrow-collapse-left:before {
  content: "\f792";
}

.mdi-arrow-collapse-right:before {
  content: "\f793";
}

.mdi-arrow-collapse-up:before {
  content: "\f794";
}

.mdi-arrow-collapse-vertical:before {
  content: "\f84c";
}

.mdi-arrow-decision:before {
  content: "了";
}

.mdi-arrow-decision-auto:before {
  content: "僚";
}

.mdi-arrow-decision-auto-outline:before {
  content: "寮";
}

.mdi-arrow-decision-outline:before {
  content: "尿";
}

.mdi-arrow-down:before {
  content: "\f045";
}

.mdi-arrow-down-bold:before {
  content: "\f72d";
}

.mdi-arrow-down-bold-box:before {
  content: "\f72e";
}

.mdi-arrow-down-bold-box-outline:before {
  content: "\f72f";
}

.mdi-arrow-down-bold-circle:before {
  content: "\f047";
}

.mdi-arrow-down-bold-circle-outline:before {
  content: "\f048";
}

.mdi-arrow-down-bold-hexagon-outline:before {
  content: "\f049";
}

.mdi-arrow-down-bold-outline:before {
  content: "料";
}

.mdi-arrow-down-box:before {
  content: "\f6bf";
}

.mdi-arrow-down-circle:before {
  content: "ﲷ";
}

.mdi-arrow-down-circle-outline:before {
  content: "ﲸ";
}

.mdi-arrow-down-drop-circle:before {
  content: "\f04a";
}

.mdi-arrow-down-drop-circle-outline:before {
  content: "\f04b";
}

.mdi-arrow-down-thick:before {
  content: "\f046";
}

.mdi-arrow-expand:before {
  content: "\f616";
}

.mdi-arrow-expand-all:before {
  content: "\f04c";
}

.mdi-arrow-expand-down:before {
  content: "\f795";
}

.mdi-arrow-expand-horizontal:before {
  content: "\f84d";
}

.mdi-arrow-expand-left:before {
  content: "\f796";
}

.mdi-arrow-expand-right:before {
  content: "\f797";
}

.mdi-arrow-expand-up:before {
  content: "\f798";
}

.mdi-arrow-expand-vertical:before {
  content: "\f84e";
}

.mdi-arrow-left:before {
  content: "\f04d";
}

.mdi-arrow-left-bold:before {
  content: "\f730";
}

.mdi-arrow-left-bold-box:before {
  content: "\f731";
}

.mdi-arrow-left-bold-box-outline:before {
  content: "\f732";
}

.mdi-arrow-left-bold-circle:before {
  content: "\f04f";
}

.mdi-arrow-left-bold-circle-outline:before {
  content: "\f050";
}

.mdi-arrow-left-bold-hexagon-outline:before {
  content: "\f051";
}

.mdi-arrow-left-bold-outline:before {
  content: "樂";
}

.mdi-arrow-left-box:before {
  content: "\f6c0";
}

.mdi-arrow-left-circle:before {
  content: "ﲹ";
}

.mdi-arrow-left-circle-outline:before {
  content: "ﲺ";
}

.mdi-arrow-left-drop-circle:before {
  content: "\f052";
}

.mdi-arrow-left-drop-circle-outline:before {
  content: "\f053";
}

.mdi-arrow-left-right-bold-outline:before {
  content: "燎";
}

.mdi-arrow-left-thick:before {
  content: "\f04e";
}

.mdi-arrow-right:before {
  content: "\f054";
}

.mdi-arrow-right-bold:before {
  content: "\f733";
}

.mdi-arrow-right-bold-box:before {
  content: "\f734";
}

.mdi-arrow-right-bold-box-outline:before {
  content: "\f735";
}

.mdi-arrow-right-bold-circle:before {
  content: "\f056";
}

.mdi-arrow-right-bold-circle-outline:before {
  content: "\f057";
}

.mdi-arrow-right-bold-hexagon-outline:before {
  content: "\f058";
}

.mdi-arrow-right-bold-outline:before {
  content: "療";
}

.mdi-arrow-right-box:before {
  content: "\f6c1";
}

.mdi-arrow-right-circle:before {
  content: "ﲻ";
}

.mdi-arrow-right-circle-outline:before {
  content: "ﲼ";
}

.mdi-arrow-right-drop-circle:before {
  content: "\f059";
}

.mdi-arrow-right-drop-circle-outline:before {
  content: "\f05a";
}

.mdi-arrow-right-thick:before {
  content: "\f055";
}

.mdi-arrow-split-horizontal:before {
  content: "鷺";
}

.mdi-arrow-split-vertical:before {
  content: "碌";
}

.mdi-arrow-top-left:before {
  content: "\f05b";
}

.mdi-arrow-top-left-bold-outline:before {
  content: "蓼";
}

.mdi-arrow-top-left-thick:before {
  content: "遼";
}

.mdi-arrow-top-right:before {
  content: "\f05c";
}

.mdi-arrow-top-right-bold-outline:before {
  content: "龍";
}

.mdi-arrow-top-right-thick:before {
  content: "暈";
}

.mdi-arrow-up:before {
  content: "\f05d";
}

.mdi-arrow-up-bold:before {
  content: "\f736";
}

.mdi-arrow-up-bold-box:before {
  content: "\f737";
}

.mdi-arrow-up-bold-box-outline:before {
  content: "\f738";
}

.mdi-arrow-up-bold-circle:before {
  content: "\f05f";
}

.mdi-arrow-up-bold-circle-outline:before {
  content: "\f060";
}

.mdi-arrow-up-bold-hexagon-outline:before {
  content: "\f061";
}

.mdi-arrow-up-bold-outline:before {
  content: "阮";
}

.mdi-arrow-up-box:before {
  content: "\f6c2";
}

.mdi-arrow-up-circle:before {
  content: "ﲽ";
}

.mdi-arrow-up-circle-outline:before {
  content: "ﲾ";
}

.mdi-arrow-up-down-bold-outline:before {
  content: "劉";
}

.mdi-arrow-up-drop-circle:before {
  content: "\f062";
}

.mdi-arrow-up-drop-circle-outline:before {
  content: "\f063";
}

.mdi-arrow-up-thick:before {
  content: "\f05e";
}

.mdi-artist:before {
  content: "\f802";
}

.mdi-artist-outline:before {
  content: "ﳅ";
}

.mdi-artstation:before {
  content: "﬷";
}

.mdi-aspect-ratio:before {
  content: "﨣";
}

.mdi-assistant:before {
  content: "\f064";
}

.mdi-asterisk:before {
  content: "\f6c3";
}

.mdi-at:before {
  content: "\f065";
}

.mdi-atlassian:before {
  content: "\f803";
}

.mdi-atm:before {
  content: "ﴣ";
}

.mdi-atom:before {
  content: "\f767";
}

.mdi-attachment:before {
  content: "\f066";
}

.mdi-audio-video:before {
  content: "祿";
}

.mdi-audiobook:before {
  content: "\f067";
}

.mdi-augmented-reality:before {
  content: "\f84f";
}

.mdi-auto-fix:before {
  content: "\f068";
}

.mdi-auto-upload:before {
  content: "\f069";
}

.mdi-autorenew:before {
  content: "\f06a";
}

.mdi-av-timer:before {
  content: "\f06b";
}

.mdi-axe:before {
  content: "\f8c7";
}

.mdi-axis:before {
  content: "ﴤ";
}

.mdi-axis-arrow:before {
  content: "ﴥ";
}

.mdi-axis-arrow-lock:before {
  content: "ﴦ";
}

.mdi-axis-lock:before {
  content: "ﴧ";
}

.mdi-axis-x-arrow:before {
  content: "ﴨ";
}

.mdi-axis-x-arrow-lock:before {
  content: "ﴩ";
}

.mdi-axis-x-rotate-clockwise:before {
  content: "ﴪ";
}

.mdi-axis-x-rotate-counterclockwise:before {
  content: "ﴫ";
}

.mdi-axis-x-y-arrow-lock:before {
  content: "ﴬ";
}

.mdi-axis-y-arrow:before {
  content: "ﴭ";
}

.mdi-axis-y-arrow-lock:before {
  content: "ﴮ";
}

.mdi-axis-y-rotate-clockwise:before {
  content: "ﴯ";
}

.mdi-axis-y-rotate-counterclockwise:before {
  content: "ﴰ";
}

.mdi-axis-z-arrow:before {
  content: "ﴱ";
}

.mdi-axis-z-arrow-lock:before {
  content: "ﴲ";
}

.mdi-axis-z-rotate-clockwise:before {
  content: "ﴳ";
}

.mdi-axis-z-rotate-counterclockwise:before {
  content: "ﴴ";
}

.mdi-azure:before {
  content: "\f804";
}

.mdi-babel:before {
  content: "﨤";
}

.mdi-baby:before {
  content: "\f06c";
}

.mdi-baby-buggy:before {
  content: "\f68e";
}

.mdi-backburger:before {
  content: "\f06d";
}

.mdi-backspace:before {
  content: "\f06e";
}

.mdi-backspace-outline:before {
  content: "טּ";
}

.mdi-backup-restore:before {
  content: "\f06f";
}

.mdi-badminton:before {
  content: "\f850";
}

.mdi-balloon:before {
  content: "逸";
}

.mdi-ballot:before {
  content: "杻";
}

.mdi-ballot-outline:before {
  content: "柳";
}

.mdi-ballot-recount:before {
  content: "ﰕ";
}

.mdi-ballot-recount-outline:before {
  content: "ﰖ";
}

.mdi-bandage:before {
  content: "ﶋ";
}

.mdi-bandcamp:before {
  content: "\f674";
}

.mdi-bank:before {
  content: "\f070";
}

.mdi-bank-minus:before {
  content: "ﶌ";
}

.mdi-bank-plus:before {
  content: "ﶍ";
}

.mdi-bank-remove:before {
  content: "ﶎ";
}

.mdi-bank-transfer:before {
  content: "都";
}

.mdi-bank-transfer-in:before {
  content: "﨧";
}

.mdi-bank-transfer-out:before {
  content: "﨨";
}

.mdi-barcode:before {
  content: "\f071";
}

.mdi-barcode-scan:before {
  content: "\f072";
}

.mdi-barley:before {
  content: "\f073";
}

.mdi-barley-off:before {
  content: "יּ";
}

.mdi-barn:before {
  content: "ךּ";
}

.mdi-barrel:before {
  content: "\f074";
}

.mdi-baseball:before {
  content: "\f851";
}

.mdi-baseball-bat:before {
  content: "\f852";
}

.mdi-basecamp:before {
  content: "\f075";
}

.mdi-basket:before {
  content: "\f076";
}

.mdi-basket-fill:before {
  content: "\f077";
}

.mdi-basket-unfill:before {
  content: "\f078";
}

.mdi-basketball:before {
  content: "\f805";
}

.mdi-basketball-hoop:before {
  content: "ﰗ";
}

.mdi-basketball-hoop-outline:before {
  content: "ﰘ";
}

.mdi-bat:before {
  content: "כּ";
}

.mdi-battery:before {
  content: "\f079";
}

.mdi-battery-10:before {
  content: "\f07a";
}

.mdi-battery-10-bluetooth:before {
  content: "綠";
}

.mdi-battery-20:before {
  content: "\f07b";
}

.mdi-battery-20-bluetooth:before {
  content: "菉";
}

.mdi-battery-30:before {
  content: "\f07c";
}

.mdi-battery-30-bluetooth:before {
  content: "錄";
}

.mdi-battery-40:before {
  content: "\f07d";
}

.mdi-battery-40-bluetooth:before {
  content: "鹿";
}

.mdi-battery-50:before {
  content: "\f07e";
}

.mdi-battery-50-bluetooth:before {
  content: "論";
}

.mdi-battery-60:before {
  content: "\f07f";
}

.mdi-battery-60-bluetooth:before {
  content: "壟";
}

.mdi-battery-70:before {
  content: "\f080";
}

.mdi-battery-70-bluetooth:before {
  content: "弄";
}

.mdi-battery-80:before {
  content: "\f081";
}

.mdi-battery-80-bluetooth:before {
  content: "籠";
}

.mdi-battery-90:before {
  content: "\f082";
}

.mdi-battery-90-bluetooth:before {
  content: "聾";
}

.mdi-battery-alert:before {
  content: "\f083";
}

.mdi-battery-alert-bluetooth:before {
  content: "牢";
}

.mdi-battery-bluetooth:before {
  content: "磊";
}

.mdi-battery-bluetooth-variant:before {
  content: "賂";
}

.mdi-battery-charging:before {
  content: "\f084";
}

.mdi-battery-charging-10:before {
  content: "\f89b";
}

.mdi-battery-charging-100:before {
  content: "\f085";
}

.mdi-battery-charging-20:before {
  content: "\f086";
}

.mdi-battery-charging-30:before {
  content: "\f087";
}

.mdi-battery-charging-40:before {
  content: "\f088";
}

.mdi-battery-charging-50:before {
  content: "\f89c";
}

.mdi-battery-charging-60:before {
  content: "\f089";
}

.mdi-battery-charging-70:before {
  content: "\f89d";
}

.mdi-battery-charging-80:before {
  content: "\f08a";
}

.mdi-battery-charging-90:before {
  content: "\f08b";
}

.mdi-battery-charging-outline:before {
  content: "\f89e";
}

.mdi-battery-charging-wireless:before {
  content: "\f806";
}

.mdi-battery-charging-wireless-10:before {
  content: "\f807";
}

.mdi-battery-charging-wireless-20:before {
  content: "\f808";
}

.mdi-battery-charging-wireless-30:before {
  content: "\f809";
}

.mdi-battery-charging-wireless-40:before {
  content: "\f80a";
}

.mdi-battery-charging-wireless-50:before {
  content: "\f80b";
}

.mdi-battery-charging-wireless-60:before {
  content: "\f80c";
}

.mdi-battery-charging-wireless-70:before {
  content: "\f80d";
}

.mdi-battery-charging-wireless-80:before {
  content: "\f80e";
}

.mdi-battery-charging-wireless-90:before {
  content: "\f80f";
}

.mdi-battery-charging-wireless-alert:before {
  content: "\f810";
}

.mdi-battery-charging-wireless-outline:before {
  content: "\f811";
}

.mdi-battery-minus:before {
  content: "\f08c";
}

.mdi-battery-negative:before {
  content: "\f08d";
}

.mdi-battery-outline:before {
  content: "\f08e";
}

.mdi-battery-plus:before {
  content: "\f08f";
}

.mdi-battery-positive:before {
  content: "\f090";
}

.mdi-battery-unknown:before {
  content: "\f091";
}

.mdi-battery-unknown-bluetooth:before {
  content: "雷";
}

.mdi-battlenet:before {
  content: "לּ";
}

.mdi-beach:before {
  content: "\f092";
}

.mdi-beaker:before {
  content: "ﳆ";
}

.mdi-beaker-outline:before {
  content: "\f68f";
}

.mdi-beats:before {
  content: "\f097";
}

.mdi-bed-empty:before {
  content: "\f89f";
}

.mdi-beer:before {
  content: "\f098";
}

.mdi-behance:before {
  content: "\f099";
}

.mdi-bell:before {
  content: "\f09a";
}

.mdi-bell-alert:before {
  content: "ﴵ";
}

.mdi-bell-circle:before {
  content: "ﴶ";
}

.mdi-bell-circle-outline:before {
  content: "ﴷ";
}

.mdi-bell-off:before {
  content: "\f09b";
}

.mdi-bell-off-outline:before {
  content: "敖";
}

.mdi-bell-outline:before {
  content: "\f09c";
}

.mdi-bell-plus:before {
  content: "\f09d";
}

.mdi-bell-plus-outline:before {
  content: "晴";
}

.mdi-bell-ring:before {
  content: "\f09e";
}

.mdi-bell-ring-outline:before {
  content: "\f09f";
}

.mdi-bell-sleep:before {
  content: "\f0a0";
}

.mdi-bell-sleep-outline:before {
  content: "朗";
}

.mdi-beta:before {
  content: "\f0a1";
}

.mdi-betamax:before {
  content: "流";
}

.mdi-bible:before {
  content: "\f0a2";
}

.mdi-bike:before {
  content: "\f0a3";
}

.mdi-billiards:before {
  content: "﬽";
}

.mdi-billiards-rack:before {
  content: "מּ";
}

.mdi-bing:before {
  content: "\f0a4";
}

.mdi-binoculars:before {
  content: "\f0a5";
}

.mdi-bio:before {
  content: "\f0a6";
}

.mdi-biohazard:before {
  content: "\f0a7";
}

.mdi-bitbucket:before {
  content: "\f0a8";
}

.mdi-bitcoin:before {
  content: "\f812";
}

.mdi-black-mesa:before {
  content: "\f0a9";
}

.mdi-blackberry:before {
  content: "\f0aa";
}

.mdi-blender:before {
  content: "ﳇ";
}

.mdi-blender-software:before {
  content: "\f0ab";
}

.mdi-blinds:before {
  content: "\f0ac";
}

.mdi-block-helper:before {
  content: "\f0ad";
}

.mdi-blogger:before {
  content: "\f0ae";
}

.mdi-blood-bag:before {
  content: "ﳈ";
}

.mdi-bluetooth:before {
  content: "\f0af";
}

.mdi-bluetooth-audio:before {
  content: "\f0b0";
}

.mdi-bluetooth-connect:before {
  content: "\f0b1";
}

.mdi-bluetooth-off:before {
  content: "\f0b2";
}

.mdi-bluetooth-settings:before {
  content: "\f0b3";
}

.mdi-bluetooth-transfer:before {
  content: "\f0b4";
}

.mdi-blur:before {
  content: "\f0b5";
}

.mdi-blur-linear:before {
  content: "\f0b6";
}

.mdi-blur-off:before {
  content: "\f0b7";
}

.mdi-blur-radial:before {
  content: "\f0b8";
}

.mdi-bolnisi-cross:before {
  content: "ﳉ";
}

.mdi-bolt:before {
  content: "ﶏ";
}

.mdi-bomb:before {
  content: "\f690";
}

.mdi-bomb-off:before {
  content: "\f6c4";
}

.mdi-bone:before {
  content: "\f0b9";
}

.mdi-book:before {
  content: "\f0ba";
}

.mdi-book-lock:before {
  content: "\f799";
}

.mdi-book-lock-open:before {
  content: "\f79a";
}

.mdi-book-minus:before {
  content: "\f5d9";
}

.mdi-book-multiple:before {
  content: "\f0bb";
}

.mdi-book-multiple-minus:before {
  content: "望";
}

.mdi-book-multiple-plus:before {
  content: "杖";
}

.mdi-book-multiple-remove:before {
  content: "歹";
}

.mdi-book-multiple-variant:before {
  content: "\f0bc";
}

.mdi-book-open:before {
  content: "\f0bd";
}

.mdi-book-open-outline:before {
  content: "﬿";
}

.mdi-book-open-page-variant:before {
  content: "\f5da";
}

.mdi-book-open-variant:before {
  content: "\f0be";
}

.mdi-book-outline:before {
  content: "נּ";
}

.mdi-book-plus:before {
  content: "\f5db";
}

.mdi-book-remove:before {
  content: "殺";
}

.mdi-book-variant:before {
  content: "\f0bf";
}

.mdi-bookmark:before {
  content: "\f0c0";
}

.mdi-bookmark-check:before {
  content: "\f0c1";
}

.mdi-bookmark-minus:before {
  content: "溜";
}

.mdi-bookmark-minus-outline:before {
  content: "琉";
}

.mdi-bookmark-music:before {
  content: "\f0c2";
}

.mdi-bookmark-off:before {
  content: "留";
}

.mdi-bookmark-off-outline:before {
  content: "硫";
}

.mdi-bookmark-outline:before {
  content: "\f0c3";
}

.mdi-bookmark-plus:before {
  content: "\f0c5";
}

.mdi-bookmark-plus-outline:before {
  content: "\f0c4";
}

.mdi-bookmark-remove:before {
  content: "\f0c6";
}

.mdi-boombox:before {
  content: "\f5dc";
}

.mdi-bootstrap:before {
  content: "\f6c5";
}

.mdi-border-all:before {
  content: "\f0c7";
}

.mdi-border-all-variant:before {
  content: "\f8a0";
}

.mdi-border-bottom:before {
  content: "\f0c8";
}

.mdi-border-bottom-variant:before {
  content: "\f8a1";
}

.mdi-border-color:before {
  content: "\f0c9";
}

.mdi-border-horizontal:before {
  content: "\f0ca";
}

.mdi-border-inside:before {
  content: "\f0cb";
}

.mdi-border-left:before {
  content: "\f0cc";
}

.mdi-border-left-variant:before {
  content: "\f8a2";
}

.mdi-border-none:before {
  content: "\f0cd";
}

.mdi-border-none-variant:before {
  content: "\f8a3";
}

.mdi-border-outside:before {
  content: "\f0ce";
}

.mdi-border-right:before {
  content: "\f0cf";
}

.mdi-border-right-variant:before {
  content: "\f8a4";
}

.mdi-border-style:before {
  content: "\f0d0";
}

.mdi-border-top:before {
  content: "\f0d1";
}

.mdi-border-top-variant:before {
  content: "\f8a5";
}

.mdi-border-vertical:before {
  content: "\f0d2";
}

.mdi-bottle-wine:before {
  content: "\f853";
}

.mdi-bow-tie:before {
  content: "\f677";
}

.mdi-bowl:before {
  content: "\f617";
}

.mdi-bowling:before {
  content: "\f0d3";
}

.mdi-box:before {
  content: "\f0d4";
}

.mdi-box-cutter:before {
  content: "\f0d5";
}

.mdi-box-shadow:before {
  content: "\f637";
}

.mdi-boxing-glove:before {
  content: "סּ";
}

.mdi-braille:before {
  content: "紐";
}

.mdi-brain:before {
  content: "類";
}

.mdi-bread-slice:before {
  content: "ﳊ";
}

.mdi-bread-slice-outline:before {
  content: "ﳋ";
}

.mdi-bridge:before {
  content: "\f618";
}

.mdi-briefcase:before {
  content: "\f0d6";
}

.mdi-briefcase-account:before {
  content: "ﳌ";
}

.mdi-briefcase-account-outline:before {
  content: "ﳍ";
}

.mdi-briefcase-check:before {
  content: "\f0d7";
}

.mdi-briefcase-download:before {
  content: "\f0d8";
}

.mdi-briefcase-download-outline:before {
  content: "ﰙ";
}

.mdi-briefcase-edit:before {
  content: "流";
}

.mdi-briefcase-edit-outline:before {
  content: "ﰚ";
}

.mdi-briefcase-minus:before {
  content: "﨩";
}

.mdi-briefcase-minus-outline:before {
  content: "ﰛ";
}

.mdi-briefcase-outline:before {
  content: "\f813";
}

.mdi-briefcase-plus:before {
  content: "飯";
}

.mdi-briefcase-plus-outline:before {
  content: "ﰜ";
}

.mdi-briefcase-remove:before {
  content: "飼";
}

.mdi-briefcase-remove-outline:before {
  content: "ﰝ";
}

.mdi-briefcase-search:before {
  content: "館";
}

.mdi-briefcase-search-outline:before {
  content: "ﰞ";
}

.mdi-briefcase-upload:before {
  content: "\f0d9";
}

.mdi-briefcase-upload-outline:before {
  content: "ﰟ";
}

.mdi-brightness-1:before {
  content: "\f0da";
}

.mdi-brightness-2:before {
  content: "\f0db";
}

.mdi-brightness-3:before {
  content: "\f0dc";
}

.mdi-brightness-4:before {
  content: "\f0dd";
}

.mdi-brightness-5:before {
  content: "\f0de";
}

.mdi-brightness-6:before {
  content: "\f0df";
}

.mdi-brightness-7:before {
  content: "\f0e0";
}

.mdi-brightness-auto:before {
  content: "\f0e1";
}

.mdi-brightness-percent:before {
  content: "ﳎ";
}

.mdi-broom:before {
  content: "\f0e2";
}

.mdi-brush:before {
  content: "\f0e3";
}

.mdi-buddhism:before {
  content: "壘";
}

.mdi-buffer:before {
  content: "\f619";
}

.mdi-bug:before {
  content: "\f0e4";
}

.mdi-bug-check:before {
  content: "鶴";
}

.mdi-bug-check-outline:before {
  content: "郞";
}

.mdi-bug-outline:before {
  content: "隷";
}

.mdi-bugle:before {
  content: "﶐";
}

.mdi-bulldozer:before {
  content: "﬇";
}

.mdi-bullet:before {
  content: "ﳏ";
}

.mdi-bulletin-board:before {
  content: "\f0e5";
}

.mdi-bullhorn:before {
  content: "\f0e6";
}

.mdi-bullhorn-outline:before {
  content: "﬈";
}

.mdi-bullseye:before {
  content: "\f5dd";
}

.mdi-bullseye-arrow:before {
  content: "\f8c8";
}

.mdi-bus:before {
  content: "\f0e7";
}

.mdi-bus-alert:before {
  content: "滛";
}

.mdi-bus-articulated-end:before {
  content: "\f79b";
}

.mdi-bus-articulated-front:before {
  content: "\f79c";
}

.mdi-bus-clock:before {
  content: "\f8c9";
}

.mdi-bus-double-decker:before {
  content: "\f79d";
}

.mdi-bus-school:before {
  content: "\f79e";
}

.mdi-bus-side:before {
  content: "\f79f";
}

.mdi-cached:before {
  content: "\f0e8";
}

.mdi-cactus:before {
  content: "﶑";
}

.mdi-cake:before {
  content: "\f0e9";
}

.mdi-cake-layered:before {
  content: "\f0ea";
}

.mdi-cake-variant:before {
  content: "\f0eb";
}

.mdi-calculator:before {
  content: "\f0ec";
}

.mdi-calculator-variant:before {
  content: "滋";
}

.mdi-calendar:before {
  content: "\f0ed";
}

.mdi-calendar-alert:before {
  content: "侮";
}

.mdi-calendar-blank:before {
  content: "\f0ee";
}

.mdi-calendar-blank-outline:before {
  content: "﭂";
}

.mdi-calendar-check:before {
  content: "\f0ef";
}

.mdi-calendar-check-outline:before {
  content: "ﰠ";
}

.mdi-calendar-clock:before {
  content: "\f0f0";
}

.mdi-calendar-edit:before {
  content: "\f8a6";
}

.mdi-calendar-export:before {
  content: "﬉";
}

.mdi-calendar-heart:before {
  content: "六";
}

.mdi-calendar-import:before {
  content: "﬊";
}

.mdi-calendar-minus:before {
  content: "ﴸ";
}

.mdi-calendar-multiple:before {
  content: "\f0f1";
}

.mdi-calendar-multiple-check:before {
  content: "\f0f2";
}

.mdi-calendar-multiselect:before {
  content: "僧";
}

.mdi-calendar-outline:before {
  content: "ףּ";
}

.mdi-calendar-plus:before {
  content: "\f0f3";
}

.mdi-calendar-question:before {
  content: "\f691";
}

.mdi-calendar-range:before {
  content: "\f678";
}

.mdi-calendar-range-outline:before {
  content: "פּ";
}

.mdi-calendar-remove:before {
  content: "\f0f4";
}

.mdi-calendar-remove-outline:before {
  content: "ﰡ";
}

.mdi-calendar-search:before {
  content: "屢";
}

.mdi-calendar-star:before {
  content: "戮";
}

.mdi-calendar-text:before {
  content: "\f0f5";
}

.mdi-calendar-text-outline:before {
  content: "ﰢ";
}

.mdi-calendar-today:before {
  content: "\f0f6";
}

.mdi-calendar-week:before {
  content: "免";
}

.mdi-calendar-week-begin:before {
  content: "勉";
}

.mdi-call-made:before {
  content: "\f0f7";
}

.mdi-call-merge:before {
  content: "\f0f8";
}

.mdi-call-missed:before {
  content: "\f0f9";
}

.mdi-call-received:before {
  content: "\f0fa";
}

.mdi-call-split:before {
  content: "\f0fb";
}

.mdi-camcorder:before {
  content: "\f0fc";
}

.mdi-camcorder-box:before {
  content: "\f0fd";
}

.mdi-camcorder-box-off:before {
  content: "\f0fe";
}

.mdi-camcorder-off:before {
  content: "\f0ff";
}

.mdi-camera:before {
  content: "\f100";
}

.mdi-camera-account:before {
  content: "\f8ca";
}

.mdi-camera-burst:before {
  content: "\f692";
}

.mdi-camera-control:before {
  content: "﭅";
}

.mdi-camera-enhance:before {
  content: "\f101";
}

.mdi-camera-enhance-outline:before {
  content: "צּ";
}

.mdi-camera-front:before {
  content: "\f102";
}

.mdi-camera-front-variant:before {
  content: "\f103";
}

.mdi-camera-gopro:before {
  content: "\f7a0";
}

.mdi-camera-image:before {
  content: "\f8cb";
}

.mdi-camera-iris:before {
  content: "\f104";
}

.mdi-camera-metering-center:before {
  content: "\f7a1";
}

.mdi-camera-metering-matrix:before {
  content: "\f7a2";
}

.mdi-camera-metering-partial:before {
  content: "\f7a3";
}

.mdi-camera-metering-spot:before {
  content: "\f7a4";
}

.mdi-camera-off:before {
  content: "\f5df";
}

.mdi-camera-outline:before {
  content: "ﴹ";
}

.mdi-camera-party-mode:before {
  content: "\f105";
}

.mdi-camera-rear:before {
  content: "\f106";
}

.mdi-camera-rear-variant:before {
  content: "\f107";
}

.mdi-camera-switch:before {
  content: "\f108";
}

.mdi-camera-timer:before {
  content: "\f109";
}

.mdi-camera-wireless:before {
  content: "ﶒ";
}

.mdi-camera-wireless-outline:before {
  content: "ﶓ";
}

.mdi-cancel:before {
  content: "\f739";
}

.mdi-candle:before {
  content: "\f5e2";
}

.mdi-candycane:before {
  content: "\f10a";
}

.mdi-cannabis:before {
  content: "\f7a5";
}

.mdi-caps-lock:before {
  content: "漢";
}

.mdi-car:before {
  content: "\f10b";
}

.mdi-car-battery:before {
  content: "\f10c";
}

.mdi-car-brake-abs:before {
  content: "ﰣ";
}

.mdi-car-brake-alert:before {
  content: "ﰤ";
}

.mdi-car-brake-hold:before {
  content: "ﴺ";
}

.mdi-car-brake-parking:before {
  content: "ﴻ";
}

.mdi-car-connected:before {
  content: "\f10d";
}

.mdi-car-convertible:before {
  content: "\f7a6";
}

.mdi-car-cruise-control:before {
  content: "ﴼ";
}

.mdi-car-defrost-front:before {
  content: "ﴽ";
}

.mdi-car-defrost-rear:before {
  content: "﴾";
}

.mdi-car-door:before {
  content: "קּ";
}

.mdi-car-electric:before {
  content: "רּ";
}

.mdi-car-esp:before {
  content: "ﰥ";
}

.mdi-car-estate:before {
  content: "\f7a7";
}

.mdi-car-hatchback:before {
  content: "\f7a8";
}

.mdi-car-key:before {
  content: "שּ";
}

.mdi-car-light-dimmed:before {
  content: "ﰦ";
}

.mdi-car-light-fog:before {
  content: "ﰧ";
}

.mdi-car-light-high:before {
  content: "ﰨ";
}

.mdi-car-limousine:before {
  content: "\f8cc";
}

.mdi-car-multiple:before {
  content: "תּ";
}

.mdi-car-parking-lights:before {
  content: "﴿";
}

.mdi-car-pickup:before {
  content: "\f7a9";
}

.mdi-car-side:before {
  content: "\f7aa";
}

.mdi-car-sports:before {
  content: "\f7ab";
}

.mdi-car-tire-alert:before {
  content: "ﰩ";
}

.mdi-car-traction-control:before {
  content: "﵀";
}

.mdi-car-wash:before {
  content: "\f10e";
}

.mdi-caravan:before {
  content: "\f7ac";
}

.mdi-card:before {
  content: "וֹ";
}

.mdi-card-bulleted:before {
  content: "בֿ";
}

.mdi-card-bulleted-off:before {
  content: "כֿ";
}

.mdi-card-bulleted-off-outline:before {
  content: "פֿ";
}

.mdi-card-bulleted-outline:before {
  content: "ﭏ";
}

.mdi-card-bulleted-settings:before {
  content: "ﭐ";
}

.mdi-card-bulleted-settings-outline:before {
  content: "ﭑ";
}

.mdi-card-outline:before {
  content: "ﭒ";
}

.mdi-card-text:before {
  content: "ﭓ";
}

.mdi-card-text-outline:before {
  content: "ﭔ";
}

.mdi-cards:before {
  content: "\f638";
}

.mdi-cards-club:before {
  content: "\f8cd";
}

.mdi-cards-diamond:before {
  content: "\f8ce";
}

.mdi-cards-heart:before {
  content: "\f8cf";
}

.mdi-cards-outline:before {
  content: "\f639";
}

.mdi-cards-playing-outline:before {
  content: "\f63a";
}

.mdi-cards-spade:before {
  content: "\f8d0";
}

.mdi-cards-variant:before {
  content: "\f6c6";
}

.mdi-carrot:before {
  content: "\f10f";
}

.mdi-carry-on-bag-check:before {
  content: "﵁";
}

.mdi-cart:before {
  content: "\f110";
}

.mdi-cart-arrow-down:before {
  content: "﵂";
}

.mdi-cart-arrow-right:before {
  content: "ﰪ";
}

.mdi-cart-arrow-up:before {
  content: "﵃";
}

.mdi-cart-minus:before {
  content: "﵄";
}

.mdi-cart-off:before {
  content: "\f66b";
}

.mdi-cart-outline:before {
  content: "\f111";
}

.mdi-cart-plus:before {
  content: "\f112";
}

.mdi-cart-remove:before {
  content: "﵅";
}

.mdi-case-sensitive-alt:before {
  content: "\f113";
}

.mdi-cash:before {
  content: "\f114";
}

.mdi-cash-100:before {
  content: "\f115";
}

.mdi-cash-marker:before {
  content: "ﶔ";
}

.mdi-cash-multiple:before {
  content: "\f116";
}

.mdi-cash-refund:before {
  content: "瀞";
}

.mdi-cash-register:before {
  content: "ﳐ";
}

.mdi-cash-usd:before {
  content: "\f117";
}

.mdi-cassette:before {
  content: "陸";
}

.mdi-cast:before {
  content: "\f118";
}

.mdi-cast-connected:before {
  content: "\f119";
}

.mdi-cast-off:before {
  content: "\f789";
}

.mdi-castle:before {
  content: "\f11a";
}

.mdi-cat:before {
  content: "\f11b";
}

.mdi-cctv:before {
  content: "\f7ad";
}

.mdi-ceiling-light:before {
  content: "\f768";
}

.mdi-cellphone:before {
  content: "\f11c";
}

.mdi-cellphone-android:before {
  content: "\f11d";
}

.mdi-cellphone-arrow-down:before {
  content: "倫";
}

.mdi-cellphone-basic:before {
  content: "\f11e";
}

.mdi-cellphone-dock:before {
  content: "\f11f";
}

.mdi-cellphone-erase:before {
  content: "樓";
}

.mdi-cellphone-iphone:before {
  content: "\f120";
}

.mdi-cellphone-key:before {
  content: "淚";
}

.mdi-cellphone-link:before {
  content: "\f121";
}

.mdi-cellphone-link-off:before {
  content: "\f122";
}

.mdi-cellphone-lock:before {
  content: "漏";
}

.mdi-cellphone-message:before {
  content: "\f8d2";
}

.mdi-cellphone-off:before {
  content: "累";
}

.mdi-cellphone-screenshot:before {
  content: "勤";
}

.mdi-cellphone-settings:before {
  content: "\f123";
}

.mdi-cellphone-settings-variant:before {
  content: "縷";
}

.mdi-cellphone-sound:before {
  content: "陋";
}

.mdi-cellphone-text:before {
  content: "\f8d1";
}

.mdi-cellphone-wireless:before {
  content: "\f814";
}

.mdi-celtic-cross:before {
  content: "ﳑ";
}

.mdi-certificate:before {
  content: "\f124";
}

.mdi-chair-school:before {
  content: "\f125";
}

.mdi-charity:before {
  content: "ﰫ";
}

.mdi-chart-arc:before {
  content: "\f126";
}

.mdi-chart-areaspline:before {
  content: "\f127";
}

.mdi-chart-bar:before {
  content: "\f128";
}

.mdi-chart-bar-stacked:before {
  content: "\f769";
}

.mdi-chart-bell-curve:before {
  content: "ﰬ";
}

.mdi-chart-bubble:before {
  content: "\f5e3";
}

.mdi-chart-donut:before {
  content: "\f7ae";
}

.mdi-chart-donut-variant:before {
  content: "\f7af";
}

.mdi-chart-gantt:before {
  content: "\f66c";
}

.mdi-chart-histogram:before {
  content: "\f129";
}

.mdi-chart-line:before {
  content: "\f12a";
}

.mdi-chart-line-stacked:before {
  content: "\f76a";
}

.mdi-chart-line-variant:before {
  content: "\f7b0";
}

.mdi-chart-multiline:before {
  content: "\f8d3";
}

.mdi-chart-pie:before {
  content: "\f12b";
}

.mdi-chart-scatterplot-hexbin:before {
  content: "\f66d";
}

.mdi-chart-timeline:before {
  content: "\f66e";
}

.mdi-chat:before {
  content: "ﭕ";
}

.mdi-chat-alert:before {
  content: "ﭖ";
}

.mdi-chat-processing:before {
  content: "ﭗ";
}

.mdi-check:before {
  content: "\f12c";
}

.mdi-check-all:before {
  content: "\f12d";
}

.mdi-check-box-multiple-outline:before {
  content: "ﰭ";
}

.mdi-check-box-outline:before {
  content: "ﰮ";
}

.mdi-check-circle:before {
  content: "\f5e0";
}

.mdi-check-circle-outline:before {
  content: "\f5e1";
}

.mdi-check-decagram:before {
  content: "\f790";
}

.mdi-check-network:before {
  content: "ﰯ";
}

.mdi-check-network-outline:before {
  content: "ﰰ";
}

.mdi-check-outline:before {
  content: "\f854";
}

.mdi-checkbook:before {
  content: "煮";
}

.mdi-checkbox-blank:before {
  content: "\f12e";
}

.mdi-checkbox-blank-circle:before {
  content: "\f12f";
}

.mdi-checkbox-blank-circle-outline:before {
  content: "\f130";
}

.mdi-checkbox-blank-outline:before {
  content: "\f131";
}

.mdi-checkbox-intermediate:before {
  content: "\f855";
}

.mdi-checkbox-marked:before {
  content: "\f132";
}

.mdi-checkbox-marked-circle:before {
  content: "\f133";
}

.mdi-checkbox-marked-circle-outline:before {
  content: "\f134";
}

.mdi-checkbox-marked-outline:before {
  content: "\f135";
}

.mdi-checkbox-multiple-blank:before {
  content: "\f136";
}

.mdi-checkbox-multiple-blank-circle:before {
  content: "\f63b";
}

.mdi-checkbox-multiple-blank-circle-outline:before {
  content: "\f63c";
}

.mdi-checkbox-multiple-blank-outline:before {
  content: "\f137";
}

.mdi-checkbox-multiple-marked:before {
  content: "\f138";
}

.mdi-checkbox-multiple-marked-circle:before {
  content: "\f63d";
}

.mdi-checkbox-multiple-marked-circle-outline:before {
  content: "\f63e";
}

.mdi-checkbox-multiple-marked-outline:before {
  content: "\f139";
}

.mdi-checkerboard:before {
  content: "\f13a";
}

.mdi-chef-hat:before {
  content: "ﭘ";
}

.mdi-chemical-weapon:before {
  content: "\f13b";
}

.mdi-chess-bishop:before {
  content: "\f85b";
}

.mdi-chess-king:before {
  content: "\f856";
}

.mdi-chess-knight:before {
  content: "\f857";
}

.mdi-chess-pawn:before {
  content: "\f858";
}

.mdi-chess-queen:before {
  content: "\f859";
}

.mdi-chess-rook:before {
  content: "\f85a";
}

.mdi-chevron-double-down:before {
  content: "\f13c";
}

.mdi-chevron-double-left:before {
  content: "\f13d";
}

.mdi-chevron-double-right:before {
  content: "\f13e";
}

.mdi-chevron-double-up:before {
  content: "\f13f";
}

.mdi-chevron-down:before {
  content: "\f140";
}

.mdi-chevron-down-box:before {
  content: "崙";
}

.mdi-chevron-down-box-outline:before {
  content: "淪";
}

.mdi-chevron-down-circle:before {
  content: "﬋";
}

.mdi-chevron-down-circle-outline:before {
  content: "﬌";
}

.mdi-chevron-left:before {
  content: "\f141";
}

.mdi-chevron-left-box:before {
  content: "輪";
}

.mdi-chevron-left-box-outline:before {
  content: "律";
}

.mdi-chevron-left-circle:before {
  content: "﬍";
}

.mdi-chevron-left-circle-outline:before {
  content: "﬎";
}

.mdi-chevron-right:before {
  content: "\f142";
}

.mdi-chevron-right-box:before {
  content: "慄";
}

.mdi-chevron-right-box-outline:before {
  content: "栗";
}

.mdi-chevron-right-circle:before {
  content: "﬏";
}

.mdi-chevron-right-circle-outline:before {
  content: "﬐";
}

.mdi-chevron-triple-down:before {
  content: "ﶕ";
}

.mdi-chevron-triple-left:before {
  content: "ﶖ";
}

.mdi-chevron-triple-right:before {
  content: "ﶗ";
}

.mdi-chevron-triple-up:before {
  content: "ﶘ";
}

.mdi-chevron-up:before {
  content: "\f143";
}

.mdi-chevron-up-box:before {
  content: "率";
}

.mdi-chevron-up-box-outline:before {
  content: "隆";
}

.mdi-chevron-up-circle:before {
  content: "﬑";
}

.mdi-chevron-up-circle-outline:before {
  content: "﬒";
}

.mdi-chili-hot:before {
  content: "\f7b1";
}

.mdi-chili-medium:before {
  content: "\f7b2";
}

.mdi-chili-mild:before {
  content: "\f7b3";
}

.mdi-chip:before {
  content: "\f61a";
}

.mdi-christianity:before {
  content: "勒";
}

.mdi-christianity-outline:before {
  content: "ﳒ";
}

.mdi-church:before {
  content: "\f144";
}

.mdi-circle:before {
  content: "\f764";
}

.mdi-circle-edit-outline:before {
  content: "\f8d4";
}

.mdi-circle-medium:before {
  content: "利";
}

.mdi-circle-outline:before {
  content: "\f765";
}

.mdi-circle-slice-1:before {
  content: "瞧";
}

.mdi-circle-slice-2:before {
  content: "爵";
}

.mdi-circle-slice-3:before {
  content: "犯";
}

.mdi-circle-slice-4:before {
  content: "猪";
}

.mdi-circle-slice-5:before {
  content: "瑱";
}

.mdi-circle-slice-6:before {
  content: "甆";
}

.mdi-circle-slice-7:before {
  content: "画";
}

.mdi-circle-slice-8:before {
  content: "瘝";
}

.mdi-circle-small:before {
  content: "吏";
}

.mdi-cisco-webex:before {
  content: "\f145";
}

.mdi-city:before {
  content: "\f146";
}

.mdi-city-variant:before {
  content: "卑";
}

.mdi-city-variant-outline:before {
  content: "喝";
}

.mdi-clipboard:before {
  content: "\f147";
}

.mdi-clipboard-account:before {
  content: "\f148";
}

.mdi-clipboard-account-outline:before {
  content: "ﰱ";
}

.mdi-clipboard-alert:before {
  content: "\f149";
}

.mdi-clipboard-alert-outline:before {
  content: "ﳓ";
}

.mdi-clipboard-arrow-down:before {
  content: "\f14a";
}

.mdi-clipboard-arrow-down-outline:before {
  content: "ﰲ";
}

.mdi-clipboard-arrow-left:before {
  content: "\f14b";
}

.mdi-clipboard-arrow-left-outline:before {
  content: "ﳔ";
}

.mdi-clipboard-arrow-right:before {
  content: "ﳕ";
}

.mdi-clipboard-arrow-right-outline:before {
  content: "ﳖ";
}

.mdi-clipboard-arrow-up:before {
  content: "ﰳ";
}

.mdi-clipboard-arrow-up-outline:before {
  content: "ﰴ";
}

.mdi-clipboard-check:before {
  content: "\f14c";
}

.mdi-clipboard-check-outline:before {
  content: "\f8a7";
}

.mdi-clipboard-flow:before {
  content: "\f6c7";
}

.mdi-clipboard-outline:before {
  content: "\f14d";
}

.mdi-clipboard-play:before {
  content: "ﰵ";
}

.mdi-clipboard-play-outline:before {
  content: "ﰶ";
}

.mdi-clipboard-plus:before {
  content: "\f750";
}

.mdi-clipboard-pulse:before {
  content: "\f85c";
}

.mdi-clipboard-pulse-outline:before {
  content: "\f85d";
}

.mdi-clipboard-text:before {
  content: "\f14e";
}

.mdi-clipboard-text-outline:before {
  content: "嘆";
}

.mdi-clipboard-text-play:before {
  content: "ﰷ";
}

.mdi-clipboard-text-play-outline:before {
  content: "ﰸ";
}

.mdi-clippy:before {
  content: "\f14f";
}

.mdi-clock:before {
  content: "肋";
}

.mdi-clock-alert:before {
  content: "凜";
}

.mdi-clock-alert-outline:before {
  content: "\f5ce";
}

.mdi-clock-end:before {
  content: "\f151";
}

.mdi-clock-fast:before {
  content: "\f152";
}

.mdi-clock-in:before {
  content: "\f153";
}

.mdi-clock-out:before {
  content: "\f154";
}

.mdi-clock-outline:before {
  content: "\f150";
}

.mdi-clock-start:before {
  content: "\f155";
}

.mdi-close:before {
  content: "\f156";
}

.mdi-close-box:before {
  content: "\f157";
}

.mdi-close-box-multiple:before {
  content: "ﰹ";
}

.mdi-close-box-multiple-outline:before {
  content: "ﰺ";
}

.mdi-close-box-outline:before {
  content: "\f158";
}

.mdi-close-circle:before {
  content: "\f159";
}

.mdi-close-circle-outline:before {
  content: "\f15a";
}

.mdi-close-network:before {
  content: "\f15b";
}

.mdi-close-network-outline:before {
  content: "ﰻ";
}

.mdi-close-octagon:before {
  content: "\f15c";
}

.mdi-close-octagon-outline:before {
  content: "\f15d";
}

.mdi-close-outline:before {
  content: "\f6c8";
}

.mdi-closed-caption:before {
  content: "\f15e";
}

.mdi-closed-caption-outline:before {
  content: "ﶙ";
}

.mdi-cloud:before {
  content: "\f15f";
}

.mdi-cloud-alert:before {
  content: "履";
}

.mdi-cloud-braces:before {
  content: "\f7b4";
}

.mdi-cloud-check:before {
  content: "\f160";
}

.mdi-cloud-circle:before {
  content: "\f161";
}

.mdi-cloud-download:before {
  content: "\f162";
}

.mdi-cloud-download-outline:before {
  content: "ﭙ";
}

.mdi-cloud-off-outline:before {
  content: "\f164";
}

.mdi-cloud-outline:before {
  content: "\f163";
}

.mdi-cloud-print:before {
  content: "\f165";
}

.mdi-cloud-print-outline:before {
  content: "\f166";
}

.mdi-cloud-question:before {
  content: "器";
}

.mdi-cloud-search:before {
  content: "凌";
}

.mdi-cloud-search-outline:before {
  content: "稜";
}

.mdi-cloud-sync:before {
  content: "\f63f";
}

.mdi-cloud-tags:before {
  content: "\f7b5";
}

.mdi-cloud-upload:before {
  content: "\f167";
}

.mdi-cloud-upload-outline:before {
  content: "ﭚ";
}

.mdi-clover:before {
  content: "\f815";
}

.mdi-code-array:before {
  content: "\f168";
}

.mdi-code-braces:before {
  content: "\f169";
}

.mdi-code-brackets:before {
  content: "\f16a";
}

.mdi-code-equal:before {
  content: "\f16b";
}

.mdi-code-greater-than:before {
  content: "\f16c";
}

.mdi-code-greater-than-or-equal:before {
  content: "\f16d";
}

.mdi-code-less-than:before {
  content: "\f16e";
}

.mdi-code-less-than-or-equal:before {
  content: "\f16f";
}

.mdi-code-not-equal:before {
  content: "\f170";
}

.mdi-code-not-equal-variant:before {
  content: "\f171";
}

.mdi-code-parentheses:before {
  content: "\f172";
}

.mdi-code-string:before {
  content: "\f173";
}

.mdi-code-tags:before {
  content: "\f174";
}

.mdi-code-tags-check:before {
  content: "\f693";
}

.mdi-codepen:before {
  content: "\f175";
}

.mdi-coffee:before {
  content: "\f176";
}

.mdi-coffee-outline:before {
  content: "\f6c9";
}

.mdi-coffee-to-go:before {
  content: "\f177";
}

.mdi-coffin:before {
  content: "ﭛ";
}

.mdi-cogs:before {
  content: "\f8d5";
}

.mdi-coin:before {
  content: "\f178";
}

.mdi-coins:before {
  content: "\f694";
}

.mdi-collage:before {
  content: "\f640";
}

.mdi-collapse-all:before {
  content: "瘟";
}

.mdi-collapse-all-outline:before {
  content: "益";
}

.mdi-color-helper:before {
  content: "\f179";
}

.mdi-comment:before {
  content: "\f17a";
}

.mdi-comment-account:before {
  content: "\f17b";
}

.mdi-comment-account-outline:before {
  content: "\f17c";
}

.mdi-comment-alert:before {
  content: "\f17d";
}

.mdi-comment-alert-outline:before {
  content: "\f17e";
}

.mdi-comment-arrow-left:before {
  content: "易";
}

.mdi-comment-arrow-left-outline:before {
  content: "李";
}

.mdi-comment-arrow-right:before {
  content: "梨";
}

.mdi-comment-arrow-right-outline:before {
  content: "泥";
}

.mdi-comment-check:before {
  content: "\f17f";
}

.mdi-comment-check-outline:before {
  content: "\f180";
}

.mdi-comment-eye:before {
  content: "塀";
}

.mdi-comment-eye-outline:before {
  content: "墨";
}

.mdi-comment-multiple:before {
  content: "\f85e";
}

.mdi-comment-multiple-outline:before {
  content: "\f181";
}

.mdi-comment-outline:before {
  content: "\f182";
}

.mdi-comment-plus:before {
  content: "理";
}

.mdi-comment-plus-outline:before {
  content: "\f183";
}

.mdi-comment-processing:before {
  content: "\f184";
}

.mdi-comment-processing-outline:before {
  content: "\f185";
}

.mdi-comment-question:before {
  content: "\f816";
}

.mdi-comment-question-outline:before {
  content: "\f186";
}

.mdi-comment-remove:before {
  content: "\f5de";
}

.mdi-comment-remove-outline:before {
  content: "\f187";
}

.mdi-comment-search:before {
  content: "層";
}

.mdi-comment-search-outline:before {
  content: "屮";
}

.mdi-comment-text:before {
  content: "\f188";
}

.mdi-comment-text-multiple:before {
  content: "\f85f";
}

.mdi-comment-text-multiple-outline:before {
  content: "\f860";
}

.mdi-comment-text-outline:before {
  content: "\f189";
}

.mdi-compare:before {
  content: "\f18a";
}

.mdi-compass:before {
  content: "\f18b";
}

.mdi-compass-off:before {
  content: "ﭜ";
}

.mdi-compass-off-outline:before {
  content: "ﭝ";
}

.mdi-compass-outline:before {
  content: "\f18c";
}

.mdi-console:before {
  content: "\f18d";
}

.mdi-console-line:before {
  content: "\f7b6";
}

.mdi-console-network:before {
  content: "\f8a8";
}

.mdi-console-network-outline:before {
  content: "ﰼ";
}

.mdi-contact-mail:before {
  content: "\f18e";
}

.mdi-contactless-payment:before {
  content: "﵆";
}

.mdi-contacts:before {
  content: "\f6ca";
}

.mdi-contain:before {
  content: "悔";
}

.mdi-contain-end:before {
  content: "慨";
}

.mdi-contain-start:before {
  content: "憎";
}

.mdi-content-copy:before {
  content: "\f18f";
}

.mdi-content-cut:before {
  content: "\f190";
}

.mdi-content-duplicate:before {
  content: "\f191";
}

.mdi-content-paste:before {
  content: "\f192";
}

.mdi-content-save:before {
  content: "\f193";
}

.mdi-content-save-all:before {
  content: "\f194";
}

.mdi-content-save-edit:before {
  content: "ﳗ";
}

.mdi-content-save-edit-outline:before {
  content: "ﳘ";
}

.mdi-content-save-outline:before {
  content: "\f817";
}

.mdi-content-save-settings:before {
  content: "\f61b";
}

.mdi-content-save-settings-outline:before {
  content: "ﬓ";
}

.mdi-contrast:before {
  content: "\f195";
}

.mdi-contrast-box:before {
  content: "\f196";
}

.mdi-contrast-circle:before {
  content: "\f197";
}

.mdi-controller-classic:before {
  content: "ﭞ";
}

.mdi-controller-classic-outline:before {
  content: "ﭟ";
}

.mdi-cookie:before {
  content: "\f198";
}

.mdi-copyright:before {
  content: "\f5e6";
}

.mdi-cordova:before {
  content: "綾";
}

.mdi-corn:before {
  content: "\f7b7";
}

.mdi-counter:before {
  content: "\f199";
}

.mdi-cow:before {
  content: "\f19a";
}

.mdi-crane:before {
  content: "\f861";
}

.mdi-creation:before {
  content: "\f1c9";
}

.mdi-creative-commons:before {
  content: "﵇";
}

.mdi-credit-card:before {
  content: "\f19b";
}

.mdi-credit-card-marker:before {
  content: "ﶚ";
}

.mdi-credit-card-multiple:before {
  content: "\f19c";
}

.mdi-credit-card-off:before {
  content: "\f5e4";
}

.mdi-credit-card-plus:before {
  content: "\f675";
}

.mdi-credit-card-refund:before {
  content: "盛";
}

.mdi-credit-card-scan:before {
  content: "\f19d";
}

.mdi-credit-card-settings:before {
  content: "\f8d6";
}

.mdi-credit-card-wireless:before {
  content: "﵈";
}

.mdi-cricket:before {
  content: "﵉";
}

.mdi-crop:before {
  content: "\f19e";
}

.mdi-crop-free:before {
  content: "\f19f";
}

.mdi-crop-landscape:before {
  content: "\f1a0";
}

.mdi-crop-portrait:before {
  content: "\f1a1";
}

.mdi-crop-rotate:before {
  content: "\f695";
}

.mdi-crop-square:before {
  content: "\f1a2";
}

.mdi-crosshairs:before {
  content: "\f1a3";
}

.mdi-crosshairs-gps:before {
  content: "\f1a4";
}

.mdi-crown:before {
  content: "\f1a5";
}

.mdi-cryengine:before {
  content: "菱";
}

.mdi-crystal-ball:before {
  content: "ﬔ";
}

.mdi-cube:before {
  content: "\f1a6";
}

.mdi-cube-outline:before {
  content: "\f1a7";
}

.mdi-cube-scan:before {
  content: "ﭠ";
}

.mdi-cube-send:before {
  content: "\f1a8";
}

.mdi-cube-unfolded:before {
  content: "\f1a9";
}

.mdi-cup:before {
  content: "\f1aa";
}

.mdi-cup-off:before {
  content: "\f5e5";
}

.mdi-cup-water:before {
  content: "\f1ab";
}

.mdi-cupcake:before {
  content: "陵";
}

.mdi-curling:before {
  content: "\f862";
}

.mdi-currency-bdt:before {
  content: "\f863";
}

.mdi-currency-brl:before {
  content: "ﭡ";
}

.mdi-currency-btc:before {
  content: "\f1ac";
}

.mdi-currency-chf:before {
  content: "\f7b8";
}

.mdi-currency-cny:before {
  content: "\f7b9";
}

.mdi-currency-eth:before {
  content: "\f7ba";
}

.mdi-currency-eur:before {
  content: "\f1ad";
}

.mdi-currency-gbp:before {
  content: "\f1ae";
}

.mdi-currency-ils:before {
  content: "ﰽ";
}

.mdi-currency-inr:before {
  content: "\f1af";
}

.mdi-currency-jpy:before {
  content: "\f7bb";
}

.mdi-currency-krw:before {
  content: "\f7bc";
}

.mdi-currency-kzt:before {
  content: "\f864";
}

.mdi-currency-ngn:before {
  content: "\f1b0";
}

.mdi-currency-php:before {
  content: "痢";
}

.mdi-currency-rub:before {
  content: "\f1b1";
}

.mdi-currency-sign:before {
  content: "\f7bd";
}

.mdi-currency-try:before {
  content: "\f1b2";
}

.mdi-currency-twd:before {
  content: "\f7be";
}

.mdi-currency-usd:before {
  content: "\f1b3";
}

.mdi-currency-usd-off:before {
  content: "\f679";
}

.mdi-current-ac:before {
  content: "讀";
}

.mdi-current-dc:before {
  content: "拏";
}

.mdi-cursor-default:before {
  content: "\f1b4";
}

.mdi-cursor-default-click:before {
  content: "ﳙ";
}

.mdi-cursor-default-click-outline:before {
  content: "ﳚ";
}

.mdi-cursor-default-outline:before {
  content: "\f1b5";
}

.mdi-cursor-move:before {
  content: "\f1b6";
}

.mdi-cursor-pointer:before {
  content: "\f1b7";
}

.mdi-cursor-text:before {
  content: "\f5e7";
}

.mdi-database:before {
  content: "\f1b8";
}

.mdi-database-check:before {
  content: "直";
}

.mdi-database-edit:before {
  content: "ﭢ";
}

.mdi-database-export:before {
  content: "諾";
}

.mdi-database-import:before {
  content: "樂";
}

.mdi-database-lock:before {
  content: "睊";
}

.mdi-database-minus:before {
  content: "\f1b9";
}

.mdi-database-plus:before {
  content: "\f1ba";
}

.mdi-database-refresh:before {
  content: "ﳛ";
}

.mdi-database-remove:before {
  content: "ﳜ";
}

.mdi-database-search:before {
  content: "\f865";
}

.mdi-database-settings:before {
  content: "ﳝ";
}

.mdi-death-star:before {
  content: "\f8d7";
}

.mdi-death-star-variant:before {
  content: "\f8d8";
}

.mdi-deathly-hallows:before {
  content: "ﭣ";
}

.mdi-debian:before {
  content: "\f8d9";
}

.mdi-debug-step-into:before {
  content: "\f1bb";
}

.mdi-debug-step-out:before {
  content: "\f1bc";
}

.mdi-debug-step-over:before {
  content: "\f1bd";
}

.mdi-decagram:before {
  content: "\f76b";
}

.mdi-decagram-outline:before {
  content: "\f76c";
}

.mdi-decimal-decrease:before {
  content: "\f1be";
}

.mdi-decimal-increase:before {
  content: "\f1bf";
}

.mdi-delete:before {
  content: "\f1c0";
}

.mdi-delete-circle:before {
  content: "\f682";
}

.mdi-delete-circle-outline:before {
  content: "ﭤ";
}

.mdi-delete-empty:before {
  content: "\f6cb";
}

.mdi-delete-forever:before {
  content: "\f5e8";
}

.mdi-delete-forever-outline:before {
  content: "ﭥ";
}

.mdi-delete-outline:before {
  content: "罹";
}

.mdi-delete-restore:before {
  content: "\f818";
}

.mdi-delete-sweep:before {
  content: "\f5e9";
}

.mdi-delete-sweep-outline:before {
  content: "ﰾ";
}

.mdi-delete-variant:before {
  content: "\f1c1";
}

.mdi-delta:before {
  content: "\f1c2";
}

.mdi-desk-lamp:before {
  content: "丹";
}

.mdi-deskphone:before {
  content: "\f1c3";
}

.mdi-desktop-classic:before {
  content: "\f7bf";
}

.mdi-desktop-mac:before {
  content: "\f1c4";
}

.mdi-desktop-mac-dashboard:before {
  content: "裏";
}

.mdi-desktop-tower:before {
  content: "\f1c5";
}

.mdi-desktop-tower-monitor:before {
  content: "着";
}

.mdi-details:before {
  content: "\f1c6";
}

.mdi-dev-to:before {
  content: "﵊";
}

.mdi-developer-board:before {
  content: "\f696";
}

.mdi-deviantart:before {
  content: "\f1c7";
}

.mdi-dialpad:before {
  content: "\f61c";
}

.mdi-diameter:before {
  content: "ﰿ";
}

.mdi-diameter-outline:before {
  content: "ﱀ";
}

.mdi-diameter-variant:before {
  content: "ﱁ";
}

.mdi-diamond:before {
  content: "ﭦ";
}

.mdi-diamond-outline:before {
  content: "ﭧ";
}

.mdi-diamond-stone:before {
  content: "\f1c8";
}

.mdi-dice-1:before {
  content: "\f1ca";
}

.mdi-dice-2:before {
  content: "\f1cb";
}

.mdi-dice-3:before {
  content: "\f1cc";
}

.mdi-dice-4:before {
  content: "\f1cd";
}

.mdi-dice-5:before {
  content: "\f1ce";
}

.mdi-dice-6:before {
  content: "\f1cf";
}

.mdi-dice-d10:before {
  content: "\f76e";
}

.mdi-dice-d12:before {
  content: "\f866";
}

.mdi-dice-d20:before {
  content: "\f5ea";
}

.mdi-dice-d4:before {
  content: "\f5eb";
}

.mdi-dice-d6:before {
  content: "\f5ec";
}

.mdi-dice-d8:before {
  content: "\f5ed";
}

.mdi-dice-multiple:before {
  content: "\f76d";
}

.mdi-dictionary:before {
  content: "\f61d";
}

.mdi-dip-switch:before {
  content: "\f7c0";
}

.mdi-directions:before {
  content: "\f1d0";
}

.mdi-directions-fork:before {
  content: "\f641";
}

.mdi-disc:before {
  content: "\f5ee";
}

.mdi-disc-alert:before {
  content: "\f1d1";
}

.mdi-disc-player:before {
  content: "寧";
}

.mdi-discord:before {
  content: "\f66f";
}

.mdi-dishwasher:before {
  content: "磌";
}

.mdi-disqus:before {
  content: "\f1d2";
}

.mdi-disqus-outline:before {
  content: "\f1d3";
}

.mdi-diving-flippers:before {
  content: "ﶛ";
}

.mdi-diving-helmet:before {
  content: "ﶜ";
}

.mdi-diving-scuba:before {
  content: "ﶝ";
}

.mdi-diving-scuba-flag:before {
  content: "ﶞ";
}

.mdi-diving-scuba-tank:before {
  content: "ﶟ";
}

.mdi-diving-scuba-tank-multiple:before {
  content: "ﶠ";
}

.mdi-diving-snorkel:before {
  content: "ﶡ";
}

.mdi-division:before {
  content: "\f1d4";
}

.mdi-division-box:before {
  content: "\f1d5";
}

.mdi-dlna:before {
  content: "懲";
}

.mdi-dna:before {
  content: "\f683";
}

.mdi-dns:before {
  content: "\f1d6";
}

.mdi-dns-outline:before {
  content: "ﭨ";
}

.mdi-do-not-disturb:before {
  content: "\f697";
}

.mdi-do-not-disturb-off:before {
  content: "\f698";
}

.mdi-docker:before {
  content: "\f867";
}

.mdi-doctor:before {
  content: "敏";
}

.mdi-dog:before {
  content: "既";
}

.mdi-dog-service:before {
  content: "窱";
}

.mdi-dog-side:before {
  content: "暑";
}

.mdi-dolby:before {
  content: "\f6b2";
}

.mdi-domain:before {
  content: "\f1d7";
}

.mdi-domain-off:before {
  content: "﵋";
}

.mdi-donkey:before {
  content: "\f7c1";
}

.mdi-door:before {
  content: "\f819";
}

.mdi-door-closed:before {
  content: "\f81a";
}

.mdi-door-open:before {
  content: "\f81b";
}

.mdi-doorbell-video:before {
  content: "\f868";
}

.mdi-dot-net:before {
  content: "節";
}

.mdi-dots-horizontal:before {
  content: "\f1d8";
}

.mdi-dots-horizontal-circle:before {
  content: "\f7c2";
}

.mdi-dots-horizontal-circle-outline:before {
  content: "ﭩ";
}

.mdi-dots-vertical:before {
  content: "\f1d9";
}

.mdi-dots-vertical-circle:before {
  content: "\f7c3";
}

.mdi-dots-vertical-circle-outline:before {
  content: "ﭪ";
}

.mdi-douban:before {
  content: "\f699";
}

.mdi-download:before {
  content: "\f1da";
}

.mdi-download-multiple:before {
  content: "裡";
}

.mdi-download-network:before {
  content: "\f6f3";
}

.mdi-download-network-outline:before {
  content: "ﱂ";
}

.mdi-download-outline:before {
  content: "ﭫ";
}

.mdi-drag:before {
  content: "\f1db";
}

.mdi-drag-horizontal:before {
  content: "\f1dc";
}

.mdi-drag-variant:before {
  content: "ﭬ";
}

.mdi-drag-vertical:before {
  content: "\f1dd";
}

.mdi-drama-masks:before {
  content: "ﳞ";
}

.mdi-drawing:before {
  content: "\f1de";
}

.mdi-drawing-box:before {
  content: "\f1df";
}

.mdi-dribbble:before {
  content: "\f1e0";
}

.mdi-dribbble-box:before {
  content: "\f1e1";
}

.mdi-drone:before {
  content: "\f1e2";
}

.mdi-dropbox:before {
  content: "\f1e3";
}

.mdi-drupal:before {
  content: "\f1e4";
}

.mdi-duck:before {
  content: "\f1e5";
}

.mdi-dumbbell:before {
  content: "\f1e6";
}

.mdi-dump-truck:before {
  content: "ﱃ";
}

.mdi-ear-hearing:before {
  content: "\f7c4";
}

.mdi-ear-hearing-off:before {
  content: "梅";
}

.mdi-earth:before {
  content: "\f1e7";
}

.mdi-earth-box:before {
  content: "\f6cc";
}

.mdi-earth-box-off:before {
  content: "\f6cd";
}

.mdi-earth-off:before {
  content: "\f1e8";
}

.mdi-edge:before {
  content: "\f1e9";
}

.mdi-egg:before {
  content: "类";
}

.mdi-egg-easter:before {
  content: "絛";
}

.mdi-eight-track:before {
  content: "里";
}

.mdi-eject:before {
  content: "\f1ea";
}

.mdi-eject-outline:before {
  content: "ﭭ";
}

.mdi-elephant:before {
  content: "\f7c5";
}

.mdi-elevation-decline:before {
  content: "\f1eb";
}

.mdi-elevation-rise:before {
  content: "\f1ec";
}

.mdi-elevator:before {
  content: "\f1ed";
}

.mdi-email:before {
  content: "\f1ee";
}

.mdi-email-alert:before {
  content: "\f6ce";
}

.mdi-email-box:before {
  content: "ﳟ";
}

.mdi-email-check:before {
  content: "練";
}

.mdi-email-check-outline:before {
  content: "缾";
}

.mdi-email-lock:before {
  content: "\f1f1";
}

.mdi-email-mark-as-unread:before {
  content: "ﭮ";
}

.mdi-email-open:before {
  content: "\f1ef";
}

.mdi-email-open-outline:before {
  content: "\f5ef";
}

.mdi-email-outline:before {
  content: "\f1f0";
}

.mdi-email-plus:before {
  content: "離";
}

.mdi-email-plus-outline:before {
  content: "匿";
}

.mdi-email-search:before {
  content: "怒";
}

.mdi-email-search-outline:before {
  content: "率";
}

.mdi-email-variant:before {
  content: "\f5f0";
}

.mdi-ember:before {
  content: "ﬕ";
}

.mdi-emby:before {
  content: "\f6b3";
}

.mdi-emoticon:before {
  content: "ﱄ";
}

.mdi-emoticon-angry:before {
  content: "ﱅ";
}

.mdi-emoticon-angry-outline:before {
  content: "ﱆ";
}

.mdi-emoticon-cool:before {
  content: "ﱇ";
}

.mdi-emoticon-cool-outline:before {
  content: "\f1f3";
}

.mdi-emoticon-cry:before {
  content: "ﱈ";
}

.mdi-emoticon-cry-outline:before {
  content: "ﱉ";
}

.mdi-emoticon-dead:before {
  content: "ﱊ";
}

.mdi-emoticon-dead-outline:before {
  content: "\f69a";
}

.mdi-emoticon-devil:before {
  content: "ﱋ";
}

.mdi-emoticon-devil-outline:before {
  content: "\f1f4";
}

.mdi-emoticon-excited:before {
  content: "ﱌ";
}

.mdi-emoticon-excited-outline:before {
  content: "\f69b";
}

.mdi-emoticon-happy:before {
  content: "ﱍ";
}

.mdi-emoticon-happy-outline:before {
  content: "\f1f5";
}

.mdi-emoticon-kiss:before {
  content: "ﱎ";
}

.mdi-emoticon-kiss-outline:before {
  content: "ﱏ";
}

.mdi-emoticon-neutral:before {
  content: "ﱐ";
}

.mdi-emoticon-neutral-outline:before {
  content: "\f1f6";
}

.mdi-emoticon-outline:before {
  content: "\f1f2";
}

.mdi-emoticon-poop:before {
  content: "\f1f7";
}

.mdi-emoticon-poop-outline:before {
  content: "ﱑ";
}

.mdi-emoticon-sad:before {
  content: "ﱒ";
}

.mdi-emoticon-sad-outline:before {
  content: "\f1f8";
}

.mdi-emoticon-tongue:before {
  content: "\f1f9";
}

.mdi-emoticon-tongue-outline:before {
  content: "ﱓ";
}

.mdi-emoticon-wink:before {
  content: "ﱔ";
}

.mdi-emoticon-wink-outline:before {
  content: "ﱕ";
}

.mdi-engine:before {
  content: "\f1fa";
}

.mdi-engine-off:before {
  content: "海";
}

.mdi-engine-off-outline:before {
  content: "渚";
}

.mdi-engine-outline:before {
  content: "\f1fb";
}

.mdi-equal:before {
  content: "\f1fc";
}

.mdi-equal-box:before {
  content: "\f1fd";
}

.mdi-eraser:before {
  content: "\f1fe";
}

.mdi-eraser-variant:before {
  content: "\f642";
}

.mdi-escalator:before {
  content: "\f1ff";
}

.mdi-eslint:before {
  content: "ﱖ";
}

.mdi-et:before {
  content: "者";
}

.mdi-ethereum:before {
  content: "\f869";
}

.mdi-ethernet:before {
  content: "\f200";
}

.mdi-ethernet-cable:before {
  content: "\f201";
}

.mdi-ethernet-cable-off:before {
  content: "\f202";
}

.mdi-etsy:before {
  content: "\f203";
}

.mdi-ev-station:before {
  content: "\f5f1";
}

.mdi-eventbrite:before {
  content: "\f7c6";
}

.mdi-evernote:before {
  content: "\f204";
}

.mdi-exclamation:before {
  content: "\f205";
}

.mdi-exit-run:before {
  content: "漢";
}

.mdi-exit-to-app:before {
  content: "\f206";
}

.mdi-expand-all:before {
  content: "荒";
}

.mdi-expand-all-outline:before {
  content: "華";
}

.mdi-exponent:before {
  content: "異";
}

.mdi-exponent-box:before {
  content: "北";
}

.mdi-export:before {
  content: "\f207";
}

.mdi-export-variant:before {
  content: "ﭯ";
}

.mdi-eye:before {
  content: "\f208";
}

.mdi-eye-check:before {
  content: "ﳠ";
}

.mdi-eye-check-outline:before {
  content: "ﳡ";
}

.mdi-eye-circle:before {
  content: "ﭰ";
}

.mdi-eye-circle-outline:before {
  content: "ﭱ";
}

.mdi-eye-off:before {
  content: "\f209";
}

.mdi-eye-off-outline:before {
  content: "\f6d0";
}

.mdi-eye-outline:before {
  content: "\f6cf";
}

.mdi-eye-plus:before {
  content: "\f86a";
}

.mdi-eye-plus-outline:before {
  content: "\f86b";
}

.mdi-eye-settings:before {
  content: "\f86c";
}

.mdi-eye-settings-outline:before {
  content: "\f86d";
}

.mdi-eyedropper:before {
  content: "\f20a";
}

.mdi-eyedropper-variant:before {
  content: "\f20b";
}

.mdi-face:before {
  content: "\f643";
}

.mdi-face-agent:before {
  content: "﵌";
}

.mdi-face-outline:before {
  content: "ﭲ";
}

.mdi-face-profile:before {
  content: "\f644";
}

.mdi-face-recognition:before {
  content: "ﱗ";
}

.mdi-facebook:before {
  content: "\f20c";
}

.mdi-facebook-box:before {
  content: "\f20d";
}

.mdi-facebook-messenger:before {
  content: "\f20e";
}

.mdi-facebook-workplace:before {
  content: "ﬖ";
}

.mdi-factory:before {
  content: "\f20f";
}

.mdi-fan:before {
  content: "\f210";
}

.mdi-fan-off:before {
  content: "\f81c";
}

.mdi-fast-forward:before {
  content: "\f211";
}

.mdi-fast-forward-10:before {
  content: "﵍";
}

.mdi-fast-forward-30:before {
  content: "ﳢ";
}

.mdi-fast-forward-outline:before {
  content: "\f6d1";
}

.mdi-fax:before {
  content: "\f212";
}

.mdi-feather:before {
  content: "\f6d2";
}

.mdi-feature-search:before {
  content: "煮";
}

.mdi-feature-search-outline:before {
  content: "爫";
}

.mdi-fedora:before {
  content: "\f8da";
}

.mdi-ferry:before {
  content: "\f213";
}

.mdi-file:before {
  content: "\f214";
}

.mdi-file-account:before {
  content: "\f73a";
}

.mdi-file-alert:before {
  content: "琢";
}

.mdi-file-alert-outline:before {
  content: "碑";
}

.mdi-file-cabinet:before {
  content: "蝹";
}

.mdi-file-cancel:before {
  content: "ﶢ";
}

.mdi-file-cancel-outline:before {
  content: "ﶣ";
}

.mdi-file-chart:before {
  content: "\f215";
}

.mdi-file-check:before {
  content: "\f216";
}

.mdi-file-cloud:before {
  content: "\f217";
}

.mdi-file-compare:before {
  content: "\f8a9";
}

.mdi-file-delimited:before {
  content: "\f218";
}

.mdi-file-document:before {
  content: "\f219";
}

.mdi-file-document-box:before {
  content: "\f21a";
}

.mdi-file-document-box-multiple:before {
  content: "襁";
}

.mdi-file-document-box-multiple-outline:before {
  content: "覆";
}

.mdi-file-document-box-outline:before {
  content: "溺";
}

.mdi-file-document-edit:before {
  content: "ﶤ";
}

.mdi-file-document-edit-outline:before {
  content: "ﶥ";
}

.mdi-file-document-outline:before {
  content: "吝";
}

.mdi-file-download:before {
  content: "磻";
}

.mdi-file-download-outline:before {
  content: "便";
}

.mdi-file-excel:before {
  content: "\f21b";
}

.mdi-file-excel-box:before {
  content: "\f21c";
}

.mdi-file-export:before {
  content: "\f21d";
}

.mdi-file-eye:before {
  content: "ﶦ";
}

.mdi-file-eye-outline:before {
  content: "ﶧ";
}

.mdi-file-find:before {
  content: "\f21e";
}

.mdi-file-find-outline:before {
  content: "ﭳ";
}

.mdi-file-hidden:before {
  content: "\f613";
}

.mdi-file-image:before {
  content: "\f21f";
}

.mdi-file-import:before {
  content: "\f220";
}

.mdi-file-lock:before {
  content: "\f221";
}

.mdi-file-move:before {
  content: "視";
}

.mdi-file-multiple:before {
  content: "\f222";
}

.mdi-file-music:before {
  content: "\f223";
}

.mdi-file-outline:before {
  content: "\f224";
}

.mdi-file-pdf:before {
  content: "\f225";
}

.mdi-file-pdf-box:before {
  content: "\f226";
}

.mdi-file-percent:before {
  content: "\f81d";
}

.mdi-file-plus:before {
  content: "\f751";
}

.mdi-file-powerpoint:before {
  content: "\f227";
}

.mdi-file-powerpoint-box:before {
  content: "\f228";
}

.mdi-file-presentation-box:before {
  content: "\f229";
}

.mdi-file-question:before {
  content: "\f86e";
}

.mdi-file-remove:before {
  content: "ﭴ";
}

.mdi-file-replace:before {
  content: "ﬗ";
}

.mdi-file-replace-outline:before {
  content: "﬘";
}

.mdi-file-restore:before {
  content: "\f670";
}

.mdi-file-search:before {
  content: "ﱘ";
}

.mdi-file-search-outline:before {
  content: "ﱙ";
}

.mdi-file-send:before {
  content: "\f22a";
}

.mdi-file-table:before {
  content: "ﱚ";
}

.mdi-file-table-outline:before {
  content: "ﱛ";
}

.mdi-file-tree:before {
  content: "\f645";
}

.mdi-file-undo:before {
  content: "\f8db";
}

.mdi-file-upload:before {
  content: "社";
}

.mdi-file-upload-outline:before {
  content: "祉";
}

.mdi-file-video:before {
  content: "\f22b";
}

.mdi-file-word:before {
  content: "\f22c";
}

.mdi-file-word-box:before {
  content: "\f22d";
}

.mdi-file-xml:before {
  content: "\f22e";
}

.mdi-film:before {
  content: "\f22f";
}

.mdi-filmstrip:before {
  content: "\f230";
}

.mdi-filmstrip-off:before {
  content: "\f231";
}

.mdi-filter:before {
  content: "\f232";
}

.mdi-filter-outline:before {
  content: "\f233";
}

.mdi-filter-remove:before {
  content: "\f234";
}

.mdi-filter-remove-outline:before {
  content: "\f235";
}

.mdi-filter-variant:before {
  content: "\f236";
}

.mdi-finance:before {
  content: "\f81e";
}

.mdi-find-replace:before {
  content: "\f6d3";
}

.mdi-fingerprint:before {
  content: "\f237";
}

.mdi-fire:before {
  content: "\f238";
}

.mdi-fire-truck:before {
  content: "\f8aa";
}

.mdi-firebase:before {
  content: "復";
}

.mdi-firefox:before {
  content: "\f239";
}

.mdi-fish:before {
  content: "\f23a";
}

.mdi-flag:before {
  content: "\f23b";
}

.mdi-flag-checkered:before {
  content: "\f23c";
}

.mdi-flag-minus:before {
  content: "ﭵ";
}

.mdi-flag-outline:before {
  content: "\f23d";
}

.mdi-flag-plus:before {
  content: "ﭶ";
}

.mdi-flag-remove:before {
  content: "ﭷ";
}

.mdi-flag-triangle:before {
  content: "\f23f";
}

.mdi-flag-variant:before {
  content: "\f240";
}

.mdi-flag-variant-outline:before {
  content: "\f23e";
}

.mdi-flare:before {
  content: "﵎";
}

.mdi-flash:before {
  content: "\f241";
}

.mdi-flash-auto:before {
  content: "\f242";
}

.mdi-flash-circle:before {
  content: "\f81f";
}

.mdi-flash-off:before {
  content: "\f243";
}

.mdi-flash-outline:before {
  content: "\f6d4";
}

.mdi-flash-red-eye:before {
  content: "\f67a";
}

.mdi-flashlight:before {
  content: "\f244";
}

.mdi-flashlight-off:before {
  content: "\f245";
}

.mdi-flask:before {
  content: "\f093";
}

.mdi-flask-empty:before {
  content: "\f094";
}

.mdi-flask-empty-outline:before {
  content: "\f095";
}

.mdi-flask-outline:before {
  content: "\f096";
}

.mdi-flattr:before {
  content: "\f246";
}

.mdi-flickr:before {
  content: "ﳣ";
}

.mdi-flip-to-back:before {
  content: "\f247";
}

.mdi-flip-to-front:before {
  content: "\f248";
}

.mdi-floor-lamp:before {
  content: "\f8dc";
}

.mdi-floor-plan:before {
  content: "\f820";
}

.mdi-floppy:before {
  content: "\f249";
}

.mdi-floppy-variant:before {
  content: "燐";
}

.mdi-flower:before {
  content: "\f24a";
}

.mdi-flower-outline:before {
  content: "璘";
}

.mdi-flower-poppy:before {
  content: "ﳤ";
}

.mdi-flower-tulip:before {
  content: "藺";
}

.mdi-flower-tulip-outline:before {
  content: "隣";
}

.mdi-folder:before {
  content: "\f24b";
}

.mdi-folder-account:before {
  content: "\f24c";
}

.mdi-folder-account-outline:before {
  content: "ﭸ";
}

.mdi-folder-alert:before {
  content: "ﶨ";
}

.mdi-folder-alert-outline:before {
  content: "ﶩ";
}

.mdi-folder-clock:before {
  content: "調";
}

.mdi-folder-clock-outline:before {
  content: "諸";
}

.mdi-folder-download:before {
  content: "\f24d";
}

.mdi-folder-edit:before {
  content: "\f8dd";
}

.mdi-folder-edit-outline:before {
  content: "ﶪ";
}

.mdi-folder-google-drive:before {
  content: "\f24e";
}

.mdi-folder-image:before {
  content: "\f24f";
}

.mdi-folder-key:before {
  content: "\f8ab";
}

.mdi-folder-key-network:before {
  content: "\f8ac";
}

.mdi-folder-key-network-outline:before {
  content: "ﱜ";
}

.mdi-folder-lock:before {
  content: "\f250";
}

.mdi-folder-lock-open:before {
  content: "\f251";
}

.mdi-folder-move:before {
  content: "\f252";
}

.mdi-folder-multiple:before {
  content: "\f253";
}

.mdi-folder-multiple-image:before {
  content: "\f254";
}

.mdi-folder-multiple-outline:before {
  content: "\f255";
}

.mdi-folder-network:before {
  content: "\f86f";
}

.mdi-folder-network-outline:before {
  content: "ﱝ";
}

.mdi-folder-open:before {
  content: "\f76f";
}

.mdi-folder-open-outline:before {
  content: "ﶫ";
}

.mdi-folder-outline:before {
  content: "\f256";
}

.mdi-folder-plus:before {
  content: "\f257";
}

.mdi-folder-plus-outline:before {
  content: "ﭹ";
}

.mdi-folder-pound:before {
  content: "ﳥ";
}

.mdi-folder-pound-outline:before {
  content: "ﳦ";
}

.mdi-folder-remove:before {
  content: "\f258";
}

.mdi-folder-remove-outline:before {
  content: "ﭺ";
}

.mdi-folder-search:before {
  content: "不";
}

.mdi-folder-search-outline:before {
  content: "泌";
}

.mdi-folder-star:before {
  content: "\f69c";
}

.mdi-folder-star-outline:before {
  content: "ﭻ";
}

.mdi-folder-sync:before {
  content: "ﳧ";
}

.mdi-folder-sync-outline:before {
  content: "ﳨ";
}

.mdi-folder-text:before {
  content: "ﱞ";
}

.mdi-folder-text-outline:before {
  content: "ﱟ";
}

.mdi-folder-upload:before {
  content: "\f259";
}

.mdi-font-awesome:before {
  content: "\f03a";
}

.mdi-food:before {
  content: "\f25a";
}

.mdi-food-apple:before {
  content: "\f25b";
}

.mdi-food-apple-outline:before {
  content: "ﱠ";
}

.mdi-food-croissant:before {
  content: "\f7c7";
}

.mdi-food-fork-drink:before {
  content: "\f5f2";
}

.mdi-food-off:before {
  content: "\f5f3";
}

.mdi-food-variant:before {
  content: "\f25c";
}

.mdi-football:before {
  content: "\f25d";
}

.mdi-football-australian:before {
  content: "\f25e";
}

.mdi-football-helmet:before {
  content: "\f25f";
}

.mdi-forklift:before {
  content: "\f7c8";
}

.mdi-format-align-bottom:before {
  content: "\f752";
}

.mdi-format-align-center:before {
  content: "\f260";
}

.mdi-format-align-justify:before {
  content: "\f261";
}

.mdi-format-align-left:before {
  content: "\f262";
}

.mdi-format-align-middle:before {
  content: "\f753";
}

.mdi-format-align-right:before {
  content: "\f263";
}

.mdi-format-align-top:before {
  content: "\f754";
}

.mdi-format-annotation-minus:before {
  content: "請";
}

.mdi-format-annotation-plus:before {
  content: "\f646";
}

.mdi-format-bold:before {
  content: "\f264";
}

.mdi-format-clear:before {
  content: "\f265";
}

.mdi-format-color-fill:before {
  content: "\f266";
}

.mdi-format-color-text:before {
  content: "\f69d";
}

.mdi-format-columns:before {
  content: "\f8de";
}

.mdi-format-float-center:before {
  content: "\f267";
}

.mdi-format-float-left:before {
  content: "\f268";
}

.mdi-format-float-none:before {
  content: "\f269";
}

.mdi-format-float-right:before {
  content: "\f26a";
}

.mdi-format-font:before {
  content: "\f6d5";
}

.mdi-format-font-size-decrease:before {
  content: "鱗";
}

.mdi-format-font-size-increase:before {
  content: "麟";
}

.mdi-format-header-1:before {
  content: "\f26b";
}

.mdi-format-header-2:before {
  content: "\f26c";
}

.mdi-format-header-3:before {
  content: "\f26d";
}

.mdi-format-header-4:before {
  content: "\f26e";
}

.mdi-format-header-5:before {
  content: "\f26f";
}

.mdi-format-header-6:before {
  content: "\f270";
}

.mdi-format-header-decrease:before {
  content: "\f271";
}

.mdi-format-header-equal:before {
  content: "\f272";
}

.mdi-format-header-increase:before {
  content: "\f273";
}

.mdi-format-header-pound:before {
  content: "\f274";
}

.mdi-format-horizontal-align-center:before {
  content: "\f61e";
}

.mdi-format-horizontal-align-left:before {
  content: "\f61f";
}

.mdi-format-horizontal-align-right:before {
  content: "\f620";
}

.mdi-format-indent-decrease:before {
  content: "\f275";
}

.mdi-format-indent-increase:before {
  content: "\f276";
}

.mdi-format-italic:before {
  content: "\f277";
}

.mdi-format-letter-case:before {
  content: "﬙";
}

.mdi-format-letter-case-lower:before {
  content: "﬚";
}

.mdi-format-letter-case-upper:before {
  content: "﬛";
}

.mdi-format-line-spacing:before {
  content: "\f278";
}

.mdi-format-line-style:before {
  content: "\f5c8";
}

.mdi-format-line-weight:before {
  content: "\f5c9";
}

.mdi-format-list-bulleted:before {
  content: "\f279";
}

.mdi-format-list-bulleted-square:before {
  content: "ﶬ";
}

.mdi-format-list-bulleted-type:before {
  content: "\f27a";
}

.mdi-format-list-checkbox:before {
  content: "數";
}

.mdi-format-list-checks:before {
  content: "\f755";
}

.mdi-format-list-numbered:before {
  content: "\f27b";
}

.mdi-format-list-numbered-rtl:before {
  content: "ﳩ";
}

.mdi-format-page-break:before {
  content: "\f6d6";
}

.mdi-format-paint:before {
  content: "\f27c";
}

.mdi-format-paragraph:before {
  content: "\f27d";
}

.mdi-format-pilcrow:before {
  content: "\f6d7";
}

.mdi-format-quote-close:before {
  content: "\f27e";
}

.mdi-format-quote-open:before {
  content: "\f756";
}

.mdi-format-rotate-90:before {
  content: "\f6a9";
}

.mdi-format-section:before {
  content: "\f69e";
}

.mdi-format-size:before {
  content: "\f27f";
}

.mdi-format-strikethrough:before {
  content: "\f280";
}

.mdi-format-strikethrough-variant:before {
  content: "\f281";
}

.mdi-format-subscript:before {
  content: "\f282";
}

.mdi-format-superscript:before {
  content: "\f283";
}

.mdi-format-text:before {
  content: "\f284";
}

.mdi-format-text-rotation-down:before {
  content: "﵏";
}

.mdi-format-text-rotation-none:before {
  content: "ﵐ";
}

.mdi-format-text-wrapping-clip:before {
  content: "ﳪ";
}

.mdi-format-text-wrapping-overflow:before {
  content: "ﳫ";
}

.mdi-format-text-wrapping-wrap:before {
  content: "ﳬ";
}

.mdi-format-textbox:before {
  content: "ﳭ";
}

.mdi-format-textdirection-l-to-r:before {
  content: "\f285";
}

.mdi-format-textdirection-r-to-l:before {
  content: "\f286";
}

.mdi-format-title:before {
  content: "\f5f4";
}

.mdi-format-underline:before {
  content: "\f287";
}

.mdi-format-vertical-align-bottom:before {
  content: "\f621";
}

.mdi-format-vertical-align-center:before {
  content: "\f622";
}

.mdi-format-vertical-align-top:before {
  content: "\f623";
}

.mdi-format-wrap-inline:before {
  content: "\f288";
}

.mdi-format-wrap-square:before {
  content: "\f289";
}

.mdi-format-wrap-tight:before {
  content: "\f28a";
}

.mdi-format-wrap-top-bottom:before {
  content: "\f28b";
}

.mdi-forum:before {
  content: "\f28c";
}

.mdi-forum-outline:before {
  content: "\f821";
}

.mdi-forward:before {
  content: "\f28d";
}

.mdi-forwardburger:before {
  content: "ﵑ";
}

.mdi-fountain:before {
  content: "索";
}

.mdi-fountain-pen:before {
  content: "ﳮ";
}

.mdi-fountain-pen-tip:before {
  content: "ﳯ";
}

.mdi-foursquare:before {
  content: "\f28e";
}

.mdi-freebsd:before {
  content: "\f8df";
}

.mdi-fridge:before {
  content: "\f290";
}

.mdi-fridge-bottom:before {
  content: "\f292";
}

.mdi-fridge-outline:before {
  content: "\f28f";
}

.mdi-fridge-top:before {
  content: "\f291";
}

.mdi-fuel:before {
  content: "\f7c9";
}

.mdi-fullscreen:before {
  content: "\f293";
}

.mdi-fullscreen-exit:before {
  content: "\f294";
}

.mdi-function:before {
  content: "\f295";
}

.mdi-function-variant:before {
  content: "\f870";
}

.mdi-fuse:before {
  content: "ﱡ";
}

.mdi-fuse-blade:before {
  content: "ﱢ";
}

.mdi-gamepad:before {
  content: "\f296";
}

.mdi-gamepad-variant:before {
  content: "\f297";
}

.mdi-gantry-crane:before {
  content: "ﶭ";
}

.mdi-garage:before {
  content: "\f6d8";
}

.mdi-garage-alert:before {
  content: "\f871";
}

.mdi-garage-open:before {
  content: "\f6d9";
}

.mdi-gas-cylinder:before {
  content: "\f647";
}

.mdi-gas-station:before {
  content: "\f298";
}

.mdi-gate:before {
  content: "\f299";
}

.mdi-gate-and:before {
  content: "\f8e0";
}

.mdi-gate-nand:before {
  content: "\f8e1";
}

.mdi-gate-nor:before {
  content: "\f8e2";
}

.mdi-gate-not:before {
  content: "\f8e3";
}

.mdi-gate-or:before {
  content: "\f8e4";
}

.mdi-gate-xnor:before {
  content: "\f8e5";
}

.mdi-gate-xor:before {
  content: "\f8e6";
}

.mdi-gauge:before {
  content: "\f29a";
}

.mdi-gauge-empty:before {
  content: "\f872";
}

.mdi-gauge-full:before {
  content: "\f873";
}

.mdi-gauge-low:before {
  content: "\f874";
}

.mdi-gavel:before {
  content: "\f29b";
}

.mdi-gender-female:before {
  content: "\f29c";
}

.mdi-gender-male:before {
  content: "\f29d";
}

.mdi-gender-male-female:before {
  content: "\f29e";
}

.mdi-gender-transgender:before {
  content: "\f29f";
}

.mdi-gentoo:before {
  content: "\f8e7";
}

.mdi-gesture:before {
  content: "\f7ca";
}

.mdi-gesture-double-tap:before {
  content: "\f73b";
}

.mdi-gesture-pinch:before {
  content: "謁";
}

.mdi-gesture-spread:before {
  content: "諾";
}

.mdi-gesture-swipe:before {
  content: "ﵒ";
}

.mdi-gesture-swipe-down:before {
  content: "\f73c";
}

.mdi-gesture-swipe-horizontal:before {
  content: "諭";
}

.mdi-gesture-swipe-left:before {
  content: "\f73d";
}

.mdi-gesture-swipe-right:before {
  content: "\f73e";
}

.mdi-gesture-swipe-up:before {
  content: "\f73f";
}

.mdi-gesture-swipe-vertical:before {
  content: "謹";
}

.mdi-gesture-tap:before {
  content: "\f740";
}

.mdi-gesture-tap-hold:before {
  content: "ﵓ";
}

.mdi-gesture-two-double-tap:before {
  content: "\f741";
}

.mdi-gesture-two-tap:before {
  content: "\f742";
}

.mdi-ghost:before {
  content: "\f2a0";
}

.mdi-ghost-off:before {
  content: "林";
}

.mdi-gif:before {
  content: "ﵔ";
}

.mdi-gift:before {
  content: "\f2a1";
}

.mdi-git:before {
  content: "\f2a2";
}

.mdi-github-box:before {
  content: "\f2a3";
}

.mdi-github-circle:before {
  content: "\f2a4";
}

.mdi-github-face:before {
  content: "\f6da";
}

.mdi-gitlab:before {
  content: "ﭼ";
}

.mdi-glass-cocktail:before {
  content: "\f356";
}

.mdi-glass-flute:before {
  content: "\f2a5";
}

.mdi-glass-mug:before {
  content: "\f2a6";
}

.mdi-glass-stange:before {
  content: "\f2a7";
}

.mdi-glass-tulip:before {
  content: "\f2a8";
}

.mdi-glass-wine:before {
  content: "\f875";
}

.mdi-glassdoor:before {
  content: "\f2a9";
}

.mdi-glasses:before {
  content: "\f2aa";
}

.mdi-globe-model:before {
  content: "\f8e8";
}

.mdi-gmail:before {
  content: "\f2ab";
}

.mdi-gnome:before {
  content: "\f2ac";
}

.mdi-go-kart:before {
  content: "ﵕ";
}

.mdi-go-kart-track:before {
  content: "ﵖ";
}

.mdi-gog:before {
  content: "ﭽ";
}

.mdi-golf:before {
  content: "\f822";
}

.mdi-gondola:before {
  content: "\f685";
}

.mdi-goodreads:before {
  content: "ﵗ";
}

.mdi-google:before {
  content: "\f2ad";
}

.mdi-google-adwords:before {
  content: "ﱣ";
}

.mdi-google-allo:before {
  content: "\f801";
}

.mdi-google-analytics:before {
  content: "\f7cb";
}

.mdi-google-assistant:before {
  content: "\f7cc";
}

.mdi-google-cardboard:before {
  content: "\f2ae";
}

.mdi-google-chrome:before {
  content: "\f2af";
}

.mdi-google-circles:before {
  content: "\f2b0";
}

.mdi-google-circles-communities:before {
  content: "\f2b1";
}

.mdi-google-circles-extended:before {
  content: "\f2b2";
}

.mdi-google-circles-group:before {
  content: "\f2b3";
}

.mdi-google-classroom:before {
  content: "\f2c0";
}

.mdi-google-controller:before {
  content: "\f2b4";
}

.mdi-google-controller-off:before {
  content: "\f2b5";
}

.mdi-google-drive:before {
  content: "\f2b6";
}

.mdi-google-earth:before {
  content: "\f2b7";
}

.mdi-google-fit:before {
  content: "參";
}

.mdi-google-glass:before {
  content: "\f2b8";
}

.mdi-google-hangouts:before {
  content: "\f2c9";
}

.mdi-google-home:before {
  content: "\f823";
}

.mdi-google-keep:before {
  content: "\f6db";
}

.mdi-google-lens:before {
  content: "淋";
}

.mdi-google-maps:before {
  content: "\f5f5";
}

.mdi-google-nearby:before {
  content: "\f2b9";
}

.mdi-google-pages:before {
  content: "\f2ba";
}

.mdi-google-photos:before {
  content: "\f6dc";
}

.mdi-google-physical-web:before {
  content: "\f2bb";
}

.mdi-google-play:before {
  content: "\f2bc";
}

.mdi-google-plus:before {
  content: "\f2bd";
}

.mdi-google-plus-box:before {
  content: "\f2be";
}

.mdi-google-spreadsheet:before {
  content: "臨";
}

.mdi-google-street-view:before {
  content: "ﱤ";
}

.mdi-google-translate:before {
  content: "\f2bf";
}

.mdi-gpu:before {
  content: "\f8ad";
}

.mdi-gradient:before {
  content: "\f69f";
}

.mdi-grain:before {
  content: "ﵘ";
}

.mdi-graphql:before {
  content: "\f876";
}

.mdi-grave-stone:before {
  content: "ﭾ";
}

.mdi-grease-pencil:before {
  content: "\f648";
}

.mdi-greater-than:before {
  content: "塞";
}

.mdi-greater-than-or-equal:before {
  content: "省";
}

.mdi-grid:before {
  content: "\f2c1";
}

.mdi-grid-large:before {
  content: "\f757";
}

.mdi-grid-off:before {
  content: "\f2c2";
}

.mdi-group:before {
  content: "\f2c3";
}

.mdi-guitar-acoustic:before {
  content: "\f770";
}

.mdi-guitar-electric:before {
  content: "\f2c4";
}

.mdi-guitar-pick:before {
  content: "\f2c5";
}

.mdi-guitar-pick-outline:before {
  content: "\f2c6";
}

.mdi-guy-fawkes-mask:before {
  content: "\f824";
}

.mdi-hackernews:before {
  content: "\f624";
}

.mdi-hail:before {
  content: "變";
}

.mdi-halloween:before {
  content: "ﭿ";
}

.mdi-hamburger:before {
  content: "\f684";
}

.mdi-hammer:before {
  content: "\f8e9";
}

.mdi-hand:before {
  content: "祈";
}

.mdi-hand-okay:before {
  content: "祐";
}

.mdi-hand-peace:before {
  content: "祖";
}

.mdi-hand-peace-variant:before {
  content: "祝";
}

.mdi-hand-pointing-down:before {
  content: "禍";
}

.mdi-hand-pointing-left:before {
  content: "禎";
}

.mdi-hand-pointing-right:before {
  content: "\f2c7";
}

.mdi-hand-pointing-up:before {
  content: "穀";
}

.mdi-hanger:before {
  content: "\f2c8";
}

.mdi-hard-hat:before {
  content: "葉";
}

.mdi-harddisk:before {
  content: "\f2ca";
}

.mdi-hat-fedora:before {
  content: "ﮀ";
}

.mdi-hazard-lights:before {
  content: "ﱥ";
}

.mdi-hdr:before {
  content: "ﵙ";
}

.mdi-hdr-off:before {
  content: "ﵚ";
}

.mdi-headphones:before {
  content: "\f2cb";
}

.mdi-headphones-bluetooth:before {
  content: "說";
}

.mdi-headphones-box:before {
  content: "\f2cc";
}

.mdi-headphones-off:before {
  content: "\f7cd";
}

.mdi-headphones-settings:before {
  content: "\f2cd";
}

.mdi-headset:before {
  content: "\f2ce";
}

.mdi-headset-dock:before {
  content: "\f2cf";
}

.mdi-headset-off:before {
  content: "\f2d0";
}

.mdi-heart:before {
  content: "\f2d1";
}

.mdi-heart-box:before {
  content: "\f2d2";
}

.mdi-heart-box-outline:before {
  content: "\f2d3";
}

.mdi-heart-broken:before {
  content: "\f2d4";
}

.mdi-heart-broken-outline:before {
  content: "ﳰ";
}

.mdi-heart-circle:before {
  content: "殺";
}

.mdi-heart-circle-outline:before {
  content: "辰";
}

.mdi-heart-half:before {
  content: "\f6de";
}

.mdi-heart-half-full:before {
  content: "\f6dd";
}

.mdi-heart-half-outline:before {
  content: "\f6df";
}

.mdi-heart-multiple:before {
  content: "突";
}

.mdi-heart-multiple-outline:before {
  content: "節";
}

.mdi-heart-off:before {
  content: "\f758";
}

.mdi-heart-outline:before {
  content: "\f2d5";
}

.mdi-heart-pulse:before {
  content: "\f5f6";
}

.mdi-helicopter:before {
  content: "贈";
}

.mdi-help:before {
  content: "\f2d6";
}

.mdi-help-box:before {
  content: "\f78a";
}

.mdi-help-circle:before {
  content: "\f2d7";
}

.mdi-help-circle-outline:before {
  content: "\f625";
}

.mdi-help-network:before {
  content: "\f6f4";
}

.mdi-help-network-outline:before {
  content: "ﱦ";
}

.mdi-help-rhombus:before {
  content: "ﮁ";
}

.mdi-help-rhombus-outline:before {
  content: "ﮂ";
}

.mdi-hexagon:before {
  content: "\f2d8";
}

.mdi-hexagon-multiple:before {
  content: "\f6e0";
}

.mdi-hexagon-outline:before {
  content: "\f2d9";
}

.mdi-hexagon-slice-1:before {
  content: "輸";
}

.mdi-hexagon-slice-2:before {
  content: "遲";
}

.mdi-hexagon-slice-3:before {
  content: "醙";
}

.mdi-hexagon-slice-4:before {
  content: "鉶";
}

.mdi-hexagon-slice-5:before {
  content: "陼";
}

.mdi-hexagon-slice-6:before {
  content: "難";
}

.mdi-hexagram:before {
  content: "靖";
}

.mdi-hexagram-outline:before {
  content: "韛";
}

.mdi-high-definition:before {
  content: "\f7ce";
}

.mdi-high-definition-box:before {
  content: "\f877";
}

.mdi-highway:before {
  content: "\f5f7";
}

.mdi-hiking:before {
  content: "ﵛ";
}

.mdi-hinduism:before {
  content: "沈";
}

.mdi-history:before {
  content: "\f2da";
}

.mdi-hockey-puck:before {
  content: "\f878";
}

.mdi-hockey-sticks:before {
  content: "\f879";
}

.mdi-hololens:before {
  content: "\f2db";
}

.mdi-home:before {
  content: "\f2dc";
}

.mdi-home-account:before {
  content: "\f825";
}

.mdi-home-alert:before {
  content: "\f87a";
}

.mdi-home-assistant:before {
  content: "\f7cf";
}

.mdi-home-automation:before {
  content: "\f7d0";
}

.mdi-home-circle:before {
  content: "\f7d1";
}

.mdi-home-city:before {
  content: "ﳱ";
}

.mdi-home-city-outline:before {
  content: "ﳲ";
}

.mdi-home-currency-usd:before {
  content: "\f8ae";
}

.mdi-home-floor-0:before {
  content: "ﶮ";
}

.mdi-home-floor-1:before {
  content: "ﵜ";
}

.mdi-home-floor-2:before {
  content: "ﵝ";
}

.mdi-home-floor-3:before {
  content: "ﵞ";
}

.mdi-home-floor-a:before {
  content: "ﵟ";
}

.mdi-home-floor-b:before {
  content: "ﵠ";
}

.mdi-home-floor-g:before {
  content: "ﵡ";
}

.mdi-home-floor-l:before {
  content: "ﵢ";
}

.mdi-home-floor-negative-1:before {
  content: "ﶯ";
}

.mdi-home-group:before {
  content: "ﶰ";
}

.mdi-home-heart:before {
  content: "\f826";
}

.mdi-home-lock:before {
  content: "\f8ea";
}

.mdi-home-lock-open:before {
  content: "\f8eb";
}

.mdi-home-map-marker:before {
  content: "\f5f8";
}

.mdi-home-minus:before {
  content: "拾";
}

.mdi-home-modern:before {
  content: "\f2dd";
}

.mdi-home-outline:before {
  content: "\f6a0";
}

.mdi-home-plus:before {
  content: "若";
}

.mdi-home-variant:before {
  content: "\f2de";
}

.mdi-home-variant-outline:before {
  content: "ﮃ";
}

.mdi-hook:before {
  content: "\f6e1";
}

.mdi-hook-off:before {
  content: "\f6e2";
}

.mdi-hops:before {
  content: "\f2df";
}

.mdi-horseshoe:before {
  content: "練";
}

.mdi-hospital:before {
  content: "\f2e0";
}

.mdi-hospital-building:before {
  content: "\f2e1";
}

.mdi-hospital-marker:before {
  content: "\f2e2";
}

.mdi-hot-tub:before {
  content: "\f827";
}

.mdi-hotel:before {
  content: "\f2e3";
}

.mdi-houzz:before {
  content: "\f2e4";
}

.mdi-houzz-box:before {
  content: "\f2e5";
}

.mdi-hubspot:before {
  content: "ﳳ";
}

.mdi-hulu:before {
  content: "\f828";
}

.mdi-human:before {
  content: "\f2e6";
}

.mdi-human-child:before {
  content: "\f2e7";
}

.mdi-human-female:before {
  content: "\f649";
}

.mdi-human-female-boy:before {
  content: "縉";
}

.mdi-human-female-female:before {
  content: "繁";
}

.mdi-human-female-girl:before {
  content: "署";
}

.mdi-human-greeting:before {
  content: "\f64a";
}

.mdi-human-handsdown:before {
  content: "\f64b";
}

.mdi-human-handsup:before {
  content: "\f64c";
}

.mdi-human-male:before {
  content: "\f64d";
}

.mdi-human-male-boy:before {
  content: "者";
}

.mdi-human-male-female:before {
  content: "\f2e8";
}

.mdi-human-male-girl:before {
  content: "臭";
}

.mdi-human-male-male:before {
  content: "艹";
}

.mdi-human-pregnant:before {
  content: "\f5cf";
}

.mdi-humble-bundle:before {
  content: "\f743";
}

.mdi-ice-cream:before {
  content: "\f829";
}

.mdi-iframe:before {
  content: "ﱧ";
}

.mdi-iframe-outline:before {
  content: "ﱨ";
}

.mdi-image:before {
  content: "\f2e9";
}

.mdi-image-album:before {
  content: "\f2ea";
}

.mdi-image-area:before {
  content: "\f2eb";
}

.mdi-image-area-close:before {
  content: "\f2ec";
}

.mdi-image-broken:before {
  content: "\f2ed";
}

.mdi-image-broken-variant:before {
  content: "\f2ee";
}

.mdi-image-filter:before {
  content: "\f2ef";
}

.mdi-image-filter-black-white:before {
  content: "\f2f0";
}

.mdi-image-filter-center-focus:before {
  content: "\f2f1";
}

.mdi-image-filter-center-focus-weak:before {
  content: "\f2f2";
}

.mdi-image-filter-drama:before {
  content: "\f2f3";
}

.mdi-image-filter-frames:before {
  content: "\f2f4";
}

.mdi-image-filter-hdr:before {
  content: "\f2f5";
}

.mdi-image-filter-none:before {
  content: "\f2f6";
}

.mdi-image-filter-tilt-shift:before {
  content: "\f2f7";
}

.mdi-image-filter-vintage:before {
  content: "\f2f8";
}

.mdi-image-move:before {
  content: "立";
}

.mdi-image-multiple:before {
  content: "\f2f9";
}

.mdi-image-off:before {
  content: "\f82a";
}

.mdi-image-outline:before {
  content: "掠";
}

.mdi-image-plus:before {
  content: "\f87b";
}

.mdi-image-search:before {
  content: "略";
}

.mdi-image-search-outline:before {
  content: "亮";
}

.mdi-image-size-select-actual:before {
  content: "ﱩ";
}

.mdi-image-size-select-large:before {
  content: "ﱪ";
}

.mdi-image-size-select-small:before {
  content: "ﱫ";
}

.mdi-import:before {
  content: "\f2fa";
}

.mdi-inbox:before {
  content: "\f686";
}

.mdi-inbox-arrow-down:before {
  content: "\f2fb";
}

.mdi-inbox-arrow-up:before {
  content: "\f3d1";
}

.mdi-inbox-multiple:before {
  content: "\f8af";
}

.mdi-inbox-multiple-outline:before {
  content: "ﮄ";
}

.mdi-incognito:before {
  content: "\f5f9";
}

.mdi-infinity:before {
  content: "\f6e3";
}

.mdi-information:before {
  content: "\f2fc";
}

.mdi-information-outline:before {
  content: "\f2fd";
}

.mdi-information-variant:before {
  content: "\f64e";
}

.mdi-instagram:before {
  content: "\f2fe";
}

.mdi-instapaper:before {
  content: "\f2ff";
}

.mdi-internet-explorer:before {
  content: "\f300";
}

.mdi-invert-colors:before {
  content: "\f301";
}

.mdi-ip:before {
  content: "艹";
}

.mdi-ip-network:before {
  content: "著";
}

.mdi-ip-network-outline:before {
  content: "ﱬ";
}

.mdi-ipod:before {
  content: "ﱭ";
}

.mdi-islam:before {
  content: "兩";
}

.mdi-itunes:before {
  content: "\f676";
}

.mdi-jabber:before {
  content: "ﶱ";
}

.mdi-jeepney:before {
  content: "\f302";
}

.mdi-jira:before {
  content: "\f303";
}

.mdi-jquery:before {
  content: "\f87c";
}

.mdi-jsfiddle:before {
  content: "\f304";
}

.mdi-json:before {
  content: "\f626";
}

.mdi-judaism:before {
  content: "凉";
}

.mdi-kabaddi:before {
  content: "ﵣ";
}

.mdi-karate:before {
  content: "\f82b";
}

.mdi-keg:before {
  content: "\f305";
}

.mdi-kettle:before {
  content: "\f5fa";
}

.mdi-key:before {
  content: "\f306";
}

.mdi-key-change:before {
  content: "\f307";
}

.mdi-key-minus:before {
  content: "\f308";
}

.mdi-key-outline:before {
  content: "ﶲ";
}

.mdi-key-plus:before {
  content: "\f309";
}

.mdi-key-remove:before {
  content: "\f30a";
}

.mdi-key-variant:before {
  content: "\f30b";
}

.mdi-keyboard:before {
  content: "\f30c";
}

.mdi-keyboard-backspace:before {
  content: "\f30d";
}

.mdi-keyboard-caps:before {
  content: "\f30e";
}

.mdi-keyboard-close:before {
  content: "\f30f";
}

.mdi-keyboard-off:before {
  content: "\f310";
}

.mdi-keyboard-outline:before {
  content: "梁";
}

.mdi-keyboard-return:before {
  content: "\f311";
}

.mdi-keyboard-settings:before {
  content: "笠";
}

.mdi-keyboard-settings-outline:before {
  content: "粒";
}

.mdi-keyboard-tab:before {
  content: "\f312";
}

.mdi-keyboard-variant:before {
  content: "\f313";
}

.mdi-kickstarter:before {
  content: "\f744";
}

.mdi-knife:before {
  content: "狀";
}

.mdi-knife-military:before {
  content: "炙";
}

.mdi-kodi:before {
  content: "\f314";
}

.mdi-label:before {
  content: "\f315";
}

.mdi-label-off:before {
  content: "響";
}

.mdi-label-off-outline:before {
  content: "頋";
}

.mdi-label-outline:before {
  content: "\f316";
}

.mdi-label-variant:before {
  content: "頻";
}

.mdi-label-variant-outline:before {
  content: "鬒";
}

.mdi-ladybug:before {
  content: "\f82c";
}

.mdi-lambda:before {
  content: "\f627";
}

.mdi-lamp:before {
  content: "\f6b4";
}

.mdi-lan:before {
  content: "\f317";
}

.mdi-lan-connect:before {
  content: "\f318";
}

.mdi-lan-disconnect:before {
  content: "\f319";
}

.mdi-lan-pending:before {
  content: "\f31a";
}

.mdi-language-c:before {
  content: "\f671";
}

.mdi-language-cpp:before {
  content: "\f672";
}

.mdi-language-csharp:before {
  content: "\f31b";
}

.mdi-language-css3:before {
  content: "\f31c";
}

.mdi-language-go:before {
  content: "\f7d2";
}

.mdi-language-haskell:before {
  content: "ﱮ";
}

.mdi-language-html5:before {
  content: "\f31d";
}

.mdi-language-java:before {
  content: "﬜";
}

.mdi-language-javascript:before {
  content: "\f31e";
}

.mdi-language-lua:before {
  content: "\f8b0";
}

.mdi-language-php:before {
  content: "\f31f";
}

.mdi-language-python:before {
  content: "\f320";
}

.mdi-language-python-text:before {
  content: "\f321";
}

.mdi-language-r:before {
  content: "\f7d3";
}

.mdi-language-ruby-on-rails:before {
  content: "龜";
}

.mdi-language-swift:before {
  content: "\f6e4";
}

.mdi-language-typescript:before {
  content: "\f6e5";
}

.mdi-laptop:before {
  content: "\f322";
}

.mdi-laptop-chromebook:before {
  content: "\f323";
}

.mdi-laptop-mac:before {
  content: "\f324";
}

.mdi-laptop-off:before {
  content: "\f6e6";
}

.mdi-laptop-windows:before {
  content: "\f325";
}

.mdi-laravel:before {
  content: "𢡊";
}

.mdi-lastfm:before {
  content: "\f326";
}

.mdi-lastpass:before {
  content: "\f446";
}

.mdi-launch:before {
  content: "\f327";
}

.mdi-lava-lamp:before {
  content: "\f7d4";
}

.mdi-layers:before {
  content: "\f328";
}

.mdi-layers-off:before {
  content: "\f329";
}

.mdi-layers-off-outline:before {
  content: "識";
}

.mdi-layers-outline:before {
  content: "什";
}

.mdi-lead-pencil:before {
  content: "\f64f";
}

.mdi-leaf:before {
  content: "\f32a";
}

.mdi-leaf-maple:before {
  content: "ﱯ";
}

.mdi-leak:before {
  content: "ﶳ";
}

.mdi-leak-off:before {
  content: "ﶴ";
}

.mdi-led-off:before {
  content: "\f32b";
}

.mdi-led-on:before {
  content: "\f32c";
}

.mdi-led-outline:before {
  content: "\f32d";
}

.mdi-led-strip:before {
  content: "\f7d5";
}

.mdi-led-variant-off:before {
  content: "\f32e";
}

.mdi-led-variant-on:before {
  content: "\f32f";
}

.mdi-led-variant-outline:before {
  content: "\f330";
}

.mdi-less-than:before {
  content: "糧";
}

.mdi-less-than-or-equal:before {
  content: "良";
}

.mdi-library:before {
  content: "\f331";
}

.mdi-library-books:before {
  content: "\f332";
}

.mdi-library-movie:before {
  content: "ﳴ";
}

.mdi-library-music:before {
  content: "\f333";
}

.mdi-library-plus:before {
  content: "\f334";
}

.mdi-library-shelves:before {
  content: "ﮅ";
}

.mdi-library-video:before {
  content: "ﳵ";
}

.mdi-lifebuoy:before {
  content: "\f87d";
}

.mdi-light-switch:before {
  content: "諒";
}

.mdi-lightbulb:before {
  content: "\f335";
}

.mdi-lightbulb-on:before {
  content: "\f6e7";
}

.mdi-lightbulb-on-outline:before {
  content: "\f6e8";
}

.mdi-lightbulb-outline:before {
  content: "\f336";
}

.mdi-lighthouse:before {
  content: "茶";
}

.mdi-lighthouse-on:before {
  content: "刺";
}

.mdi-link:before {
  content: "\f337";
}

.mdi-link-box:before {
  content: "ﳶ";
}

.mdi-link-box-outline:before {
  content: "ﳷ";
}

.mdi-link-box-variant:before {
  content: "ﳸ";
}

.mdi-link-box-variant-outline:before {
  content: "ﳹ";
}

.mdi-link-off:before {
  content: "\f338";
}

.mdi-link-plus:before {
  content: "ﱰ";
}

.mdi-link-variant:before {
  content: "\f339";
}

.mdi-link-variant-off:before {
  content: "\f33a";
}

.mdi-linkedin:before {
  content: "\f33b";
}

.mdi-linkedin-box:before {
  content: "\f33c";
}

.mdi-linux:before {
  content: "\f33d";
}

.mdi-linux-mint:before {
  content: "\f8ec";
}

.mdi-litecoin:before {
  content: "褐";
}

.mdi-loading:before {
  content: "\f771";
}

.mdi-lock:before {
  content: "\f33e";
}

.mdi-lock-alert:before {
  content: "\f8ed";
}

.mdi-lock-clock:before {
  content: "量";
}

.mdi-lock-open:before {
  content: "\f33f";
}

.mdi-lock-open-outline:before {
  content: "\f340";
}

.mdi-lock-outline:before {
  content: "\f341";
}

.mdi-lock-pattern:before {
  content: "\f6e9";
}

.mdi-lock-plus:before {
  content: "\f5fb";
}

.mdi-lock-question:before {
  content: "\f8ee";
}

.mdi-lock-reset:before {
  content: "\f772";
}

.mdi-lock-smart:before {
  content: "\f8b1";
}

.mdi-locker:before {
  content: "\f7d6";
}

.mdi-locker-multiple:before {
  content: "\f7d7";
}

.mdi-login:before {
  content: "\f342";
}

.mdi-login-variant:before {
  content: "\f5fc";
}

.mdi-logout:before {
  content: "\f343";
}

.mdi-logout-variant:before {
  content: "\f5fd";
}

.mdi-looks:before {
  content: "\f344";
}

.mdi-loop:before {
  content: "\f6ea";
}

.mdi-loupe:before {
  content: "\f345";
}

.mdi-lumx:before {
  content: "\f346";
}

.mdi-lyft:before {
  content: "יִ";
}

.mdi-magnet:before {
  content: "\f347";
}

.mdi-magnet-on:before {
  content: "\f348";
}

.mdi-magnify:before {
  content: "\f349";
}

.mdi-magnify-close:before {
  content: "勵";
}

.mdi-magnify-minus:before {
  content: "\f34a";
}

.mdi-magnify-minus-cursor:before {
  content: "視";
}

.mdi-magnify-minus-outline:before {
  content: "\f6eb";
}

.mdi-magnify-plus:before {
  content: "\f34b";
}

.mdi-magnify-plus-cursor:before {
  content: "謁";
}

.mdi-magnify-plus-outline:before {
  content: "\f6ec";
}

.mdi-mail-ru:before {
  content: "\f34c";
}

.mdi-mailbox:before {
  content: "\f6ed";
}

.mdi-mailbox-open:before {
  content: "ﵤ";
}

.mdi-mailbox-open-outline:before {
  content: "ﵥ";
}

.mdi-mailbox-open-up:before {
  content: "ﵦ";
}

.mdi-mailbox-open-up-outline:before {
  content: "ﵧ";
}

.mdi-mailbox-outline:before {
  content: "ﵨ";
}

.mdi-mailbox-up:before {
  content: "ﵩ";
}

.mdi-mailbox-up-outline:before {
  content: "ﵪ";
}

.mdi-map:before {
  content: "\f34d";
}

.mdi-map-clock:before {
  content: "ﳺ";
}

.mdi-map-clock-outline:before {
  content: "ﳻ";
}

.mdi-map-legend:before {
  content: "切";
}

.mdi-map-marker:before {
  content: "\f34e";
}

.mdi-map-marker-check:before {
  content: "ﱱ";
}

.mdi-map-marker-circle:before {
  content: "\f34f";
}

.mdi-map-marker-distance:before {
  content: "\f8ef";
}

.mdi-map-marker-minus:before {
  content: "\f650";
}

.mdi-map-marker-multiple:before {
  content: "\f350";
}

.mdi-map-marker-off:before {
  content: "\f351";
}

.mdi-map-marker-outline:before {
  content: "\f7d8";
}

.mdi-map-marker-path:before {
  content: "ﳼ";
}

.mdi-map-marker-plus:before {
  content: "\f651";
}

.mdi-map-marker-radius:before {
  content: "\f352";
}

.mdi-map-minus:before {
  content: "呂";
}

.mdi-map-outline:before {
  content: "女";
}

.mdi-map-plus:before {
  content: "廬";
}

.mdi-map-search:before {
  content: "旅";
}

.mdi-map-search-outline:before {
  content: "濾";
}

.mdi-mapbox:before {
  content: "ﮆ";
}

.mdi-margin:before {
  content: "\f353";
}

.mdi-markdown:before {
  content: "\f354";
}

.mdi-marker:before {
  content: "\f652";
}

.mdi-marker-cancel:before {
  content: "ﶵ";
}

.mdi-marker-check:before {
  content: "\f355";
}

.mdi-mastodon:before {
  content: "𢡄";
}

.mdi-mastodon-variant:before {
  content: "𣏕";
}

.mdi-material-design:before {
  content: "礪";
}

.mdi-material-ui:before {
  content: "\f357";
}

.mdi-math-compass:before {
  content: "\f358";
}

.mdi-math-cos:before {
  content: "ﱲ";
}

.mdi-math-sin:before {
  content: "ﱳ";
}

.mdi-math-tan:before {
  content: "ﱴ";
}

.mdi-matrix:before {
  content: "\f628";
}

.mdi-maxcdn:before {
  content: "\f359";
}

.mdi-medal:before {
  content: "閭";
}

.mdi-medical-bag:before {
  content: "\f6ee";
}

.mdi-medium:before {
  content: "\f35a";
}

.mdi-meetup:before {
  content: "㮝";
}

.mdi-memory:before {
  content: "\f35b";
}

.mdi-menu:before {
  content: "\f35c";
}

.mdi-menu-down:before {
  content: "\f35d";
}

.mdi-menu-down-outline:before {
  content: "\f6b5";
}

.mdi-menu-left:before {
  content: "\f35e";
}

.mdi-menu-left-outline:before {
  content: "度";
}

.mdi-menu-open:before {
  content: "ﮇ";
}

.mdi-menu-right:before {
  content: "\f35f";
}

.mdi-menu-right-outline:before {
  content: "拓";
}

.mdi-menu-swap:before {
  content: "謹";
}

.mdi-menu-swap-outline:before {
  content: "賓";
}

.mdi-menu-up:before {
  content: "\f360";
}

.mdi-menu-up-outline:before {
  content: "\f6b6";
}

.mdi-message:before {
  content: "\f361";
}

.mdi-message-alert:before {
  content: "\f362";
}

.mdi-message-alert-outline:before {
  content: "糖";
}

.mdi-message-bulleted:before {
  content: "\f6a1";
}

.mdi-message-bulleted-off:before {
  content: "\f6a2";
}

.mdi-message-draw:before {
  content: "\f363";
}

.mdi-message-image:before {
  content: "\f364";
}

.mdi-message-outline:before {
  content: "\f365";
}

.mdi-message-plus:before {
  content: "\f653";
}

.mdi-message-processing:before {
  content: "\f366";
}

.mdi-message-reply:before {
  content: "\f367";
}

.mdi-message-reply-text:before {
  content: "\f368";
}

.mdi-message-settings:before {
  content: "\f6ef";
}

.mdi-message-settings-variant:before {
  content: "\f6f0";
}

.mdi-message-text:before {
  content: "\f369";
}

.mdi-message-text-outline:before {
  content: "\f36a";
}

.mdi-message-video:before {
  content: "\f36b";
}

.mdi-meteor:before {
  content: "\f629";
}

.mdi-metronome:before {
  content: "\f7d9";
}

.mdi-metronome-tick:before {
  content: "\f7da";
}

.mdi-micro-sd:before {
  content: "\f7db";
}

.mdi-microphone:before {
  content: "\f36c";
}

.mdi-microphone-minus:before {
  content: "\f8b2";
}

.mdi-microphone-off:before {
  content: "\f36d";
}

.mdi-microphone-outline:before {
  content: "\f36e";
}

.mdi-microphone-plus:before {
  content: "\f8b3";
}

.mdi-microphone-settings:before {
  content: "\f36f";
}

.mdi-microphone-variant:before {
  content: "\f370";
}

.mdi-microphone-variant-off:before {
  content: "\f371";
}

.mdi-microscope:before {
  content: "\f654";
}

.mdi-microsoft:before {
  content: "\f372";
}

.mdi-microsoft-dynamics:before {
  content: "驪";
}

.mdi-microwave:before {
  content: "ﱵ";
}

.mdi-midi:before {
  content: "\f8f0";
}

.mdi-midi-port:before {
  content: "\f8f1";
}

.mdi-mine:before {
  content: "ﶶ";
}

.mdi-minecraft:before {
  content: "\f373";
}

.mdi-mini-sd:before {
  content: "宅";
}

.mdi-minidisc:before {
  content: "洞";
}

.mdi-minus:before {
  content: "\f374";
}

.mdi-minus-box:before {
  content: "\f375";
}

.mdi-minus-box-outline:before {
  content: "\f6f1";
}

.mdi-minus-circle:before {
  content: "\f376";
}

.mdi-minus-circle-outline:before {
  content: "\f377";
}

.mdi-minus-network:before {
  content: "\f378";
}

.mdi-minus-network-outline:before {
  content: "ﱶ";
}

.mdi-mixcloud:before {
  content: "\f62a";
}

.mdi-mixed-martial-arts:before {
  content: "ﵫ";
}

.mdi-mixed-reality:before {
  content: "\f87e";
}

.mdi-mixer:before {
  content: "\f7dc";
}

.mdi-molecule:before {
  content: "ﮈ";
}

.mdi-monitor:before {
  content: "\f379";
}

.mdi-monitor-cellphone:before {
  content: "麗";
}

.mdi-monitor-cellphone-star:before {
  content: "黎";
}

.mdi-monitor-dashboard:before {
  content: "暴";
}

.mdi-monitor-lock:before {
  content: "ﶷ";
}

.mdi-monitor-multiple:before {
  content: "\f37a";
}

.mdi-monitor-off:before {
  content: "ﵬ";
}

.mdi-monitor-star:before {
  content: "ﶸ";
}

.mdi-more:before {
  content: "\f37b";
}

.mdi-mother-nurse:before {
  content: "ﳽ";
}

.mdi-motion-sensor:before {
  content: "ﵭ";
}

.mdi-motorbike:before {
  content: "\f37c";
}

.mdi-mouse:before {
  content: "\f37d";
}

.mdi-mouse-bluetooth:before {
  content: "力";
}

.mdi-mouse-off:before {
  content: "\f37e";
}

.mdi-mouse-variant:before {
  content: "\f37f";
}

.mdi-mouse-variant-off:before {
  content: "\f380";
}

.mdi-move-resize:before {
  content: "\f655";
}

.mdi-move-resize-variant:before {
  content: "\f656";
}

.mdi-movie:before {
  content: "\f381";
}

.mdi-movie-outline:before {
  content: "ﶹ";
}

.mdi-movie-roll:before {
  content: "\f7dd";
}

.mdi-muffin:before {
  content: "曆";
}

.mdi-multiplication:before {
  content: "\f382";
}

.mdi-multiplication-box:before {
  content: "\f383";
}

.mdi-mushroom:before {
  content: "\f7de";
}

.mdi-mushroom-outline:before {
  content: "\f7df";
}

.mdi-music:before {
  content: "\f759";
}

.mdi-music-box:before {
  content: "\f384";
}

.mdi-music-box-outline:before {
  content: "\f385";
}

.mdi-music-circle:before {
  content: "\f386";
}

.mdi-music-circle-outline:before {
  content: "䀘";
}

.mdi-music-note:before {
  content: "\f387";
}

.mdi-music-note-bluetooth:before {
  content: "\f5fe";
}

.mdi-music-note-bluetooth-off:before {
  content: "\f5ff";
}

.mdi-music-note-eighth:before {
  content: "\f388";
}

.mdi-music-note-half:before {
  content: "\f389";
}

.mdi-music-note-off:before {
  content: "\f38a";
}

.mdi-music-note-plus:before {
  content: "ﶺ";
}

.mdi-music-note-quarter:before {
  content: "\f38b";
}

.mdi-music-note-sixteenth:before {
  content: "\f38c";
}

.mdi-music-note-whole:before {
  content: "\f38d";
}

.mdi-music-off:before {
  content: "\f75a";
}

.mdi-nail:before {
  content: "ﶻ";
}

.mdi-nas:before {
  content: "\f8f2";
}

.mdi-nativescript:before {
  content: "\f87f";
}

.mdi-nature:before {
  content: "\f38e";
}

.mdi-nature-people:before {
  content: "\f38f";
}

.mdi-navigation:before {
  content: "\f390";
}

.mdi-near-me:before {
  content: "\f5cd";
}

.mdi-needle:before {
  content: "\f391";
}

.mdi-netflix:before {
  content: "\f745";
}

.mdi-network:before {
  content: "\f6f2";
}

.mdi-network-off:before {
  content: "ﱷ";
}

.mdi-network-off-outline:before {
  content: "ﱸ";
}

.mdi-network-outline:before {
  content: "ﱹ";
}

.mdi-network-strength-1:before {
  content: "\f8f3";
}

.mdi-network-strength-1-alert:before {
  content: "\f8f4";
}

.mdi-network-strength-2:before {
  content: "\f8f5";
}

.mdi-network-strength-2-alert:before {
  content: "\f8f6";
}

.mdi-network-strength-3:before {
  content: "\f8f7";
}

.mdi-network-strength-3-alert:before {
  content: "\f8f8";
}

.mdi-network-strength-4:before {
  content: "\f8f9";
}

.mdi-network-strength-4-alert:before {
  content: "\f8fa";
}

.mdi-network-strength-off:before {
  content: "\f8fb";
}

.mdi-network-strength-off-outline:before {
  content: "\f8fc";
}

.mdi-network-strength-outline:before {
  content: "\f8fd";
}

.mdi-new-box:before {
  content: "\f394";
}

.mdi-newspaper:before {
  content: "\f395";
}

.mdi-nfc:before {
  content: "\f396";
}

.mdi-nfc-tap:before {
  content: "\f397";
}

.mdi-nfc-variant:before {
  content: "\f398";
}

.mdi-ninja:before {
  content: "\f773";
}

.mdi-nintendo-switch:before {
  content: "\f7e0";
}

.mdi-nodejs:before {
  content: "\f399";
}

.mdi-not-equal:before {
  content: "歷";
}

.mdi-not-equal-variant:before {
  content: "轢";
}

.mdi-note:before {
  content: "\f39a";
}

.mdi-note-multiple:before {
  content: "\f6b7";
}

.mdi-note-multiple-outline:before {
  content: "\f6b8";
}

.mdi-note-outline:before {
  content: "\f39b";
}

.mdi-note-plus:before {
  content: "\f39c";
}

.mdi-note-plus-outline:before {
  content: "\f39d";
}

.mdi-note-text:before {
  content: "\f39e";
}

.mdi-notebook:before {
  content: "\f82d";
}

.mdi-notification-clear-all:before {
  content: "\f39f";
}

.mdi-npm:before {
  content: "\f6f6";
}

.mdi-npm-variant:before {
  content: "年";
}

.mdi-npm-variant-outline:before {
  content: "憐";
}

.mdi-nuke:before {
  content: "\f6a3";
}

.mdi-null:before {
  content: "\f7e1";
}

.mdi-numeric:before {
  content: "\f3a0";
}

.mdi-numeric-0:before {
  content: "0";
}

.mdi-numeric-0-box:before {
  content: "\f3a1";
}

.mdi-numeric-0-box-multiple-outline:before {
  content: "\f3a2";
}

.mdi-numeric-0-box-outline:before {
  content: "\f3a3";
}

.mdi-numeric-0-circle:before {
  content: "ﱺ";
}

.mdi-numeric-0-circle-outline:before {
  content: "ﱻ";
}

.mdi-numeric-1:before {
  content: "1";
}

.mdi-numeric-1-box:before {
  content: "\f3a4";
}

.mdi-numeric-1-box-multiple-outline:before {
  content: "\f3a5";
}

.mdi-numeric-1-box-outline:before {
  content: "\f3a6";
}

.mdi-numeric-1-circle:before {
  content: "ﱼ";
}

.mdi-numeric-1-circle-outline:before {
  content: "ﱽ";
}

.mdi-numeric-2:before {
  content: "2";
}

.mdi-numeric-2-box:before {
  content: "\f3a7";
}

.mdi-numeric-2-box-multiple-outline:before {
  content: "\f3a8";
}

.mdi-numeric-2-box-outline:before {
  content: "\f3a9";
}

.mdi-numeric-2-circle:before {
  content: "ﱾ";
}

.mdi-numeric-2-circle-outline:before {
  content: "ﱿ";
}

.mdi-numeric-3:before {
  content: "3";
}

.mdi-numeric-3-box:before {
  content: "\f3aa";
}

.mdi-numeric-3-box-multiple-outline:before {
  content: "\f3ab";
}

.mdi-numeric-3-box-outline:before {
  content: "\f3ac";
}

.mdi-numeric-3-circle:before {
  content: "ﲀ";
}

.mdi-numeric-3-circle-outline:before {
  content: "ﲁ";
}

.mdi-numeric-4:before {
  content: "4";
}

.mdi-numeric-4-box:before {
  content: "\f3ad";
}

.mdi-numeric-4-box-multiple-outline:before {
  content: "\f3ae";
}

.mdi-numeric-4-box-outline:before {
  content: "\f3af";
}

.mdi-numeric-4-circle:before {
  content: "ﲂ";
}

.mdi-numeric-4-circle-outline:before {
  content: "ﲃ";
}

.mdi-numeric-5:before {
  content: "5";
}

.mdi-numeric-5-box:before {
  content: "\f3b0";
}

.mdi-numeric-5-box-multiple-outline:before {
  content: "\f3b1";
}

.mdi-numeric-5-box-outline:before {
  content: "\f3b2";
}

.mdi-numeric-5-circle:before {
  content: "ﲄ";
}

.mdi-numeric-5-circle-outline:before {
  content: "ﲅ";
}

.mdi-numeric-6:before {
  content: "6";
}

.mdi-numeric-6-box:before {
  content: "\f3b3";
}

.mdi-numeric-6-box-multiple-outline:before {
  content: "\f3b4";
}

.mdi-numeric-6-box-outline:before {
  content: "\f3b5";
}

.mdi-numeric-6-circle:before {
  content: "ﲆ";
}

.mdi-numeric-6-circle-outline:before {
  content: "ﲇ";
}

.mdi-numeric-7:before {
  content: "7";
}

.mdi-numeric-7-box:before {
  content: "\f3b6";
}

.mdi-numeric-7-box-multiple-outline:before {
  content: "\f3b7";
}

.mdi-numeric-7-box-outline:before {
  content: "\f3b8";
}

.mdi-numeric-7-circle:before {
  content: "ﲈ";
}

.mdi-numeric-7-circle-outline:before {
  content: "ﲉ";
}

.mdi-numeric-8:before {
  content: "8";
}

.mdi-numeric-8-box:before {
  content: "\f3b9";
}

.mdi-numeric-8-box-multiple-outline:before {
  content: "\f3ba";
}

.mdi-numeric-8-box-outline:before {
  content: "\f3bb";
}

.mdi-numeric-8-circle:before {
  content: "ﲊ";
}

.mdi-numeric-8-circle-outline:before {
  content: "ﲋ";
}

.mdi-numeric-9:before {
  content: "9";
}

.mdi-numeric-9-box:before {
  content: "\f3bc";
}

.mdi-numeric-9-box-multiple-outline:before {
  content: "\f3bd";
}

.mdi-numeric-9-box-outline:before {
  content: "\f3be";
}

.mdi-numeric-9-circle:before {
  content: "ﲌ";
}

.mdi-numeric-9-circle-outline:before {
  content: "ﲍ";
}

.mdi-numeric-9-plus-box:before {
  content: "\f3bf";
}

.mdi-numeric-9-plus-box-multiple-outline:before {
  content: "\f3c0";
}

.mdi-numeric-9-plus-box-outline:before {
  content: "\f3c1";
}

.mdi-numeric-9-plus-circle:before {
  content: "ﲎ";
}

.mdi-numeric-9-plus-circle-outline:before {
  content: "ﲏ";
}

.mdi-nut:before {
  content: "\f6f7";
}

.mdi-nutrition:before {
  content: "\f3c2";
}

.mdi-oar:before {
  content: "\f67b";
}

.mdi-ocarina:before {
  content: "ﶼ";
}

.mdi-octagon:before {
  content: "\f3c3";
}

.mdi-octagon-outline:before {
  content: "\f3c4";
}

.mdi-octagram:before {
  content: "\f6f8";
}

.mdi-octagram-outline:before {
  content: "\f774";
}

.mdi-odnoklassniki:before {
  content: "\f3c5";
}

.mdi-office:before {
  content: "\f3c6";
}

.mdi-office-building:before {
  content: "戀";
}

.mdi-oil:before {
  content: "\f3c7";
}

.mdi-oil-temperature:before {
  content: "\f3c8";
}

.mdi-omega:before {
  content: "\f3c9";
}

.mdi-one-up:before {
  content: "ﮉ";
}

.mdi-onedrive:before {
  content: "\f3ca";
}

.mdi-onenote:before {
  content: "\f746";
}

.mdi-onepassword:before {
  content: "\f880";
}

.mdi-opacity:before {
  content: "\f5cc";
}

.mdi-open-in-app:before {
  content: "\f3cb";
}

.mdi-open-in-new:before {
  content: "\f3cc";
}

.mdi-open-source-initiative:before {
  content: "ﮊ";
}

.mdi-openid:before {
  content: "\f3cd";
}

.mdi-opera:before {
  content: "\f3ce";
}

.mdi-orbit:before {
  content: "\f018";
}

.mdi-origin:before {
  content: "שׂ";
}

.mdi-ornament:before {
  content: "\f3cf";
}

.mdi-ornament-variant:before {
  content: "\f3d0";
}

.mdi-outlook:before {
  content: "ﳾ";
}

.mdi-owl:before {
  content: "\f3d2";
}

.mdi-pac-man:before {
  content: "ﮋ";
}

.mdi-package:before {
  content: "\f3d3";
}

.mdi-package-down:before {
  content: "\f3d4";
}

.mdi-package-up:before {
  content: "\f3d5";
}

.mdi-package-variant:before {
  content: "\f3d6";
}

.mdi-package-variant-closed:before {
  content: "\f3d7";
}

.mdi-page-first:before {
  content: "\f600";
}

.mdi-page-last:before {
  content: "\f601";
}

.mdi-page-layout-body:before {
  content: "\f6f9";
}

.mdi-page-layout-footer:before {
  content: "\f6fa";
}

.mdi-page-layout-header:before {
  content: "\f6fb";
}

.mdi-page-layout-sidebar-left:before {
  content: "\f6fc";
}

.mdi-page-layout-sidebar-right:before {
  content: "\f6fd";
}

.mdi-page-next:before {
  content: "ﮌ";
}

.mdi-page-next-outline:before {
  content: "ﮍ";
}

.mdi-page-previous:before {
  content: "ﮎ";
}

.mdi-page-previous-outline:before {
  content: "ﮏ";
}

.mdi-palette:before {
  content: "\f3d8";
}

.mdi-palette-advanced:before {
  content: "\f3d9";
}

.mdi-palette-outline:before {
  content: "﷨";
}

.mdi-palette-swatch:before {
  content: "\f8b4";
}

.mdi-pan:before {
  content: "ﮐ";
}

.mdi-pan-bottom-left:before {
  content: "ﮑ";
}

.mdi-pan-bottom-right:before {
  content: "ﮒ";
}

.mdi-pan-down:before {
  content: "ﮓ";
}

.mdi-pan-horizontal:before {
  content: "ﮔ";
}

.mdi-pan-left:before {
  content: "ﮕ";
}

.mdi-pan-right:before {
  content: "ﮖ";
}

.mdi-pan-top-left:before {
  content: "ﮗ";
}

.mdi-pan-top-right:before {
  content: "ﮘ";
}

.mdi-pan-up:before {
  content: "ﮙ";
}

.mdi-pan-vertical:before {
  content: "ﮚ";
}

.mdi-panda:before {
  content: "\f3da";
}

.mdi-pandora:before {
  content: "\f3db";
}

.mdi-panorama:before {
  content: "\f3dc";
}

.mdi-panorama-fisheye:before {
  content: "\f3dd";
}

.mdi-panorama-horizontal:before {
  content: "\f3de";
}

.mdi-panorama-vertical:before {
  content: "\f3df";
}

.mdi-panorama-wide-angle:before {
  content: "\f3e0";
}

.mdi-paper-cut-vertical:before {
  content: "\f3e1";
}

.mdi-paperclip:before {
  content: "\f3e2";
}

.mdi-parachute:before {
  content: "ﲐ";
}

.mdi-parachute-outline:before {
  content: "ﲑ";
}

.mdi-parking:before {
  content: "\f3e3";
}

.mdi-passport:before {
  content: "\f7e2";
}

.mdi-passport-biometric:before {
  content: "ﶽ";
}

.mdi-patreon:before {
  content: "\f881";
}

.mdi-pause:before {
  content: "\f3e4";
}

.mdi-pause-circle:before {
  content: "\f3e5";
}

.mdi-pause-circle-outline:before {
  content: "\f3e6";
}

.mdi-pause-octagon:before {
  content: "\f3e7";
}

.mdi-pause-octagon-outline:before {
  content: "\f3e8";
}

.mdi-paw:before {
  content: "\f3e9";
}

.mdi-paw-off:before {
  content: "\f657";
}

.mdi-paypal:before {
  content: "\f882";
}

.mdi-peace:before {
  content: "\f883";
}

.mdi-pen:before {
  content: "\f3ea";
}

.mdi-pen-lock:before {
  content: "ﶾ";
}

.mdi-pen-minus:before {
  content: "ﶿ";
}

.mdi-pen-off:before {
  content: "ﷀ";
}

.mdi-pen-plus:before {
  content: "ﷁ";
}

.mdi-pen-remove:before {
  content: "ﷂ";
}

.mdi-pencil:before {
  content: "\f3eb";
}

.mdi-pencil-box:before {
  content: "\f3ec";
}

.mdi-pencil-box-outline:before {
  content: "\f3ed";
}

.mdi-pencil-circle:before {
  content: "\f6fe";
}

.mdi-pencil-circle-outline:before {
  content: "\f775";
}

.mdi-pencil-lock:before {
  content: "\f3ee";
}

.mdi-pencil-lock-outline:before {
  content: "ﷃ";
}

.mdi-pencil-minus:before {
  content: "ﷄ";
}

.mdi-pencil-minus-outline:before {
  content: "ﷅ";
}

.mdi-pencil-off:before {
  content: "\f3ef";
}

.mdi-pencil-off-outline:before {
  content: "ﷆ";
}

.mdi-pencil-outline:before {
  content: "ﲒ";
}

.mdi-pencil-plus:before {
  content: "ﷇ";
}

.mdi-pencil-plus-outline:before {
  content: "﷈";
}

.mdi-pencil-remove:before {
  content: "﷉";
}

.mdi-pencil-remove-outline:before {
  content: "﷊";
}

.mdi-pentagon:before {
  content: "\f6ff";
}

.mdi-pentagon-outline:before {
  content: "\f700";
}

.mdi-percent:before {
  content: "\f3f0";
}

.mdi-periodic-table:before {
  content: "\f8b5";
}

.mdi-periodic-table-co2:before {
  content: "\f7e3";
}

.mdi-periscope:before {
  content: "\f747";
}

.mdi-perspective-less:before {
  content: "ﳿ";
}

.mdi-perspective-more:before {
  content: "ﴀ";
}

.mdi-pharmacy:before {
  content: "\f3f1";
}

.mdi-phone:before {
  content: "\f3f2";
}

.mdi-phone-bluetooth:before {
  content: "\f3f3";
}

.mdi-phone-classic:before {
  content: "\f602";
}

.mdi-phone-forward:before {
  content: "\f3f4";
}

.mdi-phone-hangup:before {
  content: "\f3f5";
}

.mdi-phone-in-talk:before {
  content: "\f3f6";
}

.mdi-phone-incoming:before {
  content: "\f3f7";
}

.mdi-phone-lock:before {
  content: "\f3f8";
}

.mdi-phone-log:before {
  content: "\f3f9";
}

.mdi-phone-minus:before {
  content: "\f658";
}

.mdi-phone-missed:before {
  content: "\f3fa";
}

.mdi-phone-off:before {
  content: "﷋";
}

.mdi-phone-outgoing:before {
  content: "\f3fb";
}

.mdi-phone-outline:before {
  content: "﷌";
}

.mdi-phone-paused:before {
  content: "\f3fc";
}

.mdi-phone-plus:before {
  content: "\f659";
}

.mdi-phone-return:before {
  content: "\f82e";
}

.mdi-phone-rotate-landscape:before {
  content: "\f884";
}

.mdi-phone-rotate-portrait:before {
  content: "\f885";
}

.mdi-phone-settings:before {
  content: "\f3fd";
}

.mdi-phone-voip:before {
  content: "\f3fe";
}

.mdi-pi:before {
  content: "\f3ff";
}

.mdi-pi-box:before {
  content: "\f400";
}

.mdi-pi-hole:before {
  content: "﷍";
}

.mdi-piano:before {
  content: "\f67c";
}

.mdi-pickaxe:before {
  content: "\f8b6";
}

.mdi-pier:before {
  content: "\f886";
}

.mdi-pier-crane:before {
  content: "\f887";
}

.mdi-pig:before {
  content: "\f401";
}

.mdi-pill:before {
  content: "\f402";
}

.mdi-pillar:before {
  content: "\f701";
}

.mdi-pin:before {
  content: "\f403";
}

.mdi-pin-off:before {
  content: "\f404";
}

.mdi-pin-off-outline:before {
  content: "勞";
}

.mdi-pin-outline:before {
  content: "擄";
}

.mdi-pine-tree:before {
  content: "\f405";
}

.mdi-pine-tree-box:before {
  content: "\f406";
}

.mdi-pinterest:before {
  content: "\f407";
}

.mdi-pinterest-box:before {
  content: "\f408";
}

.mdi-pinwheel:before {
  content: "䀹";
}

.mdi-pinwheel-outline:before {
  content: "𥉉";
}

.mdi-pipe:before {
  content: "\f7e4";
}

.mdi-pipe-disconnected:before {
  content: "\f7e5";
}

.mdi-pipe-leak:before {
  content: "\f888";
}

.mdi-pirate:before {
  content: "輻";
}

.mdi-pistol:before {
  content: "\f702";
}

.mdi-piston:before {
  content: "\f889";
}

.mdi-pizza:before {
  content: "\f409";
}

.mdi-play:before {
  content: "\f40a";
}

.mdi-play-box-outline:before {
  content: "\f40b";
}

.mdi-play-circle:before {
  content: "\f40c";
}

.mdi-play-circle-outline:before {
  content: "\f40d";
}

.mdi-play-network:before {
  content: "\f88a";
}

.mdi-play-network-outline:before {
  content: "ﲓ";
}

.mdi-play-pause:before {
  content: "\f40e";
}

.mdi-play-protected-content:before {
  content: "\f40f";
}

.mdi-play-speed:before {
  content: "\f8fe";
}

.mdi-playlist-check:before {
  content: "\f5c7";
}

.mdi-playlist-edit:before {
  content: "\f8ff";
}

.mdi-playlist-minus:before {
  content: "\f410";
}

.mdi-playlist-music:before {
  content: "ﲔ";
}

.mdi-playlist-music-outline:before {
  content: "ﲕ";
}

.mdi-playlist-play:before {
  content: "\f411";
}

.mdi-playlist-plus:before {
  content: "\f412";
}

.mdi-playlist-remove:before {
  content: "\f413";
}

.mdi-playlist-star:before {
  content: "﷎";
}

.mdi-playstation:before {
  content: "\f414";
}

.mdi-plex:before {
  content: "\f6b9";
}

.mdi-plus:before {
  content: "\f415";
}

.mdi-plus-box:before {
  content: "\f416";
}

.mdi-plus-box-outline:before {
  content: "\f703";
}

.mdi-plus-circle:before {
  content: "\f417";
}

.mdi-plus-circle-multiple-outline:before {
  content: "\f418";
}

.mdi-plus-circle-outline:before {
  content: "\f419";
}

.mdi-plus-minus:before {
  content: "撚";
}

.mdi-plus-minus-box:before {
  content: "漣";
}

.mdi-plus-network:before {
  content: "\f41a";
}

.mdi-plus-network-outline:before {
  content: "ﲖ";
}

.mdi-plus-one:before {
  content: "\f41b";
}

.mdi-plus-outline:before {
  content: "\f704";
}

.mdi-pocket:before {
  content: "\f41c";
}

.mdi-podcast:before {
  content: "煉";
}

.mdi-podium:before {
  content: "ﴁ";
}

.mdi-podium-bronze:before {
  content: "ﴂ";
}

.mdi-podium-gold:before {
  content: "ﴃ";
}

.mdi-podium-silver:before {
  content: "ﴄ";
}

.mdi-point-of-sale:before {
  content: "ﵮ";
}

.mdi-pokeball:before {
  content: "\f41d";
}

.mdi-pokemon-go:before {
  content: "行";
}

.mdi-poker-chip:before {
  content: "\f82f";
}

.mdi-polaroid:before {
  content: "\f41e";
}

.mdi-poll:before {
  content: "\f41f";
}

.mdi-poll-box:before {
  content: "\f420";
}

.mdi-polymer:before {
  content: "\f421";
}

.mdi-pool:before {
  content: "\f606";
}

.mdi-popcorn:before {
  content: "\f422";
}

.mdi-postage-stamp:before {
  content: "ﲗ";
}

.mdi-pot:before {
  content: "\f65a";
}

.mdi-pot-mix:before {
  content: "\f65b";
}

.mdi-pound:before {
  content: "\f423";
}

.mdi-pound-box:before {
  content: "\f424";
}

.mdi-power:before {
  content: "\f425";
}

.mdi-power-cycle:before {
  content: "豈";
}

.mdi-power-off:before {
  content: "更";
}

.mdi-power-on:before {
  content: "車";
}

.mdi-power-plug:before {
  content: "\f6a4";
}

.mdi-power-plug-off:before {
  content: "\f6a5";
}

.mdi-power-settings:before {
  content: "\f426";
}

.mdi-power-sleep:before {
  content: "賈";
}

.mdi-power-socket:before {
  content: "\f427";
}

.mdi-power-socket-au:before {
  content: "滑";
}

.mdi-power-socket-eu:before {
  content: "\f7e6";
}

.mdi-power-socket-uk:before {
  content: "\f7e7";
}

.mdi-power-socket-us:before {
  content: "\f7e8";
}

.mdi-power-standby:before {
  content: "串";
}

.mdi-powershell:before {
  content: "降";
}

.mdi-prescription:before {
  content: "\f705";
}

.mdi-presentation:before {
  content: "\f428";
}

.mdi-presentation-play:before {
  content: "\f429";
}

.mdi-printer:before {
  content: "\f42a";
}

.mdi-printer-3d:before {
  content: "\f42b";
}

.mdi-printer-alert:before {
  content: "\f42c";
}

.mdi-printer-settings:before {
  content: "\f706";
}

.mdi-printer-wireless:before {
  content: "見";
}

.mdi-priority-high:before {
  content: "\f603";
}

.mdi-priority-low:before {
  content: "\f604";
}

.mdi-professional-hexagon:before {
  content: "\f42d";
}

.mdi-progress-alert:before {
  content: "ﲘ";
}

.mdi-progress-check:before {
  content: "璉";
}

.mdi-progress-clock:before {
  content: "秊";
}

.mdi-progress-download:before {
  content: "練";
}

.mdi-progress-upload:before {
  content: "聯";
}

.mdi-progress-wrench:before {
  content: "ﲙ";
}

.mdi-projector:before {
  content: "\f42e";
}

.mdi-projector-screen:before {
  content: "\f42f";
}

.mdi-publish:before {
  content: "\f6a6";
}

.mdi-pulse:before {
  content: "\f430";
}

.mdi-pumpkin:before {
  content: "ﮛ";
}

.mdi-puzzle:before {
  content: "\f431";
}

.mdi-puzzle-outline:before {
  content: "贈";
}

.mdi-qi:before {
  content: "輦";
}

.mdi-qqchat:before {
  content: "\f605";
}

.mdi-qrcode:before {
  content: "\f432";
}

.mdi-qrcode-edit:before {
  content: "\f8b7";
}

.mdi-qrcode-scan:before {
  content: "\f433";
}

.mdi-quadcopter:before {
  content: "\f434";
}

.mdi-quality-high:before {
  content: "\f435";
}

.mdi-quality-low:before {
  content: "廓";
}

.mdi-quality-medium:before {
  content: "兀";
}

.mdi-quicktime:before {
  content: "\f436";
}

.mdi-quora:before {
  content: "ﴅ";
}

.mdi-rabbit:before {
  content: "句";
}

.mdi-racing-helmet:before {
  content: "ﵯ";
}

.mdi-racquetball:before {
  content: "ﵰ";
}

.mdi-radar:before {
  content: "\f437";
}

.mdi-radiator:before {
  content: "\f438";
}

.mdi-radiator-disabled:before {
  content: "𥳐";
}

.mdi-radiator-off:before {
  content: "𧻓";
}

.mdi-radio:before {
  content: "\f439";
}

.mdi-radio-am:before {
  content: "ﲚ";
}

.mdi-radio-fm:before {
  content: "ﲛ";
}

.mdi-radio-handheld:before {
  content: "\f43a";
}

.mdi-radio-tower:before {
  content: "\f43b";
}

.mdi-radioactive:before {
  content: "\f43c";
}

.mdi-radiobox-blank:before {
  content: "\f43d";
}

.mdi-radiobox-marked:before {
  content: "\f43e";
}

.mdi-radius:before {
  content: "ﲜ";
}

.mdi-radius-outline:before {
  content: "ﲝ";
}

.mdi-raspberry-pi:before {
  content: "\f43f";
}

.mdi-ray-end:before {
  content: "\f440";
}

.mdi-ray-end-arrow:before {
  content: "\f441";
}

.mdi-ray-start:before {
  content: "\f442";
}

.mdi-ray-start-arrow:before {
  content: "\f443";
}

.mdi-ray-start-end:before {
  content: "\f444";
}

.mdi-ray-vertex:before {
  content: "\f445";
}

.mdi-react:before {
  content: "\f707";
}

.mdi-read:before {
  content: "\f447";
}

.mdi-receipt:before {
  content: "\f449";
}

.mdi-record:before {
  content: "\f44a";
}

.mdi-record-player:before {
  content: "蓮";
}

.mdi-record-rec:before {
  content: "\f44b";
}

.mdi-recycle:before {
  content: "\f44c";
}

.mdi-reddit:before {
  content: "\f44d";
}

.mdi-redo:before {
  content: "\f44e";
}

.mdi-redo-variant:before {
  content: "\f44f";
}

.mdi-reflect-horizontal:before {
  content: "嗀";
}

.mdi-reflect-vertical:before {
  content: "﨎";
}

.mdi-refresh:before {
  content: "\f450";
}

.mdi-regex:before {
  content: "\f451";
}

.mdi-registered-trademark:before {
  content: "辶";
}

.mdi-relative-scale:before {
  content: "\f452";
}

.mdi-reload:before {
  content: "\f453";
}

.mdi-reminder:before {
  content: "\f88b";
}

.mdi-remote:before {
  content: "\f454";
}

.mdi-remote-desktop:before {
  content: "\f8b8";
}

.mdi-rename-box:before {
  content: "\f455";
}

.mdi-reorder-horizontal:before {
  content: "\f687";
}

.mdi-reorder-vertical:before {
  content: "\f688";
}

.mdi-repeat:before {
  content: "\f456";
}

.mdi-repeat-off:before {
  content: "\f457";
}

.mdi-repeat-once:before {
  content: "\f458";
}

.mdi-replay:before {
  content: "\f459";
}

.mdi-reply:before {
  content: "\f45a";
}

.mdi-reply-all:before {
  content: "\f45b";
}

.mdi-reproduction:before {
  content: "\f45c";
}

.mdi-resistor:before {
  content: "ײַ";
}

.mdi-resistor-nodes:before {
  content: "ﬠ";
}

.mdi-resize:before {
  content: "逸";
}

.mdi-resize-bottom-right:before {
  content: "\f45d";
}

.mdi-responsive:before {
  content: "\f45e";
}

.mdi-restart:before {
  content: "\f708";
}

.mdi-restart-off:before {
  content: "ﵱ";
}

.mdi-restore:before {
  content: "連";
}

.mdi-restore-clock:before {
  content: "\f6a7";
}

.mdi-rewind:before {
  content: "\f45f";
}

.mdi-rewind-10:before {
  content: "ﴆ";
}

.mdi-rewind-30:before {
  content: "ﵲ";
}

.mdi-rewind-outline:before {
  content: "\f709";
}

.mdi-rhombus:before {
  content: "\f70a";
}

.mdi-rhombus-medium:before {
  content: "﨏";
}

.mdi-rhombus-outline:before {
  content: "\f70b";
}

.mdi-rhombus-split:before {
  content: "塚";
}

.mdi-ribbon:before {
  content: "\f460";
}

.mdi-rice:before {
  content: "\f7e9";
}

.mdi-ring:before {
  content: "\f7ea";
}

.mdi-road:before {
  content: "\f461";
}

.mdi-road-variant:before {
  content: "\f462";
}

.mdi-robot:before {
  content: "\f6a8";
}

.mdi-robot-industrial:before {
  content: "ﬡ";
}

.mdi-robot-vacuum:before {
  content: "\f70c";
}

.mdi-robot-vacuum-variant:before {
  content: "龜";
}

.mdi-rocket:before {
  content: "\f463";
}

.mdi-roller-skate:before {
  content: "ﴇ";
}

.mdi-rollerblade:before {
  content: "ﴈ";
}

.mdi-rollupjs:before {
  content: "ﮜ";
}

.mdi-room-service:before {
  content: "\f88c";
}

.mdi-room-service-outline:before {
  content: "ﵳ";
}

.mdi-rotate-3d:before {
  content: "\f464";
}

.mdi-rotate-left:before {
  content: "\f465";
}

.mdi-rotate-left-variant:before {
  content: "\f466";
}

.mdi-rotate-orbit:before {
  content: "ﵴ";
}

.mdi-rotate-right:before {
  content: "\f467";
}

.mdi-rotate-right-variant:before {
  content: "\f468";
}

.mdi-rounded-corner:before {
  content: "\f607";
}

.mdi-router-wireless:before {
  content: "\f469";
}

.mdi-router-wireless-settings:before {
  content: "難";
}

.mdi-routes:before {
  content: "\f46a";
}

.mdi-rowing:before {
  content: "\f608";
}

.mdi-rss:before {
  content: "\f46b";
}

.mdi-rss-box:before {
  content: "\f46c";
}

.mdi-ruby:before {
  content: "ﴉ";
}

.mdi-rugby:before {
  content: "ﵵ";
}

.mdi-ruler:before {
  content: "\f46d";
}

.mdi-ruler-square:before {
  content: "ﲞ";
}

.mdi-run:before {
  content: "\f70d";
}

.mdi-run-fast:before {
  content: "\f46e";
}

.mdi-sack:before {
  content: "ﴊ";
}

.mdi-sack-percent:before {
  content: "ﴋ";
}

.mdi-safe:before {
  content: "響";
}

.mdi-safety-goggles:before {
  content: "ﴌ";
}

.mdi-sale:before {
  content: "\f46f";
}

.mdi-salesforce:before {
  content: "\f88d";
}

.mdi-sass:before {
  content: "\f7eb";
}

.mdi-satellite:before {
  content: "\f470";
}

.mdi-satellite-uplink:before {
  content: "龜";
}

.mdi-satellite-variant:before {
  content: "\f471";
}

.mdi-sausage:before {
  content: "\f8b9";
}

.mdi-saxophone:before {
  content: "\f609";
}

.mdi-scale:before {
  content: "\f472";
}

.mdi-scale-balance:before {
  content: "\f5d1";
}

.mdi-scale-bathroom:before {
  content: "\f473";
}

.mdi-scanner:before {
  content: "\f6aa";
}

.mdi-scanner-off:before {
  content: "契";
}

.mdi-school:before {
  content: "\f474";
}

.mdi-scissors-cutting:before {
  content: "頻";
}

.mdi-screen-rotation:before {
  content: "\f475";
}

.mdi-screen-rotation-lock:before {
  content: "\f476";
}

.mdi-screw-flat-top:before {
  content: "﷏";
}

.mdi-screw-lag:before {
  content: "﷐";
}

.mdi-screw-machine-flat-top:before {
  content: "﷑";
}

.mdi-screw-machine-round-top:before {
  content: "﷒";
}

.mdi-screw-round-top:before {
  content: "﷓";
}

.mdi-screwdriver:before {
  content: "\f477";
}

.mdi-script:before {
  content: "ﮝ";
}

.mdi-script-outline:before {
  content: "\f478";
}

.mdi-script-text:before {
  content: "ﮞ";
}

.mdi-script-text-outline:before {
  content: "ﮟ";
}

.mdi-sd:before {
  content: "\f479";
}

.mdi-seal:before {
  content: "\f47a";
}

.mdi-search-web:before {
  content: "\f70e";
}

.mdi-seat:before {
  content: "ﲟ";
}

.mdi-seat-flat:before {
  content: "\f47b";
}

.mdi-seat-flat-angled:before {
  content: "\f47c";
}

.mdi-seat-individual-suite:before {
  content: "\f47d";
}

.mdi-seat-legroom-extra:before {
  content: "\f47e";
}

.mdi-seat-legroom-normal:before {
  content: "\f47f";
}

.mdi-seat-legroom-reduced:before {
  content: "\f480";
}

.mdi-seat-outline:before {
  content: "ﲠ";
}

.mdi-seat-recline-extra:before {
  content: "\f481";
}

.mdi-seat-recline-normal:before {
  content: "\f482";
}

.mdi-seatbelt:before {
  content: "ﲡ";
}

.mdi-security:before {
  content: "\f483";
}

.mdi-security-network:before {
  content: "\f484";
}

.mdi-select:before {
  content: "\f485";
}

.mdi-select-all:before {
  content: "\f486";
}

.mdi-select-color:before {
  content: "ﴍ";
}

.mdi-select-compare:before {
  content: "齃";
}

.mdi-select-drag:before {
  content: "恵";
}

.mdi-select-inverse:before {
  content: "\f487";
}

.mdi-select-off:before {
  content: "\f488";
}

.mdi-selection:before {
  content: "\f489";
}

.mdi-selection-drag:before {
  content: "𤋮";
}

.mdi-selection-ellipse:before {
  content: "ﴎ";
}

.mdi-selection-off:before {
  content: "\f776";
}

.mdi-send:before {
  content: "\f48a";
}

.mdi-send-circle:before {
  content: "﷔";
}

.mdi-send-circle-outline:before {
  content: "﷕";
}

.mdi-send-lock:before {
  content: "\f7ec";
}

.mdi-serial-port:before {
  content: "\f65c";
}

.mdi-server:before {
  content: "\f48b";
}

.mdi-server-minus:before {
  content: "\f48c";
}

.mdi-server-network:before {
  content: "\f48d";
}

.mdi-server-network-off:before {
  content: "\f48e";
}

.mdi-server-off:before {
  content: "\f48f";
}

.mdi-server-plus:before {
  content: "\f490";
}

.mdi-server-remove:before {
  content: "\f491";
}

.mdi-server-security:before {
  content: "\f492";
}

.mdi-set-all:before {
  content: "\f777";
}

.mdi-set-center:before {
  content: "\f778";
}

.mdi-set-center-right:before {
  content: "\f779";
}

.mdi-set-left:before {
  content: "\f77a";
}

.mdi-set-left-center:before {
  content: "\f77b";
}

.mdi-set-left-right:before {
  content: "\f77c";
}

.mdi-set-none:before {
  content: "\f77d";
}

.mdi-set-right:before {
  content: "\f77e";
}

.mdi-set-top-box:before {
  content: "咽";
}

.mdi-settings:before {
  content: "\f493";
}

.mdi-settings-box:before {
  content: "\f494";
}

.mdi-settings-helper:before {
  content: "舘";
}

.mdi-settings-outline:before {
  content: "\f8ba";
}

.mdi-shape:before {
  content: "\f830";
}

.mdi-shape-circle-plus:before {
  content: "\f65d";
}

.mdi-shape-outline:before {
  content: "\f831";
}

.mdi-shape-plus:before {
  content: "\f495";
}

.mdi-shape-polygon-plus:before {
  content: "\f65e";
}

.mdi-shape-rectangle-plus:before {
  content: "\f65f";
}

.mdi-shape-square-plus:before {
  content: "\f660";
}

.mdi-share:before {
  content: "\f496";
}

.mdi-share-outline:before {
  content: "櫓";
}

.mdi-share-variant:before {
  content: "\f497";
}

.mdi-sheep:before {
  content: "ﲢ";
}

.mdi-shield:before {
  content: "\f498";
}

.mdi-shield-account:before {
  content: "\f88e";
}

.mdi-shield-account-outline:before {
  content: "﨑";
}

.mdi-shield-airplane:before {
  content: "\f6ba";
}

.mdi-shield-airplane-outline:before {
  content: "ﲣ";
}

.mdi-shield-check:before {
  content: "\f565";
}

.mdi-shield-check-outline:before {
  content: "ﲤ";
}

.mdi-shield-cross:before {
  content: "ﲥ";
}

.mdi-shield-cross-outline:before {
  content: "ﲦ";
}

.mdi-shield-half-full:before {
  content: "\f77f";
}

.mdi-shield-home:before {
  content: "\f689";
}

.mdi-shield-home-outline:before {
  content: "ﲧ";
}

.mdi-shield-key:before {
  content: "ﮠ";
}

.mdi-shield-key-outline:before {
  content: "ﮡ";
}

.mdi-shield-link-variant:before {
  content: "ﴏ";
}

.mdi-shield-link-variant-outline:before {
  content: "ﴐ";
}

.mdi-shield-lock:before {
  content: "列";
}

.mdi-shield-lock-outline:before {
  content: "ﲨ";
}

.mdi-shield-off:before {
  content: "劣";
}

.mdi-shield-off-outline:before {
  content: "鍊";
}

.mdi-shield-outline:before {
  content: "\f499";
}

.mdi-shield-plus:before {
  content: "龎";
}

.mdi-shield-plus-outline:before {
  content: "﫚";
}

.mdi-shield-remove:before {
  content: "﫛";
}

.mdi-shield-remove-outline:before {
  content: "﫜";
}

.mdi-shield-search:before {
  content: "ﵶ";
}

.mdi-ship-wheel:before {
  content: "\f832";
}

.mdi-shoe-formal:before {
  content: "ﬢ";
}

.mdi-shoe-heel:before {
  content: "ﬣ";
}

.mdi-shoe-print:before {
  content: "﷖";
}

.mdi-shopify:before {
  content: "﫝";
}

.mdi-shopping:before {
  content: "\f49a";
}

.mdi-shopping-music:before {
  content: "\f49b";
}

.mdi-shovel:before {
  content: "\f70f";
}

.mdi-shovel-off:before {
  content: "\f710";
}

.mdi-shower:before {
  content: "烈";
}

.mdi-shower-head:before {
  content: "裂";
}

.mdi-shredder:before {
  content: "\f49c";
}

.mdi-shuffle:before {
  content: "\f49d";
}

.mdi-shuffle-disabled:before {
  content: "\f49e";
}

.mdi-shuffle-variant:before {
  content: "\f49f";
}

.mdi-sigma:before {
  content: "\f4a0";
}

.mdi-sigma-lower:before {
  content: "\f62b";
}

.mdi-sign-caution:before {
  content: "\f4a1";
}

.mdi-sign-direction:before {
  content: "\f780";
}

.mdi-sign-text:before {
  content: "\f781";
}

.mdi-signal:before {
  content: "\f4a2";
}

.mdi-signal-2g:before {
  content: "\f711";
}

.mdi-signal-3g:before {
  content: "\f712";
}

.mdi-signal-4g:before {
  content: "\f713";
}

.mdi-signal-5g:before {
  content: "﩮";
}

.mdi-signal-cellular-1:before {
  content: "\f8bb";
}

.mdi-signal-cellular-2:before {
  content: "\f8bc";
}

.mdi-signal-cellular-3:before {
  content: "\f8bd";
}

.mdi-signal-cellular-outline:before {
  content: "\f8be";
}

.mdi-signal-hspa:before {
  content: "\f714";
}

.mdi-signal-hspa-plus:before {
  content: "\f715";
}

.mdi-signal-off:before {
  content: "\f782";
}

.mdi-signal-variant:before {
  content: "\f60a";
}

.mdi-signature:before {
  content: "﷗";
}

.mdi-signature-freehand:before {
  content: "﷘";
}

.mdi-signature-image:before {
  content: "﷙";
}

.mdi-signature-text:before {
  content: "﷚";
}

.mdi-silo:before {
  content: "ﬤ";
}

.mdi-silverware:before {
  content: "\f4a3";
}

.mdi-silverware-fork:before {
  content: "\f4a4";
}

.mdi-silverware-fork-knife:before {
  content: "﩯";
}

.mdi-silverware-spoon:before {
  content: "\f4a5";
}

.mdi-silverware-variant:before {
  content: "\f4a6";
}

.mdi-sim:before {
  content: "\f4a7";
}

.mdi-sim-alert:before {
  content: "\f4a8";
}

.mdi-sim-off:before {
  content: "\f4a9";
}

.mdi-sina-weibo:before {
  content: "﫞";
}

.mdi-sitemap:before {
  content: "\f4aa";
}

.mdi-skate:before {
  content: "ﴑ";
}

.mdi-skew-less:before {
  content: "ﴒ";
}

.mdi-skew-more:before {
  content: "ﴓ";
}

.mdi-skip-backward:before {
  content: "\f4ab";
}

.mdi-skip-forward:before {
  content: "\f4ac";
}

.mdi-skip-next:before {
  content: "\f4ad";
}

.mdi-skip-next-circle:before {
  content: "\f661";
}

.mdi-skip-next-circle-outline:before {
  content: "\f662";
}

.mdi-skip-previous:before {
  content: "\f4ae";
}

.mdi-skip-previous-circle:before {
  content: "\f663";
}

.mdi-skip-previous-circle-outline:before {
  content: "\f664";
}

.mdi-skull:before {
  content: "\f68b";
}

.mdi-skull-crossbones:before {
  content: "ﮢ";
}

.mdi-skull-crossbones-outline:before {
  content: "ﮣ";
}

.mdi-skull-outline:before {
  content: "ﮤ";
}

.mdi-skype:before {
  content: "\f4af";
}

.mdi-skype-business:before {
  content: "\f4b0";
}

.mdi-slack:before {
  content: "\f4b1";
}

.mdi-slackware:before {
  content: "金";
}

.mdi-sleep:before {
  content: "\f4b2";
}

.mdi-sleep-off:before {
  content: "\f4b3";
}

.mdi-slope-downhill:before {
  content: "﷛";
}

.mdi-slope-uphill:before {
  content: "﷜";
}

.mdi-smog:before {
  content: "並";
}

.mdi-smoke-detector:before {
  content: "\f392";
}

.mdi-smoking:before {
  content: "\f4b4";
}

.mdi-smoking-off:before {
  content: "\f4b5";
}

.mdi-snapchat:before {
  content: "\f4b6";
}

.mdi-snowflake:before {
  content: "\f716";
}

.mdi-snowman:before {
  content: "\f4b7";
}

.mdi-soccer:before {
  content: "\f4b8";
}

.mdi-soccer-field:before {
  content: "\f833";
}

.mdi-sofa:before {
  content: "\f4b9";
}

.mdi-solar-panel:before {
  content: "ﵷ";
}

.mdi-solar-panel-large:before {
  content: "ﵸ";
}

.mdi-solar-power:before {
  content: "况";
}

.mdi-solid:before {
  content: "\f68c";
}

.mdi-sort:before {
  content: "\f4ba";
}

.mdi-sort-alphabetical:before {
  content: "\f4bb";
}

.mdi-sort-ascending:before {
  content: "\f4bc";
}

.mdi-sort-descending:before {
  content: "\f4bd";
}

.mdi-sort-numeric:before {
  content: "\f4be";
}

.mdi-sort-variant:before {
  content: "\f4bf";
}

.mdi-sort-variant-lock:before {
  content: "ﲩ";
}

.mdi-sort-variant-lock-open:before {
  content: "ﲪ";
}

.mdi-soundcloud:before {
  content: "\f4c0";
}

.mdi-source-branch:before {
  content: "\f62c";
}

.mdi-source-commit:before {
  content: "\f717";
}

.mdi-source-commit-end:before {
  content: "\f718";
}

.mdi-source-commit-end-local:before {
  content: "\f719";
}

.mdi-source-commit-local:before {
  content: "\f71a";
}

.mdi-source-commit-next-local:before {
  content: "\f71b";
}

.mdi-source-commit-start:before {
  content: "\f71c";
}

.mdi-source-commit-start-next-local:before {
  content: "\f71d";
}

.mdi-source-fork:before {
  content: "\f4c1";
}

.mdi-source-merge:before {
  content: "\f62d";
}

.mdi-source-pull:before {
  content: "\f4c2";
}

.mdi-source-repository:before {
  content: "ﲫ";
}

.mdi-source-repository-multiple:before {
  content: "ﲬ";
}

.mdi-soy-sauce:before {
  content: "\f7ed";
}

.mdi-spa:before {
  content: "ﲭ";
}

.mdi-spa-outline:before {
  content: "ﲮ";
}

.mdi-space-invaders:before {
  content: "ﮥ";
}

.mdi-speaker:before {
  content: "\f4c3";
}

.mdi-speaker-bluetooth:before {
  content: "說";
}

.mdi-speaker-multiple:before {
  content: "ﴔ";
}

.mdi-speaker-off:before {
  content: "\f4c4";
}

.mdi-speaker-wireless:before {
  content: "\f71e";
}

.mdi-speedometer:before {
  content: "\f4c5";
}

.mdi-spellcheck:before {
  content: "\f4c6";
}

.mdi-spider-web:before {
  content: "ﮦ";
}

.mdi-spotify:before {
  content: "\f4c7";
}

.mdi-spotlight:before {
  content: "\f4c8";
}

.mdi-spotlight-beam:before {
  content: "\f4c9";
}

.mdi-spray:before {
  content: "\f665";
}

.mdi-spray-bottle:before {
  content: "﫟";
}

.mdi-square:before {
  content: "\f763";
}

.mdi-square-edit-outline:before {
  content: "喇";
}

.mdi-square-inc:before {
  content: "\f4ca";
}

.mdi-square-inc-cash:before {
  content: "\f4cb";
}

.mdi-square-medium:before {
  content: "晴";
}

.mdi-square-medium-outline:before {
  content: "﨓";
}

.mdi-square-outline:before {
  content: "\f762";
}

.mdi-square-root:before {
  content: "\f783";
}

.mdi-square-root-box:before {
  content: "廉";
}

.mdi-square-small:before {
  content: "﨔";
}

.mdi-squeegee:before {
  content: "﫠";
}

.mdi-ssh:before {
  content: "\f8bf";
}

.mdi-stack-exchange:before {
  content: "\f60b";
}

.mdi-stack-overflow:before {
  content: "\f4cc";
}

.mdi-stadium:before {
  content: "\f71f";
}

.mdi-stairs:before {
  content: "\f4cd";
}

.mdi-stamper:before {
  content: "ﴕ";
}

.mdi-standard-definition:before {
  content: "\f7ee";
}

.mdi-star:before {
  content: "\f4ce";
}

.mdi-star-box:before {
  content: "全";
}

.mdi-star-box-outline:before {
  content: "侀";
}

.mdi-star-circle:before {
  content: "\f4cf";
}

.mdi-star-circle-outline:before {
  content: "念";
}

.mdi-star-face:before {
  content: "捻";
}

.mdi-star-four-points:before {
  content: "﫡";
}

.mdi-star-four-points-outline:before {
  content: "﫢";
}

.mdi-star-half:before {
  content: "\f4d0";
}

.mdi-star-off:before {
  content: "\f4d1";
}

.mdi-star-outline:before {
  content: "\f4d2";
}

.mdi-star-three-points:before {
  content: "﫣";
}

.mdi-star-three-points-outline:before {
  content: "﫤";
}

.mdi-steam:before {
  content: "\f4d3";
}

.mdi-steam-box:before {
  content: "奈";
}

.mdi-steering:before {
  content: "\f4d4";
}

.mdi-steering-off:before {
  content: "懶";
}

.mdi-step-backward:before {
  content: "\f4d5";
}

.mdi-step-backward-2:before {
  content: "\f4d6";
}

.mdi-step-forward:before {
  content: "\f4d7";
}

.mdi-step-forward-2:before {
  content: "\f4d8";
}

.mdi-stethoscope:before {
  content: "\f4d9";
}

.mdi-sticker:before {
  content: "\f5d0";
}

.mdi-sticker-emoji:before {
  content: "\f784";
}

.mdi-stocking:before {
  content: "\f4da";
}

.mdi-stop:before {
  content: "\f4db";
}

.mdi-stop-circle:before {
  content: "\f666";
}

.mdi-stop-circle-outline:before {
  content: "\f667";
}

.mdi-store:before {
  content: "\f4dc";
}

.mdi-store-24-hour:before {
  content: "\f4dd";
}

.mdi-stove:before {
  content: "\f4de";
}

.mdi-strava:before {
  content: "ﬥ";
}

.mdi-subdirectory-arrow-left:before {
  content: "\f60c";
}

.mdi-subdirectory-arrow-right:before {
  content: "\f60d";
}

.mdi-subtitles:before {
  content: "凞";
}

.mdi-subtitles-outline:before {
  content: "猪";
}

.mdi-subway:before {
  content: "\f6ab";
}

.mdi-subway-alert-variant:before {
  content: "ﵹ";
}

.mdi-subway-variant:before {
  content: "\f4df";
}

.mdi-summit:before {
  content: "\f785";
}

.mdi-sunglasses:before {
  content: "\f4e0";
}

.mdi-surround-sound:before {
  content: "\f5c5";
}

.mdi-surround-sound-2-0:before {
  content: "\f7ef";
}

.mdi-surround-sound-3-1:before {
  content: "\f7f0";
}

.mdi-surround-sound-5-1:before {
  content: "\f7f1";
}

.mdi-surround-sound-7-1:before {
  content: "\f7f2";
}

.mdi-svg:before {
  content: "\f720";
}

.mdi-swap-horizontal:before {
  content: "\f4e1";
}

.mdi-swap-horizontal-bold:before {
  content: "ﮩ";
}

.mdi-swap-horizontal-variant:before {
  content: "\f8c0";
}

.mdi-swap-vertical:before {
  content: "\f4e2";
}

.mdi-swap-vertical-bold:before {
  content: "ﮪ";
}

.mdi-swap-vertical-variant:before {
  content: "\f8c1";
}

.mdi-swim:before {
  content: "\f4e3";
}

.mdi-switch:before {
  content: "\f4e4";
}

.mdi-sword:before {
  content: "\f4e5";
}

.mdi-sword-cross:before {
  content: "\f786";
}

.mdi-symfony:before {
  content: "﫥";
}

.mdi-sync:before {
  content: "\f4e6";
}

.mdi-sync-alert:before {
  content: "\f4e7";
}

.mdi-sync-off:before {
  content: "\f4e8";
}

.mdi-tab:before {
  content: "\f4e9";
}

.mdi-tab-minus:before {
  content: "ﬦ";
}

.mdi-tab-plus:before {
  content: "\f75b";
}

.mdi-tab-remove:before {
  content: "ﬧ";
}

.mdi-tab-unselected:before {
  content: "\f4ea";
}

.mdi-table:before {
  content: "\f4eb";
}

.mdi-table-border:before {
  content: "益";
}

.mdi-table-column:before {
  content: "\f834";
}

.mdi-table-column-plus-after:before {
  content: "\f4ec";
}

.mdi-table-column-plus-before:before {
  content: "\f4ed";
}

.mdi-table-column-remove:before {
  content: "\f4ee";
}

.mdi-table-column-width:before {
  content: "\f4ef";
}

.mdi-table-edit:before {
  content: "\f4f0";
}

.mdi-table-large:before {
  content: "\f4f1";
}

.mdi-table-merge-cells:before {
  content: "殮";
}

.mdi-table-of-contents:before {
  content: "\f835";
}

.mdi-table-plus:before {
  content: "充";
}

.mdi-table-remove:before {
  content: "冀";
}

.mdi-table-row:before {
  content: "\f836";
}

.mdi-table-row-height:before {
  content: "\f4f2";
}

.mdi-table-row-plus-after:before {
  content: "\f4f3";
}

.mdi-table-row-plus-before:before {
  content: "\f4f4";
}

.mdi-table-row-remove:before {
  content: "\f4f5";
}

.mdi-table-search:before {
  content: "癩";
}

.mdi-table-settings:before {
  content: "\f837";
}

.mdi-tablet:before {
  content: "\f4f6";
}

.mdi-tablet-android:before {
  content: "\f4f7";
}

.mdi-tablet-cellphone:before {
  content: "簾";
}

.mdi-tablet-ipad:before {
  content: "\f4f8";
}

.mdi-taco:before {
  content: "\f761";
}

.mdi-tag:before {
  content: "\f4f9";
}

.mdi-tag-faces:before {
  content: "\f4fa";
}

.mdi-tag-heart:before {
  content: "\f68a";
}

.mdi-tag-heart-outline:before {
  content: "ﮫ";
}

.mdi-tag-minus:before {
  content: "羅";
}

.mdi-tag-multiple:before {
  content: "\f4fb";
}

.mdi-tag-outline:before {
  content: "\f4fc";
}

.mdi-tag-plus:before {
  content: "\f721";
}

.mdi-tag-remove:before {
  content: "\f722";
}

.mdi-tag-text-outline:before {
  content: "\f4fd";
}

.mdi-tank:before {
  content: "ﴖ";
}

.mdi-tape-measure:before {
  content: "ﬨ";
}

.mdi-target:before {
  content: "\f4fe";
}

.mdi-target-account:before {
  content: "ﮬ";
}

.mdi-target-variant:before {
  content: "勇";
}

.mdi-taxi:before {
  content: "\f4ff";
}

.mdi-tea:before {
  content: "ﵺ";
}

.mdi-tea-outline:before {
  content: "ﵻ";
}

.mdi-teach:before {
  content: "\f88f";
}

.mdi-teamviewer:before {
  content: "\f500";
}

.mdi-telegram:before {
  content: "\f501";
}

.mdi-telescope:before {
  content: "﬩";
}

.mdi-television:before {
  content: "\f502";
}

.mdi-television-box:before {
  content: "\f838";
}

.mdi-television-classic:before {
  content: "\f7f3";
}

.mdi-television-classic-off:before {
  content: "\f839";
}

.mdi-television-guide:before {
  content: "\f503";
}

.mdi-television-off:before {
  content: "\f83a";
}

.mdi-temperature-celsius:before {
  content: "\f504";
}

.mdi-temperature-fahrenheit:before {
  content: "\f505";
}

.mdi-temperature-kelvin:before {
  content: "\f506";
}

.mdi-tennis:before {
  content: "ﵼ";
}

.mdi-tennis-ball:before {
  content: "\f507";
}

.mdi-tent:before {
  content: "\f508";
}

.mdi-terrain:before {
  content: "\f509";
}

.mdi-test-tube:before {
  content: "\f668";
}

.mdi-test-tube-empty:before {
  content: "蘿";
}

.mdi-test-tube-off:before {
  content: "螺";
}

.mdi-text:before {
  content: "獵";
}

.mdi-text-shadow:before {
  content: "\f669";
}

.mdi-text-short:before {
  content: "令";
}

.mdi-text-subject:before {
  content: "囹";
}

.mdi-text-to-speech:before {
  content: "\f50a";
}

.mdi-text-to-speech-off:before {
  content: "\f50b";
}

.mdi-textbox:before {
  content: "\f60e";
}

.mdi-textbox-password:before {
  content: "\f7f4";
}

.mdi-texture:before {
  content: "\f50c";
}

.mdi-theater:before {
  content: "\f50d";
}

.mdi-theme-light-dark:before {
  content: "\f50e";
}

.mdi-thermometer:before {
  content: "\f50f";
}

.mdi-thermometer-alert:before {
  content: "﷝";
}

.mdi-thermometer-chevron-down:before {
  content: "﷞";
}

.mdi-thermometer-chevron-up:before {
  content: "﷟";
}

.mdi-thermometer-lines:before {
  content: "\f510";
}

.mdi-thermometer-minus:before {
  content: "﷠";
}

.mdi-thermometer-plus:before {
  content: "﷡";
}

.mdi-thermostat:before {
  content: "\f393";
}

.mdi-thermostat-box:before {
  content: "\f890";
}

.mdi-thought-bubble:before {
  content: "\f7f5";
}

.mdi-thought-bubble-outline:before {
  content: "\f7f6";
}

.mdi-thumb-down:before {
  content: "\f511";
}

.mdi-thumb-down-outline:before {
  content: "\f512";
}

.mdi-thumb-up:before {
  content: "\f513";
}

.mdi-thumb-up-outline:before {
  content: "\f514";
}

.mdi-thumbs-up-down:before {
  content: "\f515";
}

.mdi-ticket:before {
  content: "\f516";
}

.mdi-ticket-account:before {
  content: "\f517";
}

.mdi-ticket-confirmation:before {
  content: "\f518";
}

.mdi-ticket-outline:before {
  content: "裸";
}

.mdi-ticket-percent:before {
  content: "\f723";
}

.mdi-tie:before {
  content: "\f519";
}

.mdi-tilde:before {
  content: "\f724";
}

.mdi-timelapse:before {
  content: "\f51a";
}

.mdi-timeline:before {
  content: "ﮭ";
}

.mdi-timeline-outline:before {
  content: "ﮮ";
}

.mdi-timeline-text:before {
  content: "ﮯ";
}

.mdi-timeline-text-outline:before {
  content: "ﮰ";
}

.mdi-timer:before {
  content: "\f51b";
}

.mdi-timer-10:before {
  content: "\f51c";
}

.mdi-timer-3:before {
  content: "\f51d";
}

.mdi-timer-off:before {
  content: "\f51e";
}

.mdi-timer-sand:before {
  content: "\f51f";
}

.mdi-timer-sand-empty:before {
  content: "\f6ac";
}

.mdi-timer-sand-full:before {
  content: "\f78b";
}

.mdi-timetable:before {
  content: "\f520";
}

.mdi-toaster-oven:before {
  content: "ﲯ";
}

.mdi-toggle-switch:before {
  content: "\f521";
}

.mdi-toggle-switch-off:before {
  content: "\f522";
}

.mdi-toggle-switch-off-outline:before {
  content: "礼";
}

.mdi-toggle-switch-outline:before {
  content: "神";
}

.mdi-toilet:before {
  content: "寧";
}

.mdi-toolbox:before {
  content: "嶺";
}

.mdi-toolbox-outline:before {
  content: "怜";
}

.mdi-tooltip:before {
  content: "\f523";
}

.mdi-tooltip-account:before {
  content: "\f00c";
}

.mdi-tooltip-edit:before {
  content: "\f524";
}

.mdi-tooltip-image:before {
  content: "\f525";
}

.mdi-tooltip-image-outline:before {
  content: "ﮱ";
}

.mdi-tooltip-outline:before {
  content: "\f526";
}

.mdi-tooltip-plus:before {
  content: "﮲";
}

.mdi-tooltip-plus-outline:before {
  content: "\f527";
}

.mdi-tooltip-text:before {
  content: "\f528";
}

.mdi-tooltip-text-outline:before {
  content: "﮳";
}

.mdi-tooth:before {
  content: "\f8c2";
}

.mdi-tooth-outline:before {
  content: "\f529";
}

.mdi-tor:before {
  content: "\f52a";
}

.mdi-tortoise:before {
  content: "ﴗ";
}

.mdi-tournament:before {
  content: "玲";
}

.mdi-tower-beach:before {
  content: "\f680";
}

.mdi-tower-fire:before {
  content: "\f681";
}

.mdi-towing:before {
  content: "\f83b";
}

.mdi-track-light:before {
  content: "邏";
}

.mdi-trackpad:before {
  content: "\f7f7";
}

.mdi-trackpad-lock:before {
  content: "爐";
}

.mdi-tractor:before {
  content: "\f891";
}

.mdi-trademark:before {
  content: "勺";
}

.mdi-traffic-light:before {
  content: "\f52b";
}

.mdi-train:before {
  content: "\f52c";
}

.mdi-train-car:before {
  content: "﮴";
}

.mdi-train-variant:before {
  content: "\f8c3";
}

.mdi-tram:before {
  content: "\f52d";
}

.mdi-transcribe:before {
  content: "\f52e";
}

.mdi-transcribe-close:before {
  content: "\f52f";
}

.mdi-transfer-down:before {
  content: "ﵽ";
}

.mdi-transfer-left:before {
  content: "ﵾ";
}

.mdi-transfer-right:before {
  content: "\f530";
}

.mdi-transfer-up:before {
  content: "ﵿ";
}

.mdi-transit-connection:before {
  content: "ﴘ";
}

.mdi-transit-connection-variant:before {
  content: "ﴙ";
}

.mdi-transit-transfer:before {
  content: "\f6ad";
}

.mdi-transition:before {
  content: "樂";
}

.mdi-transition-masked:before {
  content: "洛";
}

.mdi-translate:before {
  content: "\f5ca";
}

.mdi-translate-off:before {
  content: "﷢";
}

.mdi-transmission-tower:before {
  content: "ﴚ";
}

.mdi-trash-can:before {
  content: "喝";
}

.mdi-trash-can-outline:before {
  content: "啕";
}

.mdi-treasure-chest:before {
  content: "\f725";
}

.mdi-tree:before {
  content: "\f531";
}

.mdi-trello:before {
  content: "\f532";
}

.mdi-trending-down:before {
  content: "\f533";
}

.mdi-trending-neutral:before {
  content: "\f534";
}

.mdi-trending-up:before {
  content: "\f535";
}

.mdi-triangle:before {
  content: "\f536";
}

.mdi-triangle-outline:before {
  content: "\f537";
}

.mdi-triforce:before {
  content: "﮵";
}

.mdi-trophy:before {
  content: "\f538";
}

.mdi-trophy-award:before {
  content: "\f539";
}

.mdi-trophy-broken:before {
  content: "ﶀ";
}

.mdi-trophy-outline:before {
  content: "\f53a";
}

.mdi-trophy-variant:before {
  content: "\f53b";
}

.mdi-trophy-variant-outline:before {
  content: "\f53c";
}

.mdi-truck:before {
  content: "\f53d";
}

.mdi-truck-check:before {
  content: "ﲰ";
}

.mdi-truck-delivery:before {
  content: "\f53e";
}

.mdi-truck-fast:before {
  content: "\f787";
}

.mdi-truck-trailer:before {
  content: "\f726";
}

.mdi-tshirt-crew:before {
  content: "喙";
}

.mdi-tshirt-crew-outline:before {
  content: "\f53f";
}

.mdi-tshirt-v:before {
  content: "嗢";
}

.mdi-tshirt-v-outline:before {
  content: "\f540";
}

.mdi-tumble-dryer:before {
  content: "烙";
}

.mdi-tumblr:before {
  content: "\f541";
}

.mdi-tumblr-box:before {
  content: "珞";
}

.mdi-tumblr-reblog:before {
  content: "\f542";
}

.mdi-tune:before {
  content: "\f62e";
}

.mdi-tune-vertical:before {
  content: "\f66a";
}

.mdi-turnstile:before {
  content: "ﲱ";
}

.mdi-turnstile-outline:before {
  content: "ﲲ";
}

.mdi-turtle:before {
  content: "ﲳ";
}

.mdi-twitch:before {
  content: "\f543";
}

.mdi-twitter:before {
  content: "\f544";
}

.mdi-twitter-box:before {
  content: "\f545";
}

.mdi-twitter-circle:before {
  content: "\f546";
}

.mdi-twitter-retweet:before {
  content: "\f547";
}

.mdi-two-factor-authentication:before {
  content: "瑩";
}

.mdi-uber:before {
  content: "\f748";
}

.mdi-ubisoft:before {
  content: "﮶";
}

.mdi-ubuntu:before {
  content: "\f548";
}

.mdi-ultra-high-definition:before {
  content: "\f7f8";
}

.mdi-umbraco:before {
  content: "\f549";
}

.mdi-umbrella:before {
  content: "\f54a";
}

.mdi-umbrella-closed:before {
  content: "羚";
}

.mdi-umbrella-outline:before {
  content: "\f54b";
}

.mdi-undo:before {
  content: "\f54c";
}

.mdi-undo-variant:before {
  content: "\f54d";
}

.mdi-unfold-less-horizontal:before {
  content: "\f54e";
}

.mdi-unfold-less-vertical:before {
  content: "\f75f";
}

.mdi-unfold-more-horizontal:before {
  content: "\f54f";
}

.mdi-unfold-more-vertical:before {
  content: "\f760";
}

.mdi-ungroup:before {
  content: "\f550";
}

.mdi-unity:before {
  content: "\f6ae";
}

.mdi-unreal:before {
  content: "聆";
}

.mdi-untappd:before {
  content: "\f551";
}

.mdi-update:before {
  content: "\f6af";
}

.mdi-upload:before {
  content: "\f552";
}

.mdi-upload-multiple:before {
  content: "\f83c";
}

.mdi-upload-network:before {
  content: "\f6f5";
}

.mdi-upload-network-outline:before {
  content: "ﲴ";
}

.mdi-upload-outline:before {
  content: "﷣";
}

.mdi-usb:before {
  content: "\f553";
}

.mdi-van-passenger:before {
  content: "\f7f9";
}

.mdi-van-utility:before {
  content: "\f7fa";
}

.mdi-vanish:before {
  content: "\f7fb";
}

.mdi-variable:before {
  content: "﫦";
}

.mdi-vector-arrange-above:before {
  content: "\f554";
}

.mdi-vector-arrange-below:before {
  content: "\f555";
}

.mdi-vector-bezier:before {
  content: "﫧";
}

.mdi-vector-circle:before {
  content: "\f556";
}

.mdi-vector-circle-variant:before {
  content: "\f557";
}

.mdi-vector-combine:before {
  content: "\f558";
}

.mdi-vector-curve:before {
  content: "\f559";
}

.mdi-vector-difference:before {
  content: "\f55a";
}

.mdi-vector-difference-ab:before {
  content: "\f55b";
}

.mdi-vector-difference-ba:before {
  content: "\f55c";
}

.mdi-vector-ellipse:before {
  content: "\f892";
}

.mdi-vector-intersection:before {
  content: "\f55d";
}

.mdi-vector-line:before {
  content: "\f55e";
}

.mdi-vector-point:before {
  content: "\f55f";
}

.mdi-vector-polygon:before {
  content: "\f560";
}

.mdi-vector-polyline:before {
  content: "\f561";
}

.mdi-vector-radius:before {
  content: "\f749";
}

.mdi-vector-rectangle:before {
  content: "\f5c6";
}

.mdi-vector-selection:before {
  content: "\f562";
}

.mdi-vector-square:before {
  content: "\f001";
}

.mdi-vector-triangle:before {
  content: "\f563";
}

.mdi-vector-union:before {
  content: "\f564";
}

.mdi-venmo:before {
  content: "\f578";
}

.mdi-vhs:before {
  content: "祥";
}

.mdi-vibrate:before {
  content: "\f566";
}

.mdi-vibrate-off:before {
  content: "ﲵ";
}

.mdi-video:before {
  content: "\f567";
}

.mdi-video-3d:before {
  content: "\f7fc";
}

.mdi-video-4k-box:before {
  content: "\f83d";
}

.mdi-video-account:before {
  content: "落";
}

.mdi-video-image:before {
  content: "酪";
}

.mdi-video-input-antenna:before {
  content: "\f83e";
}

.mdi-video-input-component:before {
  content: "\f83f";
}

.mdi-video-input-hdmi:before {
  content: "\f840";
}

.mdi-video-input-svideo:before {
  content: "\f841";
}

.mdi-video-minus:before {
  content: "鈴";
}

.mdi-video-off:before {
  content: "\f568";
}

.mdi-video-off-outline:before {
  content: "﮷";
}

.mdi-video-outline:before {
  content: "﮸";
}

.mdi-video-plus:before {
  content: "零";
}

.mdi-video-stabilization:before {
  content: "駱";
}

.mdi-video-switch:before {
  content: "\f569";
}

.mdi-video-vintage:before {
  content: "福";
}

.mdi-view-agenda:before {
  content: "\f56a";
}

.mdi-view-array:before {
  content: "\f56b";
}

.mdi-view-carousel:before {
  content: "\f56c";
}

.mdi-view-column:before {
  content: "\f56d";
}

.mdi-view-dashboard:before {
  content: "\f56e";
}

.mdi-view-dashboard-outline:before {
  content: "靖";
}

.mdi-view-dashboard-variant:before {
  content: "\f842";
}

.mdi-view-day:before {
  content: "\f56f";
}

.mdi-view-grid:before {
  content: "\f570";
}

.mdi-view-headline:before {
  content: "\f571";
}

.mdi-view-list:before {
  content: "\f572";
}

.mdi-view-module:before {
  content: "\f573";
}

.mdi-view-parallel:before {
  content: "\f727";
}

.mdi-view-quilt:before {
  content: "\f574";
}

.mdi-view-sequential:before {
  content: "\f728";
}

.mdi-view-split-horizontal:before {
  content: "ﮧ";
}

.mdi-view-split-vertical:before {
  content: "ﮨ";
}

.mdi-view-stream:before {
  content: "\f575";
}

.mdi-view-week:before {
  content: "\f576";
}

.mdi-vimeo:before {
  content: "\f577";
}

.mdi-violin:before {
  content: "\f60f";
}

.mdi-virtual-reality:before {
  content: "\f893";
}

.mdi-visual-studio:before {
  content: "\f610";
}

.mdi-visual-studio-code:before {
  content: "精";
}

.mdi-vk:before {
  content: "\f579";
}

.mdi-vk-box:before {
  content: "\f57a";
}

.mdi-vk-circle:before {
  content: "\f57b";
}

.mdi-vlc:before {
  content: "\f57c";
}

.mdi-voice:before {
  content: "\f5cb";
}

.mdi-voicemail:before {
  content: "\f57d";
}

.mdi-volleyball:before {
  content: "靈";
}

.mdi-volume-high:before {
  content: "\f57e";
}

.mdi-volume-low:before {
  content: "\f57f";
}

.mdi-volume-medium:before {
  content: "\f580";
}

.mdi-volume-minus:before {
  content: "\f75d";
}

.mdi-volume-mute:before {
  content: "\f75e";
}

.mdi-volume-off:before {
  content: "\f581";
}

.mdi-volume-plus:before {
  content: "\f75c";
}

.mdi-volume-variant-off:before {
  content: "﷤";
}

.mdi-vote:before {
  content: "羽";
}

.mdi-vote-outline:before {
  content: "﨟";
}

.mdi-vpn:before {
  content: "\f582";
}

.mdi-vuejs:before {
  content: "\f843";
}

.mdi-walk:before {
  content: "\f583";
}

.mdi-wall:before {
  content: "\f7fd";
}

.mdi-wall-sconce:before {
  content: "亂";
}

.mdi-wall-sconce-flat:before {
  content: "卵";
}

.mdi-wall-sconce-variant:before {
  content: "欄";
}

.mdi-wallet:before {
  content: "\f584";
}

.mdi-wallet-giftcard:before {
  content: "\f585";
}

.mdi-wallet-membership:before {
  content: "\f586";
}

.mdi-wallet-outline:before {
  content: "﮹";
}

.mdi-wallet-travel:before {
  content: "\f587";
}

.mdi-wallpaper:before {
  content: "﷥";
}

.mdi-wan:before {
  content: "\f588";
}

.mdi-washing-machine:before {
  content: "\f729";
}

.mdi-watch:before {
  content: "\f589";
}

.mdi-watch-export:before {
  content: "\f58a";
}

.mdi-watch-export-variant:before {
  content: "\f894";
}

.mdi-watch-import:before {
  content: "\f58b";
}

.mdi-watch-import-variant:before {
  content: "\f895";
}

.mdi-watch-variant:before {
  content: "\f896";
}

.mdi-watch-vibrate:before {
  content: "\f6b0";
}

.mdi-watch-vibrate-off:before {
  content: "ﲶ";
}

.mdi-water:before {
  content: "\f58c";
}

.mdi-water-off:before {
  content: "\f58d";
}

.mdi-water-outline:before {
  content: "﷦";
}

.mdi-water-percent:before {
  content: "\f58e";
}

.mdi-water-pump:before {
  content: "\f58f";
}

.mdi-watermark:before {
  content: "\f612";
}

.mdi-waves:before {
  content: "\f78c";
}

.mdi-waze:before {
  content: "﮺";
}

.mdi-weather-cloudy:before {
  content: "\f590";
}

.mdi-weather-fog:before {
  content: "\f591";
}

.mdi-weather-hail:before {
  content: "\f592";
}

.mdi-weather-hurricane:before {
  content: "\f897";
}

.mdi-weather-lightning:before {
  content: "\f593";
}

.mdi-weather-lightning-rainy:before {
  content: "\f67d";
}

.mdi-weather-night:before {
  content: "\f594";
}

.mdi-weather-partlycloudy:before {
  content: "\f595";
}

.mdi-weather-pouring:before {
  content: "\f596";
}

.mdi-weather-rainy:before {
  content: "\f597";
}

.mdi-weather-snowy:before {
  content: "\f598";
}

.mdi-weather-snowy-rainy:before {
  content: "\f67e";
}

.mdi-weather-sunny:before {
  content: "\f599";
}

.mdi-weather-sunset:before {
  content: "\f59a";
}

.mdi-weather-sunset-down:before {
  content: "\f59b";
}

.mdi-weather-sunset-up:before {
  content: "\f59c";
}

.mdi-weather-windy:before {
  content: "\f59d";
}

.mdi-weather-windy-variant:before {
  content: "\f59e";
}

.mdi-web:before {
  content: "\f59f";
}

.mdi-webcam:before {
  content: "\f5a0";
}

.mdi-webhook:before {
  content: "\f62f";
}

.mdi-webpack:before {
  content: "\f72a";
}

.mdi-wechat:before {
  content: "\f611";
}

.mdi-weight:before {
  content: "\f5a1";
}

.mdi-weight-gram:before {
  content: "ﴛ";
}

.mdi-weight-kilogram:before {
  content: "\f5a2";
}

.mdi-weight-pound:before {
  content: "領";
}

.mdi-whatsapp:before {
  content: "\f5a3";
}

.mdi-wheelchair-accessibility:before {
  content: "\f5a4";
}

.mdi-whistle:before {
  content: "例";
}

.mdi-white-balance-auto:before {
  content: "\f5a5";
}

.mdi-white-balance-incandescent:before {
  content: "\f5a6";
}

.mdi-white-balance-iridescent:before {
  content: "\f5a7";
}

.mdi-white-balance-sunny:before {
  content: "\f5a8";
}

.mdi-widgets:before {
  content: "\f72b";
}

.mdi-wifi:before {
  content: "\f5a9";
}

.mdi-wifi-off:before {
  content: "\f5aa";
}

.mdi-wifi-star:before {
  content: "﷧";
}

.mdi-wifi-strength-1:before {
  content: "爛";
}

.mdi-wifi-strength-1-alert:before {
  content: "蘭";
}

.mdi-wifi-strength-1-lock:before {
  content: "鸞";
}

.mdi-wifi-strength-2:before {
  content: "嵐";
}

.mdi-wifi-strength-2-alert:before {
  content: "濫";
}

.mdi-wifi-strength-2-lock:before {
  content: "藍";
}

.mdi-wifi-strength-3:before {
  content: "襤";
}

.mdi-wifi-strength-3-alert:before {
  content: "拉";
}

.mdi-wifi-strength-3-lock:before {
  content: "臘";
}

.mdi-wifi-strength-4:before {
  content: "蠟";
}

.mdi-wifi-strength-4-alert:before {
  content: "廊";
}

.mdi-wifi-strength-4-lock:before {
  content: "朗";
}

.mdi-wifi-strength-alert-outline:before {
  content: "浪";
}

.mdi-wifi-strength-lock-outline:before {
  content: "狼";
}

.mdi-wifi-strength-off:before {
  content: "郎";
}

.mdi-wifi-strength-off-outline:before {
  content: "來";
}

.mdi-wifi-strength-outline:before {
  content: "冷";
}

.mdi-wii:before {
  content: "\f5ab";
}

.mdi-wiiu:before {
  content: "\f72c";
}

.mdi-wikipedia:before {
  content: "\f5ac";
}

.mdi-wind-turbine:before {
  content: "ﶁ";
}

.mdi-window-close:before {
  content: "\f5ad";
}

.mdi-window-closed:before {
  content: "\f5ae";
}

.mdi-window-maximize:before {
  content: "\f5af";
}

.mdi-window-minimize:before {
  content: "\f5b0";
}

.mdi-window-open:before {
  content: "\f5b1";
}

.mdi-window-restore:before {
  content: "\f5b2";
}

.mdi-windows:before {
  content: "\f5b3";
}

.mdi-windows-classic:before {
  content: "蘒";
}

.mdi-wiper:before {
  content: "﫨";
}

.mdi-wiper-wash:before {
  content: "ﶂ";
}

.mdi-wordpress:before {
  content: "\f5b4";
}

.mdi-worker:before {
  content: "\f5b5";
}

.mdi-wrap:before {
  content: "\f5b6";
}

.mdi-wrap-disabled:before {
  content: "﮻";
}

.mdi-wrench:before {
  content: "\f5b7";
}

.mdi-wrench-outline:before {
  content: "﮼";
}

.mdi-wunderlist:before {
  content: "\f5b8";
}

.mdi-xamarin:before {
  content: "\f844";
}

.mdi-xamarin-outline:before {
  content: "\f845";
}

.mdi-xaml:before {
  content: "\f673";
}

.mdi-xbox:before {
  content: "\f5b9";
}

.mdi-xbox-controller:before {
  content: "\f5ba";
}

.mdi-xbox-controller-battery-alert:before {
  content: "\f74a";
}

.mdi-xbox-controller-battery-charging:before {
  content: "﨡";
}

.mdi-xbox-controller-battery-empty:before {
  content: "\f74b";
}

.mdi-xbox-controller-battery-full:before {
  content: "\f74c";
}

.mdi-xbox-controller-battery-low:before {
  content: "\f74d";
}

.mdi-xbox-controller-battery-medium:before {
  content: "\f74e";
}

.mdi-xbox-controller-battery-unknown:before {
  content: "\f74f";
}

.mdi-xbox-controller-off:before {
  content: "\f5bb";
}

.mdi-xda:before {
  content: "\f5bc";
}

.mdi-xing:before {
  content: "\f5bd";
}

.mdi-xing-box:before {
  content: "\f5be";
}

.mdi-xing-circle:before {
  content: "\f5bf";
}

.mdi-xml:before {
  content: "\f5c0";
}

.mdi-xmpp:before {
  content: "\f7fe";
}

.mdi-yahoo:before {
  content: "שׁ";
}

.mdi-yammer:before {
  content: "\f788";
}

.mdi-yeast:before {
  content: "\f5c1";
}

.mdi-yelp:before {
  content: "\f5c2";
}

.mdi-yin-yang:before {
  content: "\f67f";
}

.mdi-youtube:before {
  content: "\f5c3";
}

.mdi-youtube-creator-studio:before {
  content: "\f846";
}

.mdi-youtube-gaming:before {
  content: "\f847";
}

.mdi-youtube-subscription:before {
  content: "ﴜ";
}

.mdi-youtube-tv:before {
  content: "\f448";
}

.mdi-z-wave:before {
  content: "﫩";
}

.mdi-zend:before {
  content: "﫪";
}

.mdi-zigbee:before {
  content: "ﴝ";
}

.mdi-zip-box:before {
  content: "\f5c4";
}

.mdi-zip-disk:before {
  content: "諸";
}

.mdi-zodiac-aquarius:before {
  content: "塚";
}

.mdi-zodiac-aries:before {
  content: "墳";
}

.mdi-zodiac-cancer:before {
  content: "奄";
}

.mdi-zodiac-capricorn:before {
  content: "奔";
}

.mdi-zodiac-gemini:before {
  content: "婢";
}

.mdi-zodiac-leo:before {
  content: "嬨";
}

.mdi-zodiac-libra:before {
  content: "廒";
}

.mdi-zodiac-pisces:before {
  content: "廙";
}

.mdi-zodiac-sagittarius:before {
  content: "彩";
}

.mdi-zodiac-scorpio:before {
  content: "徭";
}

.mdi-zodiac-taurus:before {
  content: "惘";
}

.mdi-zodiac-virgo:before {
  content: "慎";
}

.mdi-blank:before {
  content: "\f68c";
  visibility: hidden;
}

.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px;
}

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px;
}

.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px;
}

.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px;
}

.mdi-dark:before {
  color: rgba(0, 0, 0, 0.54);
}

.mdi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26);
}

.mdi-light:before {
  color: white;
}

.mdi-light.mdi-inactive:before {
  color: rgba(255, 255, 255, 0.3);
}

.mdi-rotate-45 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(45deg);
      transform: scaleX(-1) rotate(45deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: scaleY(-1) rotate(45deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-45:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.mdi-rotate-90 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(90deg);
      transform: scaleX(-1) rotate(90deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: scaleY(-1) rotate(90deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-90:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mdi-rotate-135 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(135deg);
      transform: scaleX(-1) rotate(135deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: scaleY(-1) rotate(135deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-135:before {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.mdi-rotate-180 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(180deg);
      transform: scaleX(-1) rotate(180deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: scaleY(-1) rotate(180deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-180:before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.mdi-rotate-225 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(225deg);
      transform: scaleX(-1) rotate(225deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(225deg);
      -ms-transform: rotate(225deg);
      transform: scaleY(-1) rotate(225deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-225:before {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}

.mdi-rotate-270 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(270deg);
      transform: scaleX(-1) rotate(270deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: scaleY(-1) rotate(270deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-270:before {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.mdi-rotate-315 {
  /*
  // Not included in production
  &.mdi-flip-h:before {
      -webkit-transform: scaleX(-1) rotate(315deg);
      transform: scaleX(-1) rotate(315deg);
      filter: FlipH;
      -ms-filter: "FlipH";
  }
  &.mdi-flip-v:before {
      -webkit-transform: scaleY(-1) rotate(315deg);
      -ms-transform: rotate(315deg);
      transform: scaleY(-1) rotate(315deg);
      filter: FlipV;
      -ms-filter: "FlipV";
  }
  */
}

.mdi-rotate-315:before {
  -webkit-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  transform: rotate(315deg);
}

.mdi-flip-h:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.mdi-flip-v:before {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipV;
  -ms-filter: "FlipV";
}

.mdi-spin:before {
  -webkit-animation: mdi-spin 2s infinite linear;
  animation: mdi-spin 2s infinite linear;
}

@-webkit-keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes mdi-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
/*# sourceMappingURL=materialdesignicons.css.map */