@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "../../scss/config/default/custom-variables";

.leftSideMenu {
  .mmCollapse {
    transition: height 2s;
  }

  .sideNavLink {
    .navIcon {
      display: inline-block;
      line-height: 1.0625rem;
      margin: 0 10px 0 6px;
      text-align: center;
      vertical-align: middle;
      width: 14px;
      height: 14px;
      fill: $menu-item;
    }
    &:hover,
    &:focus,
    &:active {
      .navIcon {
        fill: $menu-item-hover;
      }
    }
  }

  // @media (max-width: map-get($grid-breakpoints, lg)) {
  //   .leftSideMenu {
  //     width: 70px;
  //     max-width: map-get($grid-breakpoints, lg);
  //   }

  //   .sideNavTitle {
  //     display: none !important;
  //   }

  //   .sideNavItem {
  //     position: relative;
  //     white-space: nowrap;

  //     .sideNavLink {
  //       padding: 15px 20px;
  //       min-height: 56px;
  //     }
  //   }
  // }
}

.overlay {
  height: 100vh;
  width: 100vw;
  opacity: 0.8;
  position: absolute;
  background-color: white;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
}
