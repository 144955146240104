.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.horizontal {
  flex-direction: row;
  overflow-y: hidden;
  overflow-x: auto;
}
