//
// modal.scss
//

// Title text within header
.modal-title {
  margin-top: 0;
}

// Modal full width
.modal-full-width {
  width: 95%;
  max-width: none;
}

// Modal Positions
.modal-top {
  margin: 0 auto;
}

// Right Modal
.modal-right {
  position: absolute;
  right: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  height: 100%;
  margin: 0;
  background-color: $modal-content-bg;
  align-content: center;
  transform: translate(25%, 0) !important;

  button.close {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1;
  }
}

.modal {
  &.show {
    .modal-right {
      transform: translate(0, 0) !important;
    }
  }
}

// Bottom modal
.modal-bottom {
  display: flex;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  justify-content: flex-end;
  height: 100%;
  margin: 0 auto;
  align-content: center;
}

// Colored modal header
.modal-colored-header {
  color: $white;
  border-radius: 0;

  .close {
    color: $white !important;
  }
}

// Full filled modals
.modal-filled {
  color: $white;

  .modal-header {
    background-color: rgba($white, 0.07);
  }

  .modal-header,
  .modal-footer {
    border: none;
  }

  .close {
    color: $white !important;
  }
}

.modal.zoom {
  .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: scale(0);
  }
}

.modal.show .modal-dialog {
    transform: none;
}
