.leftSideMenu .mmCollapse {
  transition: height 2s;
}
.leftSideMenu .sideNavLink .navIcon {
  display: inline-block;
  line-height: 1.0625rem;
  margin: 0 10px 0 6px;
  text-align: center;
  vertical-align: middle;
  width: 14px;
  height: 14px;
  fill: #cedce4;
}
.leftSideMenu .sideNavLink:hover .navIcon, .leftSideMenu .sideNavLink:focus .navIcon, .leftSideMenu .sideNavLink:active .navIcon {
  fill: #ffffff;
}

.overlay {
  height: 100vh;
  width: 100vw;
  opacity: 0.8;
  position: absolute;
  background-color: white;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
}